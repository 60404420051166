import { apiManager, FpApi, HolidayUtil, IContactsHR, ResolvedHoliday, VacationUtil as VacationUtilCore } from "@tcs-rliess/fp-core";
import { fpLog } from "@tcs-rliess/fp-log";
import Aigle from "aigle";
import { sortBy, uniqBy } from "lodash-es";
import { DateTime } from "luxon";
import moment from "moment-timezone";

import { FleetplanApp } from "../../FleetplanApp";

export class VacationUtil extends VacationUtilCore{
	public static async load(year: number, dscaids: number[], app: FleetplanApp): Promise<VacationEntitlement[]> {
		const dutyVacationTypes = await app.store.categoryUtil.getTypeOptionsUtil("duty_type");
		dscaids = Array.from(new Set(dscaids));
		const dutyTypes = new Map(dutyVacationTypes.flatFilter(e => e.data.category.idName === FpApi.Resource.Duty.SystemCategoryType.Vacation).map(e => [ e.id, e ]));
		const ignoredCounter = Array.from(dutyTypes.values()).filter(e => e.data?.duty_type?.ignoreSubtractFromTimeOff).map(e => e.id);
		const willCountCategories = Array.from(dutyTypes.values()).filter(e => !e.data?.duty_type?.ignoreSubtractFromTimeOff).map(e => e.id);
		function oldBuildVacation(dscaid, vacData) {
			if(!vacData) return { ttl: {
				[FpApi.Resource.Duty.DutyStatus.Accepted]: 0,
				[FpApi.Resource.Duty.DutyStatus.Rejected]: 0,
				[FpApi.Resource.Duty.DutyStatus.Request]: 0,
			}};
			const key = dscaid;
			if(!vacData[key]) return { ttl: {
				[FpApi.Resource.Duty.DutyStatus.Accepted]: 0,
				[FpApi.Resource.Duty.DutyStatus.Rejected]: 0,
				[FpApi.Resource.Duty.DutyStatus.Request]: 0,
				ignoreSubtractFromTimeOff: false,
			}};
			for(const k of Object.keys(vacData[key])) {
				if(!dutyTypes.has(+k)) continue; // ignore all non vacation types
				if(!vacData[key]["ttl"]) {
					vacData[key]["ttl"] = {
						[FpApi.Resource.Duty.DutyStatus.Accepted]: 0,
						[FpApi.Resource.Duty.DutyStatus.Rejected]: 0,
						[FpApi.Resource.Duty.DutyStatus.Request]: 0,
					};
				}
				vacData[key]["ttl"][FpApi.Resource.Duty.DutyStatus.Accepted] += (vacData[key][k][FpApi.Resource.Duty.DutyStatus.Accepted] || 0);
				vacData[key]["ttl"][FpApi.Resource.Duty.DutyStatus.Rejected] += (vacData[key][k][FpApi.Resource.Duty.DutyStatus.Rejected] || 0);
				vacData[key]["ttl"][FpApi.Resource.Duty.DutyStatus.Request] += (vacData[key][k][FpApi.Resource.Duty.DutyStatus.Request] || 0);
				if(ignoredCounter.includes(Number.parseInt(k))) {
					vacData[key][k]["ignoreSubtractFromTimeOff"] = true;
				}
			}
			return {
				// ttl: sum(Object.values(vacData[key])),
				...vacData[key],
			};
		}
		function newBuildVacation(dscaid, vacData) {
			const target = {
				[dscaid]: {}
			};
			if(!vacData) return { ttl: {
				[FpApi.Resource.Duty.ScheduleStatus.Approved]: 0,
				[FpApi.Resource.Duty.ScheduleStatus.Workflow]: 0,
				[FpApi.Resource.Duty.ScheduleStatus.Rejected]: 0,
			}};
			Array.from(dutyTypes.keys()).forEach(categoryId => {
				[
					FpApi.Resource.Duty.ScheduleStatus.Approved,
					FpApi.Resource.Duty.ScheduleStatus.Workflow,
					FpApi.Resource.Duty.ScheduleStatus.Rejected,
				].forEach(status => {
					const key = `days:dscaid:${dscaid}:${FpApi.Resource.Duty.ScheduleType.Vacation}:${categoryId}:${status}`;
					if(!vacData[key]) return;
					if(!target[dscaid]?.["ttl"]) {
						target[dscaid] = {
							ttl: {
								[FpApi.Resource.Duty.ScheduleStatus.Approved]: 0,
								[FpApi.Resource.Duty.ScheduleStatus.Workflow]: 0,
								[FpApi.Resource.Duty.ScheduleStatus.Rejected]: 0,
							},
							// categories have a counter ignore-flag. If set -> won't be added to this field
							subtractedCounterTTL: {
								[FpApi.Resource.Duty.ScheduleStatus.Approved]: 0,
								[FpApi.Resource.Duty.ScheduleStatus.Workflow]: 0,
								[FpApi.Resource.Duty.ScheduleStatus.Rejected]: 0,
							}
						};
					}
					target[dscaid]["ttl"][status] += vacData[key];
					if(willCountCategories.includes(+categoryId)) {
						target[dscaid]["subtractedCounterTTL"][status] += vacData[key];
					} else {
						target[dscaid][`${categoryId}:${status}:ignoreSubtractFromTimeOff`] = true;
					}
					target[dscaid][`${categoryId}:${status}`] = vacData[key];
				});
			});

			return {
				// ttl: sum(Object.values(target[key])),
				...target[dscaid],
			};
		}

		const buildVacation = app.flags.dutySchedule ? newBuildVacation : oldBuildVacation;

		let result = new Map<number, FpApi.Resource.Duty.DutyRosterVacation>();
		let vac;
		if(app.flags.dutySchedule) {
			vac = await apiManager.getService(FpApi.Resource.Duty.ScheduleService).getCounters(app.ctx, {
				from: moment().set("year", year).utc().startOf("year").toISOString(),
				to: moment().set("year", year).utc().endOf("year").toISOString(),
			});
			try {
				result = new Map((await apiManager.getService(FpApi.Resource.Duty.DutyRosterVacationService)
					.get(app.ctx, {
						dscaid: dscaids,
						year: year,
					})).map(e => [ e.dscaid, e ]));
			} catch (err) {
				fpLog.error(err);
			}
		} else {
			vac = await apiManager.getService(FpApi.Resource.Duty.DutyService)
				.getVacation(app.ctx, {
					dscaid: dscaids,
					year: year,
				});
			try {
				result = new Map((await apiManager.getService(FpApi.Resource.Duty.DutyRosterVacationService)
					.get(app.ctx, {
						dscaid: dscaids,
						year: year,
					})).map(e => [ e.dscaid, e ]));
			} catch (err) {
				fpLog.error(err);
			}

		}


		const contracts = new Map((await Aigle.map(dscaids, async dscaid => {
			return (await app.store.hrContractStore.getCurrentVersion(dscaid));
		})).filter(Boolean).map(e => [ e.id, e ]));
		return dscaids.map(e => {
			return new VacationEntitlement(e,
				{
					vacation: contracts.get(e)?.data?.vacationEntitlementDays ?? 0,
					vacationFromLastYear: 0,
					vacationTaken: 0,
					...result.get(e),
					$formatted: { vac: buildVacation(e, vac), real: !!result.get(e) },
					year: year,
				}, app
			);
		});
	}

	public static days(from: DateTime, to: DateTime, options: {
		holidays?: ResolvedHoliday[],
		nonWorkingDays?: number[],
		fromHalfDay?: boolean,
		toHalfDay?: boolean,
	}): number {
		return this.getDaysBetween(from, to, options);
	}

	public static sum(from: DateTime, to: DateTime, options: {
		schedules: FpApi.Resource.Duty.Schedule[],
		nonWorkingDays?: number[],
		holidays?: ResolvedHoliday[],
		type?: FpApi.Resource.Duty.ScheduleType,
	}): number {
		/**  */
		let days = 0;
		for(const schedule of options.schedules) {
			if(options.type) if(options.type !== schedule.type) continue;
			const cloned = { ...schedule };
			const scheduleStart = DateTime.fromISO(cloned.dateFrom);
			const scheduleEnd = DateTime.fromISO(cloned.dateTo);
			if(scheduleStart <= from) cloned.dateFrom = from.toISO();
			if(scheduleEnd >= to) cloned.dateTo = to.toISO();
			const resDays = VacationUtil.days(DateTime.fromISO(cloned.dateFrom), DateTime.fromISO(cloned.dateTo), {
				holidays: options.holidays,
				nonWorkingDays: options.nonWorkingDays,
				fromHalfDay: cloned.data?.fromHalfDay,
				toHalfDay: cloned.data?.toHalfDay,
			});
			days += resDays;
		}

		return days;
	}

	/**
	 * 
	 * @param from ISO date string
	 * @param to ISO date string
	 * @param options config
	 * @returns number of milliseconds
	 */
	public static duration(from: string, to: string, options: {
		schedules: FpApi.Resource.Duty.Schedule[],
	}): number  {
		/**  */
		let duration = 0;
		for(const schedule of options.schedules) {
			const cloned = { ...schedule };
			const scheduleStart = new Date(cloned.dateFrom);
			const scheduleEnd = new Date(cloned.dateTo);
			if(scheduleStart < new Date(from)) cloned.dateFrom = from;
			if(scheduleEnd > new Date(to)) cloned.dateTo = to;
			duration += DateTime.fromISO(cloned.dateTo).diff(DateTime.fromISO(cloned.dateFrom), "milliseconds").milliseconds;
		}
		return duration;
	}

	public static async holidaysForContact(dscaid: number, options: {
		from: string,
		to: string,
		app: FleetplanApp,
		contract?: IContactsHR["rows"][number];
	}): Promise<ResolvedHoliday[]> {
		if(!options.app.ctx.hasModule("dshr")) return [];
		// in case a contract is provided, use this contract. Otherwise load the contract for the user
		const contract = options.contract ?? await (async () => {
			const contactHrData = await options.app.store.hrContractStore.getId(dscaid);
			const sortedHrRows = sortBy(contactHrData.rows, e => e.valid_from).reverse();
			// pick the currently valid one
			const dt = Number.parseInt(DateTime.fromISO(options.from).toFormat("yyyyMMdd"));
			return sortedHrRows.find(e => {
				return e.valid_from <= dt;
			});
		})();
		/** get current contract */

		const service = apiManager.getService(FpApi.Calendar.Holiday.HolidayService);
		const util = new HolidayUtil();
		const set = await service.getId(options.app.ctx, { id: contract.data.holiday });

		const bySub = util.resolveRange({
			sets: [ set ],
			subdivision: contract.data.subdivisions,
			start: DateTime.fromISO(options.from),
			end: DateTime.fromISO(options.to).minus({ minutes: 1 }),
		});

		const nationWide = util.resolveRange({
			sets: [ set ],
			start: DateTime.fromISO(options.from),
			end: DateTime.fromISO(options.to).minus({ minutes: 1 }),
		});

		return uniqBy([ ...bySub, ...nationWide ], e => e.holiday.id);
	}
}

export class VacationEntitlement {
	constructor(public dscaid: number, public obj: FpApi.Resource.Duty.DutyRosterVacation & { $formatted: {
		vac: any;
		real: boolean;
	}, year?: number}, private app: FleetplanApp) {

	}

	public used() {
		return this.app.flags.dutySchedule ?
			(this.obj["$formatted"].vac?.["subtractedCounterTTL"]?.[FpApi.Resource.Duty.ScheduleStatus.Approved] ?? 0) :
			Object.keys(this.obj["$formatted"].vac).reduce((prev, next) => {
				if(next === "ttl") return prev;
				prev = this.obj["$formatted"].vac[next].ignoreSubtractFromTimeOff ? prev :  prev + (this.obj?.["$formatted"]?.vac?.[next]?.[this.app.flags.dutySchedule ? FpApi.Resource.Duty.ScheduleStatus.Approved : FpApi.Resource.Duty.DutyStatus.Accepted] ?? 0);
				return prev;
			}, 0);
	}

	public left() {
		return this.app.flags.dutySchedule ?
			(this.obj.vacation ?? 0) - this.used() : Object.keys(this.obj["$formatted"].vac).reduce((prev, next) => {
				if(next === "ttl") return prev;
				prev = this.obj["$formatted"].vac[next].ignoreSubtractFromTimeOff ? prev :  prev - (this.obj?.["$formatted"]?.vac?.[next]?.[this.app.flags.dutySchedule ? FpApi.Resource.Duty.ScheduleStatus.Approved : FpApi.Resource.Duty.DutyStatus.Accepted] ?? 0);
				return prev;
			}, this.obj.vacation);
	}

	public requested() {
		return this.app.flags.dutySchedule ?
			(this.obj["$formatted"].vac?.["subtractedCounterTTL"]?.[FpApi.Resource.Duty.ScheduleStatus.Workflow] ?? 0) :
			Object.keys(this.obj["$formatted"].vac).reduce((prev, next) => {
				if(next === "ttl") return prev;
				prev = this.obj["$formatted"].vac[next].ignoreSubtractFromTimeOff ? prev :  prev + (this.obj?.["$formatted"]?.vac?.[next]?.[this.app.flags.dutySchedule ? FpApi.Resource.Duty.ScheduleStatus.Workflow : FpApi.Resource.Duty.DutyStatus.Request] ?? 0);
				return prev;
			}, 0);
	}

}
