import { ApiContext, apiManager, FpApi } from "@tcs-rliess/fp-core";
import { fpLog } from "@tcs-rliess/fp-log";

import { FleetplanApp } from "../FleetplanApp";

function parseInt(string: number | string): number {
	if (typeof string === "string") return Number.parseInt(string);
	else return string;
}

export class LinkUtil {
	private app: FleetplanApp;
	private get ctx(): ApiContext { return this.app.ctx; }

	private documentService = apiManager.getService(FpApi.ControlledDocument.DocumentService);

	constructor(app: FleetplanApp) {
		this.app = app;
	}

	/**
	 * Loads the given short link and calls `goto()`.
	 * @param id ID of the short link to follow.
	 * @throws this may throw is missing access right to fetch needed data.
	 */
	public async gotoShortLink(id: string): Promise<void> {
		const link = await apiManager.getService(FpApi.System.ShortLinkService).getId(this.ctx, { id });
		await this.goto(link.linkType, link.linkId, link.extra);
	}

	/**
	 * Jump to the requested object. Returned promise resolves after the `dsloader` was called, this may take a second if data has to be
	 * loaded to resolve a link.
	 *
	 * @param type target type. Example "dscaid", "dsncid", ...
	 * @param id id of the Target.
	 * @param extra provide any related ID's you know to avoid API requests.
	 * @throws this may throw if the method needs to load additional data, but the user is not allowed to do so.
	 */
	public async goto(type: string, id: number | string, extra: Record<string, number | string> = {}): Promise<void> {
		type = type.toLowerCase();
		switch (type) {
			case "flightops":
				window.location.hash = "/flight-operations/scheduledflights";
				break;
			case "fpvid":
			case "fpvid.aircraft":
				if (extra.fpreslogid) {
					window.location.hash = `/aircraft/${id}/flightlogbook/${extra.fpreslogid}/`;
				}
				else {
					window.location.hash = `/aircraft/${id}`;
				}
				break;
			case "dsmid":
			case "dsmid.manual": {
				const document = await this.app.store.controlledDocument.documentDsmid.getId(parseInt(id));
				dsloader(`dynasite.cfm?dscmd=manual_manual_manual_detail&dssid=${document.dssid}`, "tab", `manual_manual_manual_detail_${document.dssid}`, "xhr:1");
				break;
			}

			case "dsmid.standard":
			case "dsmid.regulation": {
				// note: regulations/standard are currently only returned by "getId"
				//       "get" only return documents for the current dscid
				const document = await this.documentService.getId(this.ctx, { dsmid: parseInt(id) });
				const dscdxcid = extra.dscdxcid ? `certificate/${extra.dscdxcid}` : "library";
				window.location.hash = `/compliance-safety/compliance/${dscdxcid}/document/${document.dssid}`;
				break;
			}

			case "dssid":
			case "dssid.manual": {
				dsloader(`dynasite.cfm?dscmd=manual_manual_manual_detail&dssid=${id}`, "tab", `manual_manual_manual_detail_${id}`, "xhr:1");
				break;
			}

			case "dssid.standard":
			case "dssid.regulation": {
				const dscdxcid = extra.dscdxcid ? `certificate/${extra.dscdxcid}` : "library";
				window.location.hash = `/compliance-safety/compliance/${dscdxcid}/document/${id}`;
				break;
			}

			case "dsaid": {
				if (extra.dssidType == null) {
					const result = await this.documentService.getId(this.ctx, {
						dssid: parseInt(extra.dssid),
						dsmid: parseInt(extra.dsmid),
					});
					extra.dssid = result.dssid;
					extra.dssidType = (result.$currentRevision || result.$approvalRevision || result.$editRevision).documentType;
				}

				if (extra.dssidType === "regulation") {
					// dsloader("dynasite.cfm?dscmd=quality_compliance_index_view", "tab", "quality_compliance_index_view", "xhr:1");
					const dscdxcid = extra.dscdxcid ? `certificate/${extra.dscdxcid}` : "library";
					window.location.hash = `/compliance-safety/compliance/${dscdxcid}/document/${extra.dssid}#${id}`;
				} else {
					dsloader(`dynasite.cfm?dscmd=manual_manual_manual_detail&dssid=${extra.dssid}`, "tab", `manual_manual_manual_detail_${extra.dssid}`, "xhr:1");
					window.location.hash = `dsarticle_${id}`;
				}
				break;
			}

			case "dscaid":
				dsloader(`dynasite.cfm?dscmd=contact_contact_contact_detail&dscaid=${id}`, "tab", `contact_contact_contact_detail_${id}`, "xhr:1");
				break;

			case "dscdid":
				// dsloader("dynasite.cfm?dscmd=certificate_manager_manager_manager", "tab", "certificate_manager_manager_manager", "xhr:1");
				window.location.hash = `/certificates/manager/certificates/dscdid/${id}`;
				this.app.store.certificateV3Store.setCurrentView({
					module: "certificates",
					id: id.toString(),
					type: "dscdid"
				});
				break;

			case "dscdxcid":
				fpLog.warn("LinkUtil.goto: dscdxcid deprecated");
				// dsloader(`dynasite.cfm?dscmd=certificate_certificate_person_detail&dscdxcid=${id}`, "tab", `certificate_certificate_person_detail_${id}`, "xhr:1");
				break;

			case "dsdid":
				dsloader(`dynasite.cfm?dscmd=dashboard_dashboard_dashboard_detail&dsdid=${id}`, "tab", `dashboard_dashboard_dashboard_detail_${id}`, "xhr:1");
				break;

			case "fppresbookid":
			case "fpresflightid": {
				const resType = extra.dsrestype ?? "aircraft";
				dsloader(`dynasite.cfm?cmd=resource_booking_booking_edit&dsrestype=${resType}&fpresbookid=${id}`, "tab", `resource_booking_booking_edit_${id}`, "xhr:1");
				break;
			}

			case "fpresloglid":
				dsloader(`dynasite.cfm?cmd=resource_log_leg_detail&dsrestype=aircraft&fpresloglid=${id}`, "tab", `resource_log_leg_detail_${id}`, "xhr:1");
				break;

			case "dsqmauid":
				dsloader(`dynasite.cfm?dscmd=quality_audit_audit_detail&dsqmauid=${id}`, "tab", `quality_audit_audit_detail_${id}`, "xhr:1");
				break;

			case "dsqmctrlid":
				dsloader(`dynasite.cfm?dscmd=quality_process_control_detail&dsqmctrlid=${id}`, "tab", `quality_risk_control_detail_${id}`, "xhr:1");
				break;

			case "dsqmprcsid":
				dsloader(`dynasite.cfm?dscmd=quality_process_process_detail&dsqmprcsid=${id}`, "tab", `quality_process_process_detail_${id}`, "xhr:1");
				break;

			case "dsqmevtid":
				dsloader(`dynasite.cfm?dscmd=quality_event_event_detail&dsqmevtid=${id}`, "tab", `quality_event_event_detail_${id}`, "xhr:1");
				break;

			case "dsdocid":
				dsloader(`dynasite.cfm?dscmd=finance_document_document_edit&dsdocid=${id}`, "tab", `finance_document_document_edit_${id}`, "xhr:1");
				break;

			case "dspiid":
				dsloader(`dynasite.cfm?dscmd=product_item_item_index&dspiid=${id}`, "tab", `product_item_item_index_${id}`, "xhr:1");
				break;

			case "fplaid":
				dsloader(`dynasite.cfm?cmd=system_landingfield_landingfield_detail&fplaid=${id}`, "tab", `system_landingfield_landingfield_detail_${id}`, "xhr:1");
				break;

			case "dsqmprocid":
				dsloader(`dscmd=quality_process_procedure_detail&dsqmprocid=${id}`, "tab", `quality_process_procedure_detail_${id}`, "xhr:1");
				break;

			case "dsqmrepid":
				// 2024-07-16 - [PR] deprecated
				return;
				dsloader(`dscmd=quality_report_index_view&dsqmrepid=${id}`, "tab", `quality_report_index_view_${id}`, "xhr:1");
				break;

			case "dsrlid":
				dsloader(`dscmd=security_role_role_detail&dsrlid=${id}`, "tab", `security_role_role_detail_${id}`, "xhr:1");
				break;

			case "dseid":
				// javascript:dsloader('FP_WEB','tab','event_event_event_detail_00kgakypqv_0000000000_wu',%7B%22view%22%3A%22EventDetailTab%22%2C%22linkid%22%3A0%2C%22module%22%3A%22Event%22%2C%22props%22%3A%7B%22id%22%3A%2200kgakypqv_0000000000_wu%22%7D%2C%22linkidtype%22%3A%22undefined%22%7D)
				window.location.hash = `/flight-operations/scheduledflights/${id}/${extra?.initialActiveEvent ?? id}`;
				/*dsloader("FP_WEB", "tab", `event_event_event_detail_${id}`, { "view": "EventDetailTab", "linkid": 0, "module": "Event", "props": {
					id: id,
					...extra,
				}});*/
				break;

			case "dsbid":
				// dsloader("FP_WEB", "tab", `booking_booking_booking_detail_${id}`, { "view": "BookingManager", "module": "Booking", "props": { dsbid: id }});
				dsloader(`dynasite.cfm?dscmd=booking_booking_booking_detail&dsbid=${id}`, "tab", `booking_booking_booking_detail_${id}`, "xhr:1");
				break;

			case "fpdirid":
				window.location.hash = "/directory";
				break;
			case "fpdir":
			case "fpdirloc":
			case "fpdirsec":
			case "fpdirgrp":
			case "fpdirpos":
				window.location.hash = `/directory/node/${id}/home`;
				break;
			case "file":
				/* 
					2023-10-16 - [VT] 
					Download file from media api v3
					Example:
						id = eu_stage/5035/api_export_pdf_booking_voucher/00lnlp1r02_0000000000_a3/5b807f71-71ad-4c5e-93d8-f5e516900721
				*/
				window.location.href = `/media/file/${encodeURIComponent(id)}`;
				break;

			default:
				throw new Error(`Unsupported Type "${type}" for LinkHelper.`);
		}
	}
}
