import { FpSetting, FpSettingsClient } from "@tcs-rliess/fp-core";
import EventEmitter from "events";

export class FpSettingsClientWeb extends FpSettingsClient {
	public emitter = new EventEmitter();
	public async delete(id: number, _r: string): Promise<void> {
		const node = this.sectionTree.findKey(id);
		await super.delete(id, _r);
		this.emitter.emit("delete", id);
		this.emitter.emit(`${node.data.section}:delete`, id);
	}

	public async insert<T>(section: string, data: T, options?: Parameters<FpSettingsClient["insert"]>[2]): Promise<FpSetting<T>[]> {
		const inserted = await super.insert(section, data, options);
		this.emitter.emit("insert", inserted);
		this.emitter.emit(`${section}:insert`, inserted);
		return inserted;
	}

	public async update<T>(data: FpSetting<T>): Promise<FpSetting<T>> {
		const resp = await super.update(data);
		this.emitter.emit("update", resp);
		this.emitter.emit(`${data.section}:update`, resp);
		return resp;
	}

	public subscribeSection<T>(section: string, cb: (eventName: string, data: any) => void) {
		function onDelete(id: number) {
			cb("delete", id);
		}
		function onInsert(data: FpSetting<T>[]) {
			cb("insert", data);
		}
		function onUpdate(data: FpSetting<T>) {
			cb("update", data);
		}
		this.emitter.addListener(`${section}:delete`, onDelete);
		this.emitter.addListener(`${section}:insert`, onInsert);
		this.emitter.addListener(`${section}:update`, onUpdate);

		return () => {
			this.emitter.removeListener(`${section}:delete`, onDelete);
			this.emitter.removeListener(`${section}:insert`, onInsert);
			this.emitter.removeListener(`${section}:update`, onUpdate);
		};
	}
}
