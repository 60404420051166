import { faCertificate } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CertificateStatus, FpDirClient, FpDirClientState } from "@tcs-rliess/fp-core";
import React from "react";

import { BaseStoreSingle } from "../BaseStoreSingle";

import { CertificateV3Store } from "./CertificateV3Store";

export class ContactStateStore extends BaseStoreSingle<FpDirClientState, number> {
	private dirClient = new FpDirClient({ use: "ContactStateStore" })

	protected itemId(item: FpDirClientState): number {
		return item.id;
	}

	protected async fetchIdList(idList: number[]): Promise<FpDirClientState[]> {
		const filteredIdList = this.app.store.contact.getIds(idList).map(e => e.id);
		if (filteredIdList.length) {
			const result = await this.dirClient.getContactState(this.app.dscid, filteredIdList);
			return result.rows;
		}

		return [];
	}

	public getStats(state: FpDirClientState): ReturnType<CertificateV3Store["certificatesToStats"]> {
		return this.app.store.certificateV3Store.certificatesToStats(this.getValidity(state));
	}

	/**
	 * 
	 * @param state 
	 * @param date date in ms
	 */
	public getValidity(state: FpDirClientState, date?: number) {
		if(!Array.isArray(state.certificates)) return [];
		return state.certificates.filter(c => c.properties.isRequired).map((cert) => {
			return { ...cert.properties, ...this.app.store.certificateV3Store.getTotalsFromStateCertificate(cert as unknown as any, date) };
		});
	}

	public getValidityVariant(state: FpDirClientState, date?: number) {
		if(!state?.certificates.length) return null;
		const certs = this.getValidity(state, date);
		// if(state.id === 5045036) debugger;
		if(certs.some(c => c.isExpired || c.dscatidStatus === CertificateStatus.Requested)) return "danger";
		if(certs.some(c => c.isInRenewal)) return "warning";
		if(certs.every(c => c.isValid === true)) return "success";
		// 2024-05-16 - [PR/DL] this should never happen
		return "purple";
	}

	public isAnyRequested(state: FpDirClientState, date?: number) {
		return this.getValidity(state, date).some(c => c.dscatidStatus === CertificateStatus.Requested);
	}

	public renderValidity(state: FpDirClientState, date?: number, hideOnNone = false) {
		const variant = this.getValidityVariant(state, date);
		if(variant === null && hideOnNone == false) {
			return <>
				<FontAwesomeIcon style={{ visibility: "hidden" }} fixedWidth icon={faCertificate} color={this.app.theme.color["info"][500].main}/>
				{<sup title="" style={{ color: this.app.theme.color.purple[500].main, visibility: "hidden", top: -3 }}>R</sup>}
			</>;
		}
		if(variant === null) return null;
		const isAnyRequested = this.isAnyRequested(state);
		return <>
			<FontAwesomeIcon fixedWidth icon={faCertificate} color={this.app.theme.color[variant][500].main}/>
			{<sup title="There are requested certificates" style={{ color: this.app.theme.color.purple[500].main, visibility: isAnyRequested ? undefined : "hidden", cursor: "help", top: -3 }}>R</sup>}
		</>;
	}
}
