
import { faCalendar, faCalendarCheck, faCalendarExclamation, faCalendarHeart, faCalendarImage, faCalendarTimes, faMemoCircleCheck } from "@fortawesome/pro-light-svg-icons";
import { faHelicopter } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ACTIVITY_RULESETS_MAP, ActivityGetResult, ActivityUtil, BaseCertificate, Category, ClientCategoryUtil, FpApi, FpDirClient, GetReadsResponse, IContactsHR, SubCertificate, TimeStatistic, TreeUtil, apiManager } from "@tcs-rliess/fp-core";
import { fpLog } from "@tcs-rliess/fp-log";
import Aigle from "aigle";
import { chunk, every, flatten, last, random, uniq } from "lodash-es";
import { DateTime, Duration } from "luxon";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { now } from "moment-timezone";
import React from "react";

import { FleetplanApp, useApp } from "../../../../FleetplanApp";
import { CalendarEventState, VacationEntitlement, VacationUtil } from "../../../../lib";
import { FPSet } from "../../../../lib/ClientStore/impls/CertificateV3Store";
import { CrewCheckIcon, CrewIconStatus } from "../Components/CrewCheckIcon";
import { Target } from "../Grid/_CrewCheck";

export type CrewDataLoaderTypes = "activity" | "recency" | "contract" | "vacation" |  "certificates.sets" | "duty" | "read" | "certificates.licenseEndorsements";

/** minutes */
const BUCKET_MAX_AGE = 60;

export class CrewCheckBucket {
	public activity: Map<number, ActivityGetResult> = new Map();
	public recency: Map<number, FpApi.Activity.RecencyData> = new Map();
	public contract: Map<number, IContactsHR["rows"]> = new Map();
	public vacation: Map<number, {  corrections?: VacationEntitlement }> = new Map();
	/** WIP */
	public statistics: Map<number, TimeStatistic> = new Map();
	/**
	 * readstats
	 */
	public read: Map<number, GetReadsResponse["reads"]> = new Map();
	// record -> number: setId x subCertificates
	public certificates: Map<number, Record<number, Record<number | "ttl", SubCertificate | boolean>>> = new Map();
	public baseCertificates: Map<number, BaseCertificate> = new Map();
	public licenseEndorsementToSet: Map<string, { set: FPSet, baseCertificates: BaseCertificate[] }[]> = new Map();
	public dateCreated: DateTime;
	public lastValidationDate: DateTime;

	public getLoadedStatistics() {
		return {
			bucketName: this.name,
			data: {
				activity: Array.from(this.activity.keys()),
				recency: Array.from(this.recency.keys()),
				contract: Array.from(this.contract.keys()),
				vacation: Array.from(this.vacation.keys()),
				certificates: Array.from(this.certificates.keys()),
				baseCertificates: Array.from(this.baseCertificates.keys()),
				licenseEndorsementToSet: Array.from(this.licenseEndorsementToSet.keys()),
				["recency.certificates"]: Array.from(this.certificates.keys()),
			}
		};
	}

	public byDscaid(dscaid: number) {
		return {
			activity: this.activity.get(dscaid),
			recency: this.recency.get(dscaid),
			contract: this.contract.get(dscaid),
			vacation: this.vacation.get(dscaid),
			certificates: this.certificates.get(dscaid),
			baseCertificates: this.baseCertificates.get(dscaid),
			["recency.certificates"]: this.certificates.get(dscaid),
		};
	}

	constructor(public name: string) {
		this.dateCreated = DateTime.local();
	}
}

const logger = fpLog.child("CrewCheckState");
export class CrewCheckState {
	private buckets: Map<string, CrewCheckBucket> = new Map();
	private calendarEventState: CalendarEventState;
	private categoryTree: TreeUtil<Category, number>;
	public dateCreated: DateTime;
	private controller = new AbortController();
	public interval = setInterval(() => {
		this.buckets.forEach((v, k) => {
			if(v.dateCreated.diffNow().as("minutes") > BUCKET_MAX_AGE) {
				this.buckets.delete(k);
			}
		});

	}, BUCKET_MAX_AGE * 1000 * 30);

	@observable loading = false;
	@observable.ref licenseEndorsementToSet: Map<string, { set: FPSet, baseCertificates: BaseCertificate[] }[]> = new Map();
	@observable.ref baseCertificates: Map<number, BaseCertificate> = new Map();

	constructor(private app: FleetplanApp) {
		this.dateCreated = DateTime.local();
		this.calendarEventState = new CalendarEventState(app);
	}

	public async getUserValidity(dscaid: number, validationTime: DateTime, { type, certificateSets, licenseEndorsements }: { type?: CrewDataLoaderTypes[], certificateSets?: number[], licenseEndorsements?: string[] } = {}) {
		const [ check ] = await this.get({
			dscaids: [ dscaid ],
			validationTime: validationTime,
			type: type ?? [
				"activity",
				"contract",
				"duty",
				"recency",
				"certificates.licenseEndorsements",
				"certificates.sets",
			],
			certificateSets: certificateSets,
			licenseEndorsements: licenseEndorsements
		});


		const endorsementSetsValid = check[0].certificates.licenseEndorsements?.every(s =>
			s.sets.every(s => s.ttl)
		) ?? true;

		const setsValid = check[0].certificates.sets?.every(s =>
			s.sets.every(s => s.ttl)
		) ?? true;

		return {
			setsValid,
			endorsementSetsValid,
			isValid: setsValid && endorsementSetsValid
		};
	}

	/** @param p: string -> licenseEndorsement, number -> setId */
	public getValidityForSet(dscaid: number, date: DateTime, licenseEndorsement: string, options?: { roleId?: FpApi.Calendar.Event.EventResourceRole[] }): { key: string | number, sets: Record<number | "ttl" | "validity", SubCertificate | boolean | any>[] };
	public getValidityForSet(dscaid: number, date: DateTime, set: number, options?: { roleId?: FpApi.Calendar.Event.EventResourceRole[] }): { key: string | number, sets: Record<number | "ttl" | "validity", SubCertificate | boolean | any>[] };
	public getValidityForSet(dscaid: number, date: DateTime, p: number | string, options?: { roleId?: FpApi.Calendar.Event.EventResourceRole[] }): { key: string | number, sets: Record<number | "ttl" | "validity", SubCertificate | boolean | any>[] } {
		let sets: FPSet[] = [];
		if(typeof p === "string") {
			sets.push(...this.app.store.certificateV3Store.sets.filter(
				s =>
					s.relations.find(e => e.category === "ASSIGNEDFOR" && e.type === "licenseEndorsement" && e.licenseEndorsement === p) &&
					(options?.roleId ? s.relations.filter(e => e.category === "ASSIGNEDFOR" && e.type === "dserrid" && options?.roleId.includes(e.dserrid)).length : true)
			));
			sets?.forEach(set => {
				const relations = set.relations.filter(e => e.category === "ASSIGNEDFOR" && e.type === "licenseEndorsement" && e.licenseEndorsement === p);
				relations?.forEach(e => {
					const addedMembers = set.members.map(member => this.app.store.certificateV3Store.baseCertificatesObj[member.dscdid]);
					if(!this.licenseEndorsementToSet.has(e.licenseEndorsement)) {
						this.licenseEndorsementToSet.set(e.licenseEndorsement, []);
					}
					if(this.licenseEndorsementToSet.get(e.licenseEndorsement).find(e => e.set.id === set.id)) return;
					this.licenseEndorsementToSet.get(e.licenseEndorsement)?.push({ set, baseCertificates: addedMembers });
				});
			});
		} else if (typeof p === "number") {
			sets.push(this.app.store.certificateV3Store.setsObj[p]);
			/*
			sets.push(...this.app.store.certificateV3Store.sets.filter(s => s.id === p &&
				options?.roleId ?
				s.relations.filter(e => e.category === "ASSIGNEDFOR" && e.type === "dserrid" && options?.roleId.includes(e.dserrid)).length
				: true));
			*/

		}
		const preFilterLength = sets.length;
		sets = sets.filter(Boolean);
		if(sets.length !== preFilterLength) {
			logger.warn("Some sets were not found");
		}
		const r = sets.map(set => {
			const docs = set.members.map(member => { return this.app.store.certificateV3Store.baseCertificatesObj[member.dscdid]; });
			const validity = this.app.store.certificateV3Store.getValidityForLinkBC("dscaid", dscaid?.toString(), docs, date.toISO());
			if(!validity) return null;
			const isValid = (validity.ok.length + validity.infinite.length + validity.expires.length + validity.grace.length) >= validity.required.length;
			const isInvalid = !!validity.missing.length || !!validity.requested.length || !!validity.notrequested.length || !!validity.due.length || !!validity.expired.length || !isValid;
			const result: Record<number | "ttl" | "validity" | "missing" | "expired" | "requested", SubCertificate | boolean | any> = {
				"ttl": !isInvalid,
				"validity": {},
				"missing": validity.missing,
				"expired": validity.expired,
				"requested": validity.requested,
			};
			validity.all?.forEach(e => {
				result[e.dscdid] = e;
				result["validity"][e.dscdid] = {
					styling: this.app.store.certificateV3Store.getVersionStyling(e),
					...e,
				};
			});
			result["missing"] = validity.missing;
			result["set"] = set;
			return result;
		}).filter(Boolean);
		return {
			key: p,
			sets: r,
		};
	}

	public getDataCreated(dt: DateTime) {
		return this.getBucket(dt.toFormat("yyyy-MM")).dateCreated;
	}

	private getBucket(name: string) {
		let bucket = this.buckets.get(name);
		if (bucket == null || bucket.dateCreated.diffNow().as("minutes") > BUCKET_MAX_AGE) {
			bucket = new CrewCheckBucket(name);
			this.buckets.set(name, bucket);
		}
		return bucket;
	}

	private async load(options: {
		type: Array<CrewDataLoaderTypes>,
		dscaids: Array<number> | Target,
		validationTime: DateTime,
		licenseEndorsements?: Array<string>,
		certificateSets?: Array<number>
	}) {
		try {
			this.loading = true;
			if (this.controller != null) {
				this.controller.abort();
			}
			this.controller = new AbortController();
			const { type, dscaids, validationTime: validationTime, certificateSets } = options ?? {};
			const from = validationTime?.startOf("year");
			const to = validationTime;
			/** clear already loaded, if currentlyLoadedDates or dscaid changed */

			const bucketName = validationTime.toFormat("yyyy-MM");
			const bucket = this.getBucket(bucketName);
			const yearBucket = this.getBucket(`${from.year}`);
			// dscaids for each type that have to be loaded
			const toLoad: Record<CrewDataLoaderTypes, Array<number>> = {
				activity: [],
				recency: [],
				contract: [],
				duty: [],
				vacation: [],
				"certificates.sets": [],
				"certificates.licenseEndorsements": [],
				read: [],
			};
			// if type is not in bucket, we add it to the toLoad list
			const stats = bucket.getLoadedStatistics();
			const yearStats = yearBucket.getLoadedStatistics();
			for(const t of dscaids) {
				let dscaid: number;
				// let roleIds: FpApi.Calendar.Event.EventResourceRole[];
				if(typeof t === "number") {
					dscaid = t;
				} else if(typeof t === "object" && "dscaid" in t) {
					dscaid = t.dscaid;
					// roleIds = t.roleIds;
				}
				// loop over types
				for(const t of type) {
					if([ "contract" ].includes(t)) {
						if(!yearStats.data[t]?.includes(dscaid)) {
							toLoad[t]?.push(dscaid);
						}
					} else {
						if(!stats.data[t]?.includes(dscaid)) {
							toLoad[t]?.push(dscaid);
						}
					}
				}
			}

			// if either certificate sets or license endorsements are included, we need to prepare the certificate sets
			// otherwise we can skip this
			if(certificateSets?.length || (type.includes("certificates.licenseEndorsements") || (type.includes("certificates.sets")))) {
				if (!this.app.store.certificateV3Store.initialSetLoadComplete) {
					// ensure certificate store data is loaded / up-to-date - TODO: OPTIMIZE THIS!
					await this.app.store.certificateV3Store.ensureSets();
				}
			}

			/** activities */
			// #region activities and recency
			if(type.includes("activity") || type.includes("recency") || type.includes("read")) {
				// make a copy for permission check
				let dscaidsActivity = uniq([ ...toLoad.activity, ...toLoad.recency ]);
				// if no permissions, we remove loaded data
				if(!this.app.ctx.hasPermission("module.fdt", null, FpApi.Security.PermissionModuleFdtLvl.Read)) {
					dscaidsActivity = [];
					// if we have permissions for our self and we are in the lost, we add ourself to the chunks
					if(this.app.ctx.hasPermission("module.fdt", null, FpApi.Security.PermissionModuleFdtLvl.OwnRead) && dscaids.includes(this.app.dscaid)) {
						dscaidsActivity = [ this.app.dscaid ];
					}
				}

				const chunks = chunk(dscaidsActivity, 50);
				await Aigle.eachLimit(chunks, 1, async chunk => {
					const aggregateParams: Record<string, FpApi.Activity.AggregateParams> = {};
					for(let i = 1; i <= 12; i++) {
						aggregateParams[i.toString()] = {
							start: from.set({ month: i }).startOf("month").toISO(),
							end: from.set({ month: i }).endOf("month").toISO(),
							methods: {
								[FpApi.Activity.ActivityValueType.Duty]: FpApi.Activity.AggregateMethod.SumActivityEnd,
								[FpApi.Activity.ActivityValueType.Block]: FpApi.Activity.AggregateMethod.SumActivityEnd,
							},
						};
					}
					aggregateParams["current"] = {
						start: from?.toISO(),
						end: to?.toISO(),
						methods: {
							[FpApi.Activity.ActivityValueType.Duty]: FpApi.Activity.AggregateMethod.SumActivityEnd,
							[FpApi.Activity.ActivityValueType.Block]: FpApi.Activity.AggregateMethod.SumActivityEnd,
						},
					};
					const getMany = await apiManager.getService(FpApi.Activity.ActivityService).getMany(this.app.ctx, {
						// FOR NOW
						start: from.toISO(),
						end: to.endOf("year").toISO(),
						activitySet: type.includes("activity"),
						// activities: type.includes("activity"),
						// use aggregate params for month sums ([ 0, 0, 40h, 0, ... ])
						aggregate: aggregateParams,
						runRuleset: true,
						recency: type.includes("recency"),
						recencyParams: {
							validationTime: (validationTime || DateTime.local()).toISO(),
						},
						statistics: true,
						statisticsParams: {
							start: from.startOf("year").toISO(),
							end: to.endOf("year").toISO(),
						},

						objects: chunk.filter(Boolean).map(id => ({
							linkId: `${id}`,
							linkType: "dscaid",
						})),
					}, this.controller.signal);

					for (const e of getMany) {
						bucket.activity.set(Number.parseInt(e.linkId), ActivityGetResult.fromResult(e));
					}
				});
				// insert loaded chunks into already loaded set
			}
			// #endregion
			// #region contracts
			if(type.includes("contract") && toLoad.contract.length) {
				const dscaidsContract = toLoad.contract;
				dscaidsContract.forEach(e => {
					yearBucket.contract.set(e, []);
				});
				const chunks = chunk(dscaidsContract, 100);
				const dirClient = new FpDirClient({ use: "CrewDataLoader", ctx: this.app.ctx });
				for (const req of chunks) {
					// talk to PL -> need mgetRange method to be public: permissions?
					const from = validationTime?.endOf("year");
					const response = await dirClient.contractHrFilterByDate(this.app.dscid, from, { filters: [{ id: req, }] });
					response.rows.forEach(e => {
						if(!yearBucket.contract.has(e.id)) {
							yearBucket.contract.set(e.id, []);
						}
						yearBucket.contract.get(e.id).push(e);
					});
				}
			}
			// #endregion
			// #region duty, vacation, medical leave
			const dutyDscaids = Array.from(new Set([ ...toLoad.duty ]));
			if(type.includes("duty") && dutyDscaids.length > 0) {
				const ids = new Map<FpApi.Resource.Duty.SystemCategoryType, Array<number>>([
					[ FpApi.Resource.Duty.SystemCategoryType.Vacation, []],
					[ FpApi.Resource.Duty.SystemCategoryType.Work, []],
					[ FpApi.Resource.Duty.SystemCategoryType.MedicalLeave, []],
					[ FpApi.Resource.Duty.SystemCategoryType.LocalDay, []],
				]);
				const tree = await this.app.store.categoryUtil.getTypeOptionsUtil("duty_type");
				this.categoryTree = tree;
				/** load duties */
				/*
				await this.calendarEventState.getEvents(from, to, {
					dscaid: dutyDscaids,
				});
				*/
				tree.walk((info) => {
					const idName: FpApi.Resource.Duty.SystemCategoryType = info.node.data.category.idName as any;
					ids.get(idName)?.push(info.node.id);
				});
				const types = flatten(Array.from(ids.values()));
				if(types.length) {
					const statistics = await apiManager.getService(FpApi.Resource.Duty.ScheduleService).getStatistics(this.app.ctx, {
						from: from.toISO(),
						to: from.endOf("year").toISO(),
					});
					const statisticsMonth = await apiManager.getService(FpApi.Resource.Duty.ScheduleService).getStatistics(this.app.ctx, {
						from: to.startOf("month").toISO(),
						to: to.endOf("month").toISO(),
					});
					dutyDscaids.forEach(e => {
						bucket.statistics.set(e, statisticsMonth[`dscaid-${e}`] ? TimeStatistic.fromTimeStatistic(statisticsMonth[`dscaid-${e}`]) : null);
						yearBucket.statistics.set(e, statistics[`dscaid-${e}`] ? TimeStatistic.fromTimeStatistic(statistics[`dscaid-${e}`]) : null);
						yearBucket.vacation.set(e, { corrections: undefined });
					});

					if(type.includes("vacation")) {
						const entries = await VacationUtil.load(from.year, dutyDscaids, this.app);
						entries?.forEach(ve => {
							// add corrections to the buckets
							bucket.vacation.set(ve.dscaid, { corrections: undefined });
							yearBucket.vacation.set(ve.dscaid, { corrections: ve });
						});
					}
				}
			}

			if(type.includes("read")) {
				const readMap = new Map((await this.app.store.controlleddocument.getReads(toLoad.read)).map(e => ([ e.dscaid, e.reads ])));
				readMap.forEach((v, k) => {
					yearBucket.read.set(k, v);
				});
			}
			// #endregion
			this.loading = false;
		} catch (err) {
			if (err.code === "CLIENT_REQUEST_ABORTED") {
				return;
			}
			this.loading = false;
			throw err;
		}
	}

	/** @param v is the return type of the return type of the function {@link get}  */
	public getIcons(v: any, validationTime: DateTime): Record<string, CrewCheckIcon> {
		return {
			certs: this.getCertIcon(v, validationTime),
			fdt: this.getFDTIcon(v, validationTime),
			contract_year: this.getContractIconYear(v, validationTime),
			read: this.getReadsIcon(v, validationTime),
			// duties: this.getOnDutyIcon(v, validationTime),
		};
	}

	public getFDTIcon(v: any, validationTime: DateTime): CrewCheckIcon {
		const activity: ActivityGetResult = v.activity.obj;

		if (activity == null) {
			// activity not loaded
			return {
				variant: this.app.theme.color.cancelled[500].main,
				tooltip: "no Data",
				status: null,
			};
		}

		const annoArray = activity.annotationForTimeSpan(validationTime.startOf("month"), validationTime.endOf("month"));
		const annotationKind = ActivityUtil.highestAnnotation(annoArray);
		// find one example annotation we will use for the tooltip
		const annotation = annoArray.find(e => e.kind === annotationKind);

		const variant = ClientCategoryUtil
			.byEnum(FpApi.Activity.AnnotationKind)
			.getOption(annotationKind) // note: annotationKind might be undefined if we have no annotations
			?.variant;

		const ret: CrewCheckIcon = {
			variant: this.app.theme.color[variant ?? "success"]?.[500]?.main,
			tooltip: annotation ? `FDT: \n${annotation.reason}` : null,
			status: null,
		};
		switch(annotationKind) {
			case FpApi.Activity.AnnotationKind.Warning: ret["status"] = CrewIconStatus.PARTIAL_OK; break;
			case FpApi.Activity.AnnotationKind.Deviation: ret["status"] = CrewIconStatus.NOT_OK; break;
			case FpApi.Activity.AnnotationKind.Error: ret["status"] = CrewIconStatus.NOT_OK; break;
			case FpApi.Activity.AnnotationKind.Hint: ret["status"] = CrewIconStatus.OK; break;
			default: ret["status"] = CrewIconStatus.OK; break;
		}
		return ret;
	}

	public getCertIcon(v: any, validationTime: DateTime): CrewCheckIcon {
		if (!this.app.ctx.hasModule("dshr_certs")) return null;

		const all = [ ...(v?.certificates?.licenseEndorsements ?? []), ...(v?.certificates?.sets ?? []) ];
		const ev = all.length ? every(all, cert => cert.sets?.length ? every(cert.sets, set => set.ttl) : false) : false;
		const validations = {
			expired: [],
			missing: [],
			requested: []
		};

		all.flatMap(k => k.sets).forEach(s => {
			validations["expired"].push(...(s.expired ?? []));
			validations["missing"].push(...(s.missing ?? []));
			validations["requested"].push(...(s.requested ?? []));
		});
		let status: CrewIconStatus;
		if(all.length) {
			if(ev) status = CrewIconStatus.OK;
			else status = CrewIconStatus.NOT_OK;
		}
		const ret = {
			status,
			variant: null,
			tooltip: null,
		};
		// variant
		switch(status) {
			case CrewIconStatus.OK: ret.variant = this.app.theme.color.success[500].main; break;
			case CrewIconStatus.NOT_OK: ret.variant = this.app.theme.color.danger[500].main; break;
			default: ret.variant = this.app.theme.color.cancelled[500].main; break;
		}
		// tooltip
		let tooltip = "Certificates\n";
		if(validations.expired.length) {
			tooltip += `Expired: ${validations.expired.slice(0, 2).map(e => e.name)} ${validations.expired.length - 3 > 0 ?
				` and ${validations.expired.length - 3} more...` : ""}\n`;
		}
		if(validations.missing.length) {
			tooltip += `Missing: ${validations.missing.slice(0, 2).map(e => e.name)} ${validations.missing.length - 3 > 0 ?
				` and ${validations.missing.length - 3} more...\n` : "\n"}`;
		}
		if(validations.requested.length) {
			tooltip += `Requested: ${validations.requested.slice(0, 2).map(e => e.name)} ${validations.requested.length - 3 > 0 ?
				` and ${validations.requested.length - 3} more...\n` : "\n"}`;
		}
		if(tooltip === "Certificates\n") tooltip = null;
		ret["tooltip"] = tooltip;
		return ret;
	}

	public getContractIconYear(v: any, validationTime: DateTime): CrewCheckIcon {
		if (!this.app.ctx.hasModule("workerprofile")) return null;

		const percentage = v.activity.year.DUTY.remaining / ((v.contract
			?.workhoursYear));
		if(!v.contract) {
			return {
				variant: this.app.theme.color.cancelled[500].main,
				tooltip: "Worker Profile missing",
				status: CrewIconStatus.OK,
			};
		}
		const contractYear = {
			variant: this.app.theme.color[percentage > 0.5 ? "success" : percentage > 0.25 ? "warning" : "danger"][500].main,
			tooltip: v.activity.year.DUTY.remaining ? `Remaining: ${Math.ceil(Duration.fromObject({ seconds: v.activity.year.DUTY.remaining }).as("hours"))}h` : null,
			status: null,
		};
		if(Number.isNaN(percentage)) {
			contractYear["status"] = CrewIconStatus.OK;
			contractYear["variant"] = "cancelled";
		}
		if(percentage > 0.5) contractYear["status"] = CrewIconStatus.OK;
		else if(percentage > 0.25) contractYear["status"] = CrewIconStatus.PARTIAL_OK;
		else contractYear["status"] = CrewIconStatus.NOT_OK;
		return contractYear;
	}

	public getOnDutyIcon(v: any, validationTime: DateTime): CrewCheckIcon {
		const eventsOnDay = this.calendarEventState.getEventsByDay(validationTime.toFormat("d-M-y"), [ `dscaid:${v.dscaid}` ]);

		const groupedEvents = {
			[FpApi.Resource.Duty.SystemCategoryType.Work]: [],
			[FpApi.Resource.Duty.SystemCategoryType.MedicalLeave]: [],
			[FpApi.Resource.Duty.SystemCategoryType.Vacation]: [],
			"flights": [],
			"meetings": [],
		};
		eventsOnDay.forEach(e => {
			if(+validationTime >= +e.start && +validationTime <= +e.end) {
				if(e.source === "duty") {
					const category = this.categoryTree.findKey(e.extra.dscatidType);
					if(category) groupedEvents[category.data.category.idName].push(e);
				} else if (e.source === "event") {
					if(e.extra.event.type === "FLIGHT") {
						groupedEvents["flights"].push(e);
					} else {
						groupedEvents["meetings"].push(e);
					}
				}
			}
		});

		const onDutyIcon = {
			variant: null,
			tooltip: null,
			status: null,
		};

		if(groupedEvents["flights"].length) {
			onDutyIcon["status"] = CrewIconStatus.NOT_OK;
			onDutyIcon["variant"] = this.app.theme.color.warning[500].main;
			onDutyIcon["tooltip"] = `Is Flying (${groupedEvents["flights"][0]?.extra.label_title})`;
			onDutyIcon["icon"] = <span className="fa-stack fa-2x" style={{ fontSize: 7, marginTop: -2 }}>
				<FontAwesomeIcon fixedWidth icon={faCalendar} className="fa-stack-2x"/>
				<FontAwesomeIcon fixedWidth icon={faHelicopter} className="fa-stack-1x"  />
			</span>;
		} else if(groupedEvents["meetings"].length) {
			onDutyIcon["status"] = CrewIconStatus.NOT_OK;
			onDutyIcon["variant"] = this.app.theme.color.warning[500].main;
			onDutyIcon["tooltip"] = "In Meeting";
			onDutyIcon["icon"] = <FontAwesomeIcon fixedWidth icon={faCalendarExclamation}/>;
		} else if(groupedEvents[FpApi.Resource.Duty.SystemCategoryType.MedicalLeave].length) {
			onDutyIcon["status"] = CrewIconStatus.NOT_OK;
			onDutyIcon["variant"] = this.app.theme.color.danger[500].main;
			onDutyIcon["tooltip"] = "Medical Leave";
			onDutyIcon["icon"] = <FontAwesomeIcon fixedWidth icon={faCalendarHeart}/>;
		} else if(groupedEvents[FpApi.Resource.Duty.SystemCategoryType.Vacation].length) {
			onDutyIcon["status"] = CrewIconStatus.NOT_OK;
			onDutyIcon["variant"] = this.app.theme.color.danger[500].main;
			onDutyIcon["tooltip"] = "On Vacation";
			onDutyIcon["icon"] = <FontAwesomeIcon fixedWidth icon={faCalendarImage}/>;
		} else if(groupedEvents[FpApi.Resource.Duty.SystemCategoryType.Work].length) {
			onDutyIcon["status"] = CrewIconStatus.OK;
			onDutyIcon["variant"] = this.app.theme.color.success[500].main;
			onDutyIcon["tooltip"] = "On Duty";
			onDutyIcon["icon"] = <FontAwesomeIcon fixedWidth icon={faCalendarCheck}/>;
		} else {
			onDutyIcon["status"] = CrewIconStatus.PARTIAL_OK;
			onDutyIcon["variant"] = this.app.theme.color.warning[500].main;
			onDutyIcon["tooltip"] = "Not Scheduled";
			onDutyIcon["icon"] = <FontAwesomeIcon fixedWidth icon={faCalendarTimes}/>;
		}

		return onDutyIcon;
	}

	public getReadsIcon(v: any, validationTime: DateTime): CrewCheckIcon {
		const reads: GetReadsResponse["reads"] = v.reads;
		if(!reads) {
			return {
				status: CrewIconStatus.OK,
				variant: this.app.theme.color.cancelled[500].main,
				tooltip: "No Read Tracking",
				icon: <FontAwesomeIcon fixedWidth icon={faMemoCircleCheck}/>,
			};
		}

		const unread = reads.reduce((res, read) => {
			return read.total_unread + res;
		}, 0);

		return {
			status: unread > 0 ? CrewIconStatus.NOT_OK : CrewIconStatus.OK,
			variant: unread > 0 ? this.app.theme.color.danger[500].main : this.app.theme.color.success[500].main,
			tooltip: unread > 0 ? `Unread (${unread})` : "Read",
			icon: <FontAwesomeIcon fixedWidth icon={faMemoCircleCheck}/>,
		};
	}

	public async get(options: {
		type: Array<CrewDataLoaderTypes>,
		dscaids: Array<number> | Target,
		validationTime: DateTime,
		licenseEndorsements?: Array<string>,
		certificateSets?: Array<number>
	}) {
		const { dscaids, validationTime, licenseEndorsements, certificateSets } = options;
		const dutyTree = await this.app.store.categoryUtil.getTypeOptionsUtil("duty_type");
		await this.load(options);
		const bucket = this.buckets.get(validationTime.toFormat("yyyy-MM"));
		const yearBucket = this.buckets.get(validationTime.toFormat("yyyy"));
		const columns = new TreeUtil([], {
			getChildren: (node: any) => node.children,
			getKey: (node: any) => node.colId,
		});
		const ret =  [ dscaids.map(t => {
			let dscaid: number;
			let roleIds: FpApi.Calendar.Event.EventResourceRole[];
			if(typeof t === "number") {
				dscaid = t;
			} else if(typeof t === "object" && "dscaid" in t) {
				dscaid = t.dscaid;
				roleIds = t.roleIds;
			}
			// build statistics
			const [ activityDutyYear, activityDutyBlock ] = [ FpApi.Activity.ActivityValueType.Duty, FpApi.Activity.ActivityValueType.Block ].map(type => {
				const t: Array<number> = [];
				for(const key in (bucket.activity?.get(dscaid)?.aggregate) ?? []) {
					if(key === "current") continue;
					// aggregate unit is seconds
					t.push(
						Duration.fromObject({
							seconds: (bucket.activity.get(dscaid)?.aggregate[key]["values"][type] ?? 0),
						}).as("seconds")
					);
				}
				return t;
			});

			const duty = {
				"month": {
					"ttl": last(bucket.statistics.get(dscaid)?.series(`type:${FpApi.Resource.Duty.ScheduleType.Duty}:status:APPROVED`)?.getData())?.[1] ?? 0,
					"sa": last(bucket.statistics.get(dscaid)?.series(`type:${FpApi.Resource.Duty.ScheduleType.Duty}:sat`)?.getData())?.[1] ?? 0,
					"su": last(bucket.statistics.get(dscaid)?.series(`type:${FpApi.Resource.Duty.ScheduleType.Duty}:sun`)?.getData())?.[1] ?? 0,
				},
				"year": {
					"ttl": last(yearBucket.statistics.get(dscaid)?.series(`type:${FpApi.Resource.Duty.ScheduleType.Duty}:status:APPROVED`)?.getData())?.[1] ?? 0,
					"sa": last(yearBucket.statistics.get(dscaid)?.series(`type:${FpApi.Resource.Duty.ScheduleType.Duty}:sat`)?.getData())?.[1] ?? 0,
					"su": last(yearBucket.statistics.get(dscaid)?.series(`type:${FpApi.Resource.Duty.ScheduleType.Duty}:sun`)?.getData())?.[1] ?? 0,
				},
				"current_year": {
					"ttl": yearBucket.statistics.get(dscaid)?.series(`type:${FpApi.Resource.Duty.ScheduleType.Duty}:status:APPROVED`)?.getInterpolatedValue(validationTime) ?? 0,
					"sa": yearBucket.statistics.get(dscaid)?.series(`type:${FpApi.Resource.Duty.ScheduleType.Duty}:sat`)?.getInterpolatedValue(validationTime) ?? 0,
					"su": yearBucket.statistics.get(dscaid)?.series(`type:${FpApi.Resource.Duty.ScheduleType.Duty}:sun`)?.getInterpolatedValue(validationTime) ?? 0,
					"chart_per_month_diff": yearBucket.statistics.get(dscaid)
						?.series(`type:${FpApi.Resource.Duty.ScheduleType.Duty}:status:APPROVED`)
						?.createAggregatedSeries({
							series: {
								key: `category:chart:${FpApi.Resource.Duty.ScheduleType.Duty}:diff`,
							},
							durationUnit: "month",
							method: "last",
						})
						.createDiffSeries()
						.getData(),
					"chart_per_month": yearBucket.statistics.get(dscaid)
						?.series(`type:${FpApi.Resource.Duty.ScheduleType.Duty}:status:APPROVED`)
						?.createAggregatedSeries({
							series: {
								key: `category:chart:${FpApi.Resource.Duty.ScheduleType.Duty}:agg`,
							},
							durationUnit: "month",
							method: "last",
						})
						.getData(),
				},
				"current_month": {
					"ttl": bucket.statistics.get(dscaid)?.series(`type:${FpApi.Resource.Duty.ScheduleType.Duty}:status:APPROVED`)?.getInterpolatedValue(validationTime) ?? 0,
					"sa": bucket.statistics.get(dscaid)?.series(`type:${FpApi.Resource.Duty.ScheduleType.Duty}:sat`)?.getInterpolatedValue(validationTime) ?? 0,
					"su": bucket.statistics.get(dscaid)?.series(`type:${FpApi.Resource.Duty.ScheduleType.Duty}:sun`)?.getInterpolatedValue(validationTime) ?? 0,
				},
				"statistics": {
					"ttl": yearBucket.statistics.get(dscaid)?.seriesArray().map(e => ({
						key: e.params.key,
						value: e.getInterpolatedValue(validationTime),
					})) ?? []
				}
			};

			const diffDutyTime = bucket.activity
				.get(dscaid)?.statistics
				.find(e => e.key === `type:${FpApi.Resource.Duty.ScheduleType.Duty}:status:APPROVED`)
				?.series("idName:work")
				?.createAggregatedSeries({
					series: {
						key: "idName:work:diff",
					},
					durationUnit: "month",
					method: "last",
				})
				.createDiffSeries()
				.getData() ?? [];

			const dutyTime = bucket.activity
				.get(dscaid)?.statistics
				.find(e => e.key === `type:${FpApi.Resource.Duty.ScheduleType.Duty}:status:APPROVED`)
				?.series("idName:work")
				?.createAggregatedSeries({
					series: {
						key: "idName:work:agg",
					},
					durationUnit: "month",
					method: "last",
				})
				.getData() ?? [];

			const diffMedicalLeave = yearBucket.statistics.get(dscaid)?.series(`type:${FpApi.Resource.Duty.ScheduleType.MedicalLeave}`)
				?.createAggregatedSeries({
					series: {
						key: `category:chart:${FpApi.Resource.Duty.ScheduleType.MedicalLeave}:diff`
					},
					durationUnit: "month",
					method: "last",
				})
				.createDiffSeries()
				.getData() ?? [];

			const medicalLeave = yearBucket.statistics.get(dscaid)?.series(`type:${FpApi.Resource.Duty.ScheduleType.MedicalLeave}`)
				?.createAggregatedSeries({
					series: {
						key: `category:chart:${FpApi.Resource.Duty.SystemCategoryType.MedicalLeave}:agg`,
					},
					durationUnit: "month",
					method: "last",
				})
				.getData() ?? [];

			const medAndDutyDiffActual = diffDutyTime?.map((dataPoint, index) => {
				return [ dataPoint?.[0] ?? diffMedicalLeave[index][0], dataPoint?.[1] + (diffMedicalLeave?.[index]?.[1] ?? 0) ];
			});

			const medAndDutyActual = dutyTime?.map((dataPoint, index) => {
				return [ dataPoint?.[0] ?? medicalLeave[index][0], dataPoint?.[1] + (medicalLeave?.[index]?.[1] ?? 0) ];
			});


			const activity = {
				"obj": bucket.activity.get(dscaid),
				"slider": bucket.activity.get(dscaid)?.aggregate,
				"year": {
					[FpApi.Activity.ActivityValueType.Duty]: {
						"chart_per_month_diff": diffDutyTime,
						"chart_per_month": bucket.activity
							.get(dscaid)?.statistics
							.find(e => e.key === "statistics:duty_type")
							?.series("idName:work")
							?.createAggregatedSeries({
								series: {
									key: "idName:work:agg",
								},
								durationUnit: "month",
								method: "last",
							}).getData()
						,
						"chart_per_month_by_type": (() => {
							const statistics = [];
							const workSeries = bucket.activity
								.get(dscaid)?.statistics
								.find(e => e.key === "statistics:duty_type")
								.series("idName:work:diff");
							if(workSeries)
								statistics.push(workSeries);
							dutyTree.walk((node) => {
								const stats = bucket.activity
									.get(dscaid)?.statistics
									.find(e => e.key === "statistics:duty_type")
									?.series(`dscatid:${node.node.id}`)
									?.createAggregatedSeries({
										series: {
											key: `dscatid:${node.node.id}:agg`,
										},
										durationUnit: "month",
										method: "last",
										fillNulls: true,
									});
								const diffs = stats?.createDiffSeries({
									series: {
										key: `dscatid:${node.node.id}:diff`,
									},
								});
								if(stats)
									statistics.push(stats);
								if(diffs)
									statistics.push(diffs);
							});
							const ret = {};
							statistics.forEach((stat) => {
								ret[stat.params.key] = stat;
							});
							return ret;
						})(),
						"statistic": activityDutyYear.map(e => Duration.fromObject({ seconds: e }).as("hours")),
						"ttl": activityDutyYear.reduce((a, b) => a + b, 0),
						"remaining": ((yearBucket.contract?.get(dscaid)?.at(0)?.data.workhoursYear ?? 0) * 60 * 60) - (activityDutyYear.reduce((a, b) => a + b, 0)),
					},
					[FpApi.Activity.ActivityValueType.Block]: {
						"statistic": activityDutyBlock.map(e => Duration.fromObject({ seconds: e }).as("hours")),
						"ttl": activityDutyBlock.reduce((a, b) => a + b, 0),
					},
					[`${FpApi.Activity.ActivityValueType.Duty}_with_med`]: {
						"chart_per_month_diff": medAndDutyDiffActual,
						"chart_per_month": medAndDutyActual,
						"ttl": medAndDutyActual?.at(-1)?.[1] ?? 0,
					}
				},
				"month": {
					[FpApi.Activity.ActivityValueType.Duty]: {
						"ttl": bucket.activity.get(dscaid)?.aggregate[validationTime.month].values[FpApi.Activity.ActivityValueType.Duty] ?? 0,
					},
					[FpApi.Activity.ActivityValueType.Block]: {
						"ttl": bucket.activity.get(dscaid)?.aggregate[validationTime.month].values[FpApi.Activity.ActivityValueType.Block] ?? 0,
					},
					[`${FpApi.Activity.ActivityValueType.Duty}_with_med`]: {
						"current":
							(yearBucket.statistics.get(dscaid)?.series(`category:${FpApi.Resource.Duty.SystemCategoryType.MedicalLeave}`)?.getInterpolatedValue(validationTime) ?? 0) +
							(bucket.activity
								.get(dscaid)?.statistics
								.find(e => e.key === "statistics:duty_type")
								?.series("idName:work")?.getInterpolatedValue(validationTime) ?? 0),
						"ttl":
							(yearBucket.statistics.get(dscaid)?.series(`category:${FpApi.Resource.Duty.SystemCategoryType.MedicalLeave}`)?.getInterpolatedValue(validationTime.endOf("month")) ?? 0) +
							(bucket.activity
								.get(dscaid)?.statistics
								.find(e => e.key === "statistics:duty_type")
								?.series("idName:work")?.getInterpolatedValue(validationTime.endOf("month")) ?? 0),
					}
				},
			};


			const v =  {
				dscaid: dscaid,
				roleIds: roleIds,
				activity,
				ruleset: {
					statistics: (() => {
						if(!bucket.activity.get(dscaid)?.statistics) return null;
						if(!options.type.includes("activity")) return null;
						const ret: Record<string, number> = {};
						for(const e of (bucket.activity.get(dscaid)?.statistics ?? [])) {
							if(e.key === "statistics:duty_type") continue;
							const ruleset = ACTIVITY_RULESETS_MAP.get(bucket.activity.get(dscaid)?.activitySet?.rulesets[0]);

							// 2024-05-09 - [AP, DL]  this crashes because the ruleset apparently can not exist - https://tcs.myjetbrains.com/youtrack/issue/FP-15730/Cant-Edit-Some-Flights
							if (!ruleset) continue;

							if(!columns.findKey(ruleset?.key)) {
								columns.insertUnderParent({
									colId: ruleset.key,
									data: ruleset,
									children: [],
								}, null);
							}
							// implement generated columns from ruleset -> return columns (for rule setname use $ruleset[0].shortName)
							e.seriesArray().forEach(series => {
								if(!columns.findKey(`${ruleset.key}-${e.key}`) && !series.key.startsWith("statistics:")) {
									columns.insertUnderParent({
										colId: `${ruleset.key}-${e.key}`,
										headerName: e.params.title,
										children: [],
									}, ruleset.key);
								}
								if(!columns.findKey(`${ruleset.key}-${e.key}-${series.key}`) && !series.key.startsWith("statistics:")) {
									let dataType = "number";
									const properties = {};
									if(series.params.format.type === "BOOLEAN") {
										dataType = "none";
										properties["cellStyle"] = (params) => ({
											background: (() => {
												return params.value === 0 ? this.app.theme.color.success[500].main : this.app.theme.color.danger[500].main;
											})(),
											color: (() => {
												return params.value === 0 ? this.app.theme.color.success[500].font : this.app.theme.color.danger[500].font;
											})(),
										});
									} else {
										properties["cellStyle"] = (params) => ({
											textAlign: "right",
										});
									}
									columns.insertUnderParent({
										...properties,

										colId: `${ruleset.key}-${e.key}-${series.key}`,
										headerName: series.params.title,
										dataType: dataType !== "none" ? dataType : undefined,
										valueGetter: params => {
											return params.data.ruleset.statistics[`${ruleset.key}-${e.key}-${series.key}`];
										},
										getExportValue: (params) => {
											return series.formatValue(params.value);
										},
										cellRenderer: (params) => {
											return series.formatValue(params.value);
										}
									}, `${ruleset.key}-${e.key}`);
								}
								ret[`${ruleset.key}-${e.key}-${series.key}`] = series.getInterpolatedValue(validationTime);
							});
						}
						return ret;
					})(),
				},
				/** this is used for old activity recency */
				$formatted: {
					...(bucket.activity.get(dscaid)?.recency  ?? {}),
				},
				duty,
				vacation: {
					"requested": last(yearBucket.statistics.get(dscaid)?.series(`vac:status:${this.app.flags.dutySchedule ? FpApi.Resource.Duty.ScheduleStatus.Workflow : FpApi.Resource.Duty.DutyStatus.Request}`)?.getData())?.[1] ?? 0,
					"rejected": last(yearBucket.statistics.get(dscaid)?.series(`vac:status:${this.app.flags.dutySchedule ? FpApi.Resource.Duty.ScheduleStatus.Rejected : FpApi.Resource.Duty.DutyStatus.Rejected}`)?.getData())?.[1] ?? 0,
					"accepted": last(yearBucket.statistics.get(dscaid)?.series(`vac:status:${this.app.flags.dutySchedule ? FpApi.Resource.Duty.ScheduleStatus.Approved : FpApi.Resource.Duty.DutyStatus.Accepted}`)?.getData())?.[1] ?? 0,
					"corrections": yearBucket.vacation.get(dscaid)?.corrections,
				},
				statistics: yearBucket.statistics.get(dscaid),
				statisticsByMonth: bucket.statistics.get(dscaid),
				certificates: {
					sets: options.type.includes("certificates.sets") ? certificateSets?.map(e => this.getValidityForSet(dscaid, validationTime, e, { roleId: roleIds })) : null,
					licenseEndorsements: options.type.includes("certificates.licenseEndorsements") ? licenseEndorsements?.map(e => this.getValidityForSet(dscaid, validationTime, e, { roleId: roleIds })) : null,
				},
				contract: yearBucket?.contract.get(dscaid)?.[0],
				reads: yearBucket?.read.get(dscaid),
				icons: null,
			};

			v["icons"] = this.getIcons(v, validationTime);
			return v;
		}), columns ] as const;
		return ret;
	}
}

export const validationDateCtx = React.createContext<DateTime>(null);

export const CrewCheckAge: React.FC<any> = observer((props) => {
	const app = useApp();
	const [ age, setAge ] = React.useState<string>();

	const dt = React.useContext(validationDateCtx);

	React.useEffect(() => {
		let current;
		function runNext(): void {
			if(current) clearTimeout(current);
			const lastUpdated = app.store.crewCheck.getDataCreated(dt);
			if(lastUpdated) {
				const age = lastUpdated.toRelative();// app.formatter.dateTime(lastUpdated);
				setAge(age);
				let nextTime = 1000;
				const diff = lastUpdated.valueOf() - now();
				if(diff > 60000 && diff < 60000 * 60) {
					nextTime = 60000;
				} else if(diff < 60000) {
					nextTime = random(1000, 15000);
				} else {
					nextTime = 60000 * 60;
				}

				current = setTimeout(() => {
					runNext();
				}, nextTime);
			}
		}
		runNext();
		return () => clearTimeout(current);
	}, [ app.store.crewCheck.loading, dt ]);


	return <div>
		{age ? <>Updated at &nbsp;{age}</> : null}
	</div>;
});
