import { FpDirClient, IContactsHR } from "@tcs-rliess/fp-core";
import { DateTime } from "luxon";

import { BaseStoreSingle } from "../BaseStoreSingle";
import Aigle from "aigle";

interface Item extends IContactsHR {
	dscaid: number;
}

export class HRContractStore extends BaseStoreSingle<IContactsHR, number> {
	private dirClient = new FpDirClient({ use: "HRContractStore" });

	protected itemId(item: IContactsHR): number {
		return (item as Item).dscaid;
	}

	// 2024-01-19 - [PR] We need a different solution for this. This takes a long time to load.
	protected async fetchIdList(idList: number[]): Promise<IContactsHR[]> {
		const response = await Aigle.mapLimit(idList, 3, async id => {
			const item = await this.dirClient.contractHrGet(this.app.ctx.dscid, id);
			(item as Item).dscaid = id;
			return item;
		});
		return response;
	}

	public async getCurrentVersion(id: number): Promise<IContactsHR["rows"][number]> {
		const contract = await this.getId(id);
		const dateNow = DateTime.now();
		let datesArr = [];
		for (const item of contract?.rows) { datesArr.push(item.valid_from); }
		datesArr = datesArr.filter(date => DateTime.fromISO(date?.toString()) < dateNow);
		const validNow = datesArr[datesArr?.length-1];
		return contract?.rows.find(item => item.valid_from === validNow);
	}
}
