import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faAlignLeft, faAnchor, faAngleDoubleDown, faAngleDoubleRight, faAngleDoubleUp, faAngleLeft, faAngleUp, faArchive, faArrowAltFromLeft, faArrowAltToRight,
	faArrowDown, faArrowFromLeft, faArrowLeft, faArrowRight, faArrows, faArrowToRight, faArrowUp, faBalanceScale, faBan, faBars, faBell, faBells, faBolt, faBook,
	faBookAlt, faBoxFull, faBriefcase, faBuilding, faCalculator, faCalendar, faCalendarAlt, faCalendarCheck, faCalendarExclamation, faCaretDown,
	faCaretLeft, faCaretRight, faCartArrowDown, faCertificate, faChalkboardTeacher, faChartBar, faChartLine, faChartPie, faCheck, faCheckCircle,
	faCheckSquare, faChevronDoubleLeft, faChevronDoubleRight, faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faCircle, faClipboard, faClipboardList,
	faClock, faCloudUpload, faCodeBranch, faCog, faCogs, faColumns, faCommentLines, faCommentPlus, faComments, faCommentsAlt, faCommentSlash, faCompressAlt,
	faCopy, faCreditCard, faCrosshairs, faCube, faCubes, faDatabase, faDigitalTachograph, faDotCircle, faDownload, faEdit, faEllipsisV, faEnvelope,
	faExclamation, faExclamationTriangle, faExpandAlt, faEye, faFile, faFileAlt, faFileCode, faFileExcel, faFileInvoice, faFilePdf, faFileSignature, faFileWord,
	faFilter, faFistRaised, faFlask, faFolder, faFolderOpen, faGhost, faGift, faGlobe, faGraduationCap, faHandHeart, faHandHoldingBox, faHandHoldingMagic,
	faHandshake, faHandsHelping, faHashtag, faHdd, faHeadphones, faHistory, faHome, faHourglassHalf, faInbox, faInfoCircle, faInfoSquare, faKey, faLightbulb,
	faLink, faList, faListAlt, faLocationArrow, faLock, faLockOpen, faLongArrowRight, faMap, faMapMarker, faMapSigns, faMinus, faMinusCircle, faMinusSquare,
	faMobile, faMoneyBillAlt, faNewspaper, faPaperPlane, faPencil, faPencilAlt, faPhone, faPlane, faPlus, faPlusSquare, faPrint, faProjectDiagram,
	faPuzzlePiece, faQuestion, faQuestionCircle, faReceipt, faRepeat, faReply, faRss, faSave, faSearch, faShare, faShareSquare, faShield, faShieldCheck,
	faShoppingBag, faShoppingCart, faSigma, faSignOut, faSignOutAlt, faSitemap, faSlidersH, faSpinner, faSquare, faStar, faStepBackward, faStepForward,
	faStickyNote, faStopwatch, faSuitcase, faSync, faTable, faTablet, faTabletAlt, faTachometer, faTachometerAlt, faTag, faTasks, faTh, faThList, faThumbsUp,
	faTicket, faTimes, faTint, faTrash, faUndo, faUnlink, faUnlock, faUpload, faUser, faUserCheck, faUserLock, faUsers, faUsersCog, faUserSecret, faWrench,
	faPaperclip, faFax, faFlag, faICursor, faParagraph, faFont, faCrown, faHandPointRight, faAt, faHelicopter, faDrone, faDroneAlt, faSignature, faShip, faReplyAll, faPercent,
	faGlobeEurope, faCity, faWarehouse, faHospital, faUsersClass, faHospitalAlt, faExternalLink, faHeadphonesAlt, faChairOffice, faComment, faCreditCardFront, faDoorClosed, faPhoneArrowUpRight, faPhoneArrowDownLeft
} from "@fortawesome/pro-solid-svg-icons";

library.add(
	faAlignLeft, faAnchor, faAngleDoubleDown, faAngleDoubleRight, faAngleDoubleUp, faAngleLeft, faAngleUp, faArchive, faArrowAltFromLeft, faArrowAltToRight,
	faArrowDown, faArrowFromLeft, faArrowLeft, faArrowRight, faArrows, faArrowToRight, faArrowUp, faBalanceScale, faBan, faBars, faBell, faBells, faBolt, faBook,
	faBookAlt, faBoxFull, faBriefcase, faBuilding, faCalculator, faCalendar, faCalendarAlt, faCalendarCheck, faCalendarExclamation, faCaretDown,
	faCaretLeft, faCaretRight, faCartArrowDown, faCertificate, faChalkboardTeacher, faChartBar, faChartLine, faChartPie, faCheck, faCheckCircle,
	faCheckSquare, faChevronDoubleLeft, faChevronDoubleRight, faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faCircle, faClipboard, faClipboardList,
	faClock, faCloudUpload, faCodeBranch, faCog, faCogs, faColumns, faCommentLines, faCommentPlus, faComments, faCommentsAlt, faCommentSlash, faCompressAlt,
	faCopy, faCreditCard, faCrosshairs, faCube, faCubes, faDatabase, faDigitalTachograph, faDotCircle, faDownload, faEdit, faEllipsisV, faEnvelope,
	faExclamation, faExclamationTriangle, faExpandAlt, faEye, faFile, faFileAlt, faFileCode, faFileExcel, faFileInvoice, faFilePdf, faFileSignature, faFileWord,
	faFilter, faFistRaised, faFlask, faFolder, faFolderOpen, faGhost, faGift, faGlobe, faGraduationCap, faHandHeart, faHandHoldingBox, faHandHoldingMagic,
	faHandshake, faHandsHelping, faHashtag, faHdd, faHeadphones, faHistory, faHome, faHourglassHalf, faInbox, faInfoCircle, faInfoSquare, faKey, faLightbulb,
	faLink, faList, faListAlt, faLocationArrow, faLock, faLockOpen, faLongArrowRight, faMap, faMapMarker, faMapSigns, faMinus, faMinusCircle, faMinusSquare,
	faMobile, faMoneyBillAlt, faNewspaper, faPaperPlane, faPencil, faPencilAlt, faPhone, faPlane, faPlus, faPlusSquare, faPrint, faProjectDiagram,
	faPuzzlePiece, faQuestion, faQuestionCircle, faReceipt, faRepeat, faReply, faRss, faSave, faSearch, faShare, faShareSquare, faShield, faShieldCheck,
	faShoppingBag, faShoppingCart, faSigma, faSignOut, faSignOutAlt, faSitemap, faSlidersH, faSpinner, faSquare, faStar, faStepBackward, faStepForward,
	faStickyNote, faStopwatch, faSuitcase, faSync, faTable, faTablet, faTabletAlt, faTachometer, faTachometerAlt, faTag, faTasks, faTh, faThList, faThumbsUp,
	faTicket, faTimes, faTint, faTrash, faUndo, faUnlink, faUnlock, faUpload, faUser, faUserCheck, faUserLock, faUsers, faUsersCog, faUserSecret, faWrench,
	faPaperclip, faFax, faFlag, faICursor, faParagraph, faFont, faCrown, faHandPointRight, faAt, faHelicopter, faDrone, faDroneAlt, faSignature, faShip, faReplyAll,
	faPercent, faGlobeEurope, faCity, faWarehouse, faHospital, faUsersClass, faHospitalAlt, faExternalLink, faHeadphonesAlt, faChairOffice, faComment, faCreditCardFront, faDoorClosed, faPhoneArrowDownLeft,
	faPhoneArrowUpRight
);
