import { FpApi, EventUtil as EventUtilCore, FPEventResource, FPEvent, FPEventWaypoint, TimeSpan, FPFlightSearchResult, ClientCategoryUtil } from "@tcs-rliess/fp-core";
import { DateTime, Duration } from "luxon";

import { FleetplanApp } from "../FleetplanApp";

export interface WaypointPayload {
	mass: Record<"crew" | "pax" | "cargo" | "total" | "max", number>;
	amount: Record<"crew" | "pax" | "cargo" | "total", number>;
	capacity: Record<"crew" | "pax" | "total", number>;
}

export interface SearchFlightParams {
	dateEarliest: string;
	dateLatest: string;
	maxStopDuration?: string;
	from: number;
	to: number;
	maxStops?: number;
	dscatidMission?: number;
	fpvid?: number;
}

interface PayloadResource extends FPEventResource {
	stops: string[]; // array of waypoint ids where this resource flies through
}

interface SearchParams {
	events: FPEvent[];
	containers: FPEvent[];
	startTimeSpan: TimeSpan;
	endTimeSpan: TimeSpan;

	from: number;
	to: number;
	// dateEarliest: string;
	// dateLatest: string;
	maxStops: number;
	maxStopDuration: Duration;
}

export class EventUtil extends EventUtilCore {
	public static async getPayload(params: {
		app: FleetplanApp;
		/** with with: type = flight */
		event: FPEvent;
		fromWaypoint: FPEventWaypoint;
		toWaypoint: FPEventWaypoint;
		resources: FPEventResource[];
		bookingItems?: Map<string, FpApi.Booking.BookingItem>;
	}): Promise<WaypointPayload> {
		const { app, event, fromWaypoint, toWaypoint, bookingItems } = params;

		const flightWaypointsIds = event.waypoints.map(wp => wp.id);

		const payload = {
			mass: { crew: 0, pax: 0, cargo: 0, total: 0, max: 0 },
			amount: { crew: 0, pax: 0, cargo: 0, total: 0 },
			capacity: { crew: 0, pax: 0, total: 0 },
		};
		if (event.flight == null || event.waypoints == null) return payload;

		// find aircraft
		const aircraftResource = params.resources.find(r => r.link_type === "fpvid");
		let aircraft: FpApi.Resource.Aircraft;
		if (aircraftResource?.link_id != null) {
			aircraft = await app.store.resource.aircraft.getId(parseInt(aircraftResource.link_id));

			payload.mass.max = fromWaypoint.allowed_payload_kg ?? aircraft.technicalMaxTakeOffMass - aircraft.technicalBasicEmptyMass;
			payload.capacity.crew = aircraft.capacityPersonMax - aircraft.capacityPersonPax;
			payload.capacity.pax = fromWaypoint.available_seats ?? aircraft.capacityPersonPax;
			payload.capacity.total = payload.capacity.pax + payload.capacity.crew;
		}

		const crew: PayloadResource[] = [];
		const pax: PayloadResource[] = [];
		const cargo: PayloadResource[] = [];

		// pax cargo is the bagweight of pax items, which is then added to cargo total
		const paxCargo: number[] = [];

		for (const resource of params.resources) {
			const fromIndex: number = flightWaypointsIds.findIndex(w => w === resource.dsefwpid_from);
			const toIndex: number = flightWaypointsIds.findIndex(w => w === resource.dsefwpid_to);

			if (fromIndex === -1 || toIndex === -1) {
				if (resource.inherit_route) {
					if (resource.link_type === "dscaid") {
						// do not count crew for drones
						if (!aircraft || aircraft.subType !== FpApi.Resource.AircraftType.UAS) crew.push({ ...resource, stops: flightWaypointsIds });
						continue;
					}
				}
				// couldn't find route
				continue;
			}

			const flightStops = flightWaypointsIds.slice(fromIndex, (toIndex + 1));
			// check if resource is on the route we're calculating payload for
			if (flightStops.includes(fromWaypoint.id) && flightStops.includes(toWaypoint.id)) {
				const role = ClientCategoryUtil.byEnum(FpApi.Calendar.Event.EventResourceRole).getValue(resource.dserrid);
				if (role == null) continue;

				// pax are special because bagWeight is considered cargo
				if (role === FpApi.Calendar.Event.EventResourceRole.BookingItemPax) {
					const bi = bookingItems?.get(resource.link_id);
					if (bi && bi.pax && bi.pax.bagWeight) {
						paxCargo.push(bi.pax.bagWeight);
					}
				}

				switch (role) {
					case FpApi.Calendar.Event.EventResourceRole.Aircraft: break;
					case FpApi.Calendar.Event.EventResourceRole.BookingItemCargo:
						cargo.push({ ...resource, stops: flightStops });
						break;
					case FpApi.Calendar.Event.EventResourceRole.BookingItemPax:
						pax.push({ ...resource, stops: flightStops });
						break;
					default:
						break;
				}
			}
		}

		payload.mass.crew = crew.reduce((mass, r) => mass + (r.weight ?? 0), 0);
		payload.mass.pax = pax.reduce((mass, r) => mass + (r.weight ?? 0), 0);
		payload.mass.cargo = cargo.reduce((mass, r) => mass + (r.weight ?? 0), 0) + paxCargo.reduce((partialSum, a) => partialSum + a, 0);
		payload.mass.total = payload.mass.crew + payload.mass.pax + payload.mass.cargo;

		payload.amount.crew = crew.length;
		payload.amount.pax = pax.length;
		payload.amount.cargo = cargo.length + paxCargo.length;
		payload.amount.total = payload.amount.crew + payload.amount.pax + payload.amount.cargo;

		return payload;
	}

	public static getPayloadChange(event: FPEvent/*, state: EventDetailState, fromWaypoint: Event.EventFlightWaypoint*/): {
		on: Record<"crew" | "pax" | "cargo" | "baggage" | "total", { amount: number; mass: number }>;
		off: Record<"crew" | "pax" | "cargo" | "baggage" | "total", { amount: number; mass: number }>;
		total: Record<"crew" | "pax" | "cargo" | "baggage" | "total", { amount: number; mass: number }>;
	} {
		const loadChange = {
			on: { crew: { amount: 0, mass: 0 }, pax: { amount: 0, mass: 0 }, cargo: { amount: 0, mass: 0 }, baggage: { amount: 0, mass: 0 }, total: { amount: 0, mass: 0 }},
			off: { crew: { amount: 0, mass: 0 }, pax: { amount: 0, mass: 0 }, cargo: { amount: 0, mass: 0 }, baggage: { amount: 0, mass: 0 }, total: { amount: 0, mass: 0 }},
			total: { crew: { amount: 0, mass: 0 }, pax: { amount: 0, mass: 0 }, cargo: { amount: 0, mass: 0 }, baggage: { amount: 0, mass: 0 }, total: { amount: 0, mass: 0 }},
		};

		if (event.flight == null || event.waypoints == null) return loadChange;

		return loadChange;
	}


	public async searchFlight(app: FleetplanApp, params: SearchFlightParams): Promise<FPFlightSearchResult[]> {
		params = {
			maxStops: 1,
			maxStopDuration: Duration.fromObject({ hours: 2 }).toISO(),
			...params,
		};

		const dateEarliest = DateTime.fromISO(params.dateEarliest);
		const dateLatest = DateTime.fromISO(params.dateLatest);
		const maxStopDuration = Duration.fromISO(params.maxStopDuration);

		let events = await app.store.event.getRange(dateEarliest, dateLatest);
		if (params.dscatidMission) events = events.filter(e => e.type === FpApi.Calendar.Event.EventType.Container || (e.type === FpApi.Calendar.Event.EventType.Flight && e.dscatid_category === params.dscatidMission));
		if (params.fpvid) events = events.filter(e => e.resources.some(r => r.link_type === "fpvid" && r.link_id === params.fpvid.toString()));

		const results = this.internalSearch({
			events: events.filter(e => e.type === FpApi.Calendar.Event.EventType.Flight),
			containers: events.filter(e => e.type === FpApi.Calendar.Event.EventType.Container),
			from: params.from,
			to: params.to,
			maxStopDuration: maxStopDuration,
			maxStops: params.maxStops,

			startTimeSpan: new TimeSpan(dateEarliest, dateLatest),
			endTimeSpan: new TimeSpan(dateEarliest, dateLatest),
		});

		return results;
	}

	public mapSingleEvent(event: FPEvent, containerEvent: FPEvent, params: SearchFlightParams) {
		const results = [];

		let fromWaypoint: string;
		for (let i = 0; i < event.waypoints.length; i++) {
			const waypoint = event.waypoints[i];
			const nextWaypoint = event.waypoints[i + 1];

			// stop searching if maxstops are exceeded
			if (i > params.maxStops) break;

			// set the new 'from' until we find a matching 'to'
			if (waypoint.fplaid === params.from) fromWaypoint = waypoint.id;

			// if 'from' is set and we have a next waypoint (aka destination), check if it matches with our params
			if (fromWaypoint && nextWaypoint) {
				if (nextWaypoint.fplaid === params.to) {
					results.push({
						events: [{
							children: [ event ],
							container: [ containerEvent ],
							flight: event.id,
							fromWaypoint: fromWaypoint,
							toWaypoint: nextWaypoint.id,
						}],
					});
					// clear the 'from' as it's being used now
					fromWaypoint = null;
				}
			}
		}

		return results;
	}

	private internalSearch(params: SearchParams): FPFlightSearchResult[] {
		const results: FPFlightSearchResult[] = [];

		for (const event of params.events) {
			if ([ FpApi.Calendar.Event.EventStatus.Completed, FpApi.Calendar.Event.EventStatus.Cancelled ].includes(event.status)) continue;
			if (event.type !== FpApi.Calendar.Event.EventType.Flight) continue;

			const fromIndices = this.findIndices(event, params.from);
			for (const fromIndex of fromIndices) {
				const fromWaypoint = event.waypoints[fromIndex];

				const std = DateTime.fromISO(fromWaypoint.std);
				if (params.startTimeSpan.includes(std) === false) {
					// departure not within search parameters
					continue;
				}

				for (let toIndex = fromIndex + 1; toIndex < event.waypoints.length; toIndex++) {
					const toWaypoint = event.waypoints[toIndex];

					const sta = DateTime.fromISO(toWaypoint.sta);
					if (params.endTimeSpan.includes(sta) === false) {
						// arrival not within search parameters
						continue;
					}

					if (params.to == toWaypoint.fplaid) {
						// direct connection
						results.push({
							events: [{
								children: params.events,
								container: params.containers.find(e => e.id.includes(event.id)),
								flight: event.id,
								fromWaypoint: fromWaypoint.id,
								toWaypoint: toWaypoint.id,
							}],
							//waypoints: event.flight.waypoints.slice(fromIndex, toIndex + 1),
						});
					} else if (params.maxStops > 0) {
						// look for connection
						const connectResults = this.internalSearch({
							events: params.events,
							containers: params.containers,
							from: toWaypoint.fplaid,
							to: params.to,
							// next flight must depart within the `maxStopDuration`
							startTimeSpan: new TimeSpan(sta, sta.plus(params.maxStopDuration)),
							// can end whenever
							endTimeSpan: new TimeSpan(sta, params.endTimeSpan.end),
							maxStops: params.maxStops - 1,
							maxStopDuration: params.maxStopDuration,
						});

						for (const connectResult of connectResults) {
							results.push(connectResult);
							/*results.push({
								events: [
									{
										children: params.events,
										container: params.containers.find(e => e.id.includes(event.id)),
										flight: event.id,
										fromWaypoint: fromWaypoint.id,
										toWaypoint: toWaypoint.id,
									},
									...connectResult.events,
								],
								//waypoints: [
								//	...event.flight.waypoints.slice(fromIndex, toIndex + 1),
								//	...connectResult.waypoints,
								//],
							});*/
						}
					}
				}
			}
		}

		return results;
	}

	/**
	 * Search for waypoints with the given fplaid, return their indices.
	 */
	private findIndices(event: FPEvent, fplaid: number): number[] {
		const leftOver = event.waypoints;

		const indices: number[] = [];
		for (let i = 0; i < leftOver?.length; i++) {
			const waypoint = leftOver[i];

			if (fplaid == waypoint.fplaid) {
				indices.push(i);
			}
		}

		return indices;
	}
}

export const eventUtil = new EventUtil();
