import { apiManager, FpApi } from "@tcs-rliess/fp-core";

import { BaseStoreMulti } from "../BaseStoreMulti";

export class ContactTargetWorkingTimeCorrectionStore extends BaseStoreMulti<FpApi.Contact.ContactTargetWorkingTimeCorrection, number> {
	protected loadedYears = new Map<number, FpApi.Contact.ContactTargetWorkingTimeCorrection[]>();
	protected history = new Map<number, Map<number, FpApi.Contact.ContactTargetWorkingTimeCorrection[]>>();
	protected itemId(item: FpApi.Contact.ContactTargetWorkingTimeCorrection): number {
		return item.id;
	}

	protected async fetchAll(): Promise<FpApi.Contact.ContactTargetWorkingTimeCorrection[]> {
		const data = await apiManager
			.getService(FpApi.Contact.ContactTargetWorkingTimeCorrectionService)
			.get(this.ctx, {
				year: new Date().getFullYear(),
			});
		this.loadedYears.set(new Date().getFullYear(), data);
		return data;
	}

	public async getYear(year: number): Promise<FpApi.Contact.ContactTargetWorkingTimeCorrection[]> {
		if(this.loadedYears.has(year)) {
			return this.loadedYears.get(year);
		}
		const corrections = await apiManager
			.getService(FpApi.Contact.ContactTargetWorkingTimeCorrectionService)
			.get(this.ctx, {
				year: year,
			});
		this.loadedYears.set(year, corrections);
		corrections.forEach(c => this.update(c));
		return corrections;
	}

	/** updates entry in year when year already loaded */
	public updateYear(entry: FpApi.Contact.ContactTargetWorkingTimeCorrection) {
		this.update(entry);
		this.loadedYears.get(entry.year)?.push(entry);
	}

	/** updates entry in year when year already loaded */
	public removeYear(entry: FpApi.Contact.ContactTargetWorkingTimeCorrection) {
		this.remove(entry.id);
		const y = this.loadedYears.get(entry.year);
		const index = y.findIndex(e => e.id === entry.id);
		if(index >= 0) {
			y.splice(index, 1);
		}
	}

	public async getForUser(dscaid: number, date: Date): Promise<number> {
		const year = date.getFullYear();
		const history = await this.getAll();
		const correction = history.find(c => c.isCurrent && c.dscaid === dscaid && c.year === year);
		if(correction) {
			return correction.hours;
		} else {
			const hrProfile = await this.app.store.hrContractStore.getId(dscaid);
			const ts = +`${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}`;
			let currentRow = hrProfile.contacts_hr_item;
			for(const row of hrProfile.rows) {
				if(!currentRow) {
					currentRow = row;
					continue;
				}
				const rowTs = row.valid_from;
				const currentRowTs = currentRow.valid_from;
				// if the row we are looking at is newer then the the timestamp row, we ignore it. Since at this point this contract is not yet active.
				if(rowTs > ts) continue;
				// if the row we are looking at is older then the current row, we ignore it.
				if(rowTs < currentRowTs) continue;
				currentRow = row;
			}
			if(currentRow) {
				return currentRow.data?.workhoursYear;
			}
		}
	}

	public async getHistory(dscaid: number, year: number): Promise<FpApi.Contact.ContactTargetWorkingTimeCorrection[]> {
		const history = this.history.get(dscaid)?.get(year);
		if(history) {
			return history;
		} else {
			const data = await apiManager.getService(FpApi.Contact.ContactTargetWorkingTimeCorrectionService)
				.getHistory(this.ctx, {
					dscaid,
					year,
				});
			if(!this.history.has(dscaid)) {
				this.history.set(dscaid, new Map<number, FpApi.Contact.ContactTargetWorkingTimeCorrection[]>());
			}
			this.history.get(dscaid).set(year, data);
			data.forEach(c => this.update(c));
			return data;
		}
	}

	public flushHistory(dscaid: number, year: number): void {
		this.history.get(dscaid)?.delete(year);
	}
}
