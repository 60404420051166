import { FpApi, apiManager } from "@tcs-rliess/fp-core";
import { castArray, get, set, toPath } from "lodash-es";

import { FleetplanApp } from "../../FleetplanApp";

import { DataLoader } from "./DataLoader";

export const RESOLVED = Symbol("resolved");
export const RESOLVE_RELATION = Symbol("resolveRelation");
const RESOLVE_DONE = Symbol("resolveDone");

export interface ResolveConfig {
	/**
	 * Path to a property to determine the link type if each item in the array.
	 */
	pathLinkType?: string;
	/**
	 * Optional path to prefix all `paths` with.
	 * The `resolved` object will only use the `path` and ignore the `basePath`
	 */
	pathBase?: string;

	types: Record<string, {
		paths: Array<ResolvePathConfig>;
	}>;
}

export interface ResolvePathConfig<T = any> {
	when?: (item: T) => boolean;

	resolvedPath?: string;
	linkType: string;
	linkId: string | ((item: T) => number | number[] | string | string[] | Omit<FpApi.InlineRelation, "category"> | Omit<FpApi.InlineRelation, "category">[]);
}

interface ResolveState<ITEM extends object> {
	items: ITEM[];
	loadList: Map<string, Set<string | number>>;
	resolvedMap: Map<string, Map<unknown, unknown>>;

	matches: Array<{
		item: ITEM;
		sourceObject: any;
		resolvedObject: any;
		isArray: boolean;
		relations: FpApi.InlineRelation[];
		pathConfig: ResolvePathConfig;
	}>;
}

interface ResolverParams<ITEM extends object> {
	app: FleetplanApp;
	config: ResolveConfig;
	force?: boolean;
	items: ITEM[];
}

export class Resolver<ITEM extends object> {
	private app: FleetplanApp;
	private dataLoader: DataLoader;

	private config: ResolveConfig;
	private state: ResolveState<ITEM>;

	public static async resolve<ITEM extends object>(params: ResolverParams<ITEM>): Promise<void> {
		const resolver = new Resolver(params);
		await resolver.resolve(params.force);
	}

	private constructor(params: ResolverParams<ITEM>) {
		this.app = params.app;
		this.dataLoader = new DataLoader(params.app);

		this.config = params.config;
		this.state = {
			items: params.items,
			loadList: new Map(),
			resolvedMap: new Map(),
			matches: [],
		};
	}

	private async resolve(force?: boolean): Promise<void> {
		// resolving works in three steps

		// 1. loop through all items, and collect id's we need to load
		//    they will be collected in state.loadList, in Set's
		this.collectItems(force);
		// 2. load all items
		await this.loadItems();
		// 3. apply loaded data to the original item
		this.resolveItems();
	}

	private collectItems(force = false): void {
		const { items, loadList } = this.state;

		const addLoad = (linkType: string, linkId: string | number): void => {
			// ignore items with no id
			if (linkId == null || linkId === 0) {
				return;
			}

			const linkTypeSet = loadList.get(linkType);
			if (linkTypeSet == null) {
				loadList.set(linkType, new Set([ linkId ]));
			} else {
				linkTypeSet.add(linkId);
			}
		};

		// loop trough all items and collect ids
		for (const item of items) {
			if (force === false) {
				if (item[RESOLVE_DONE]) continue;
				item[RESOLVE_DONE] = true;
			}

			// figure out link type and type config
			const linkType = get(item, this.config.pathLinkType);
			const typeConfig = this.config.types[linkType] ?? this.config.types["__DEFAULT"];

			// ignore if unknown type
			if (typeConfig == null) continue;

			for (const pathConfig of typeConfig.paths) {
				let sourceObject: any;

				if (this.config.pathBase) {
					sourceObject = get(item, this.config.pathBase);
				} else {
					sourceObject = item;
				}

				// find "resolved" object
				const resolvedObject = sourceObject[RESOLVED] ?? (sourceObject[RESOLVED] = {});

				// check if path applies
				let applies = true;
				if (pathConfig.when) applies = pathConfig.when(sourceObject);
				if (applies !== true) continue;

				// store if we are an array or not
				let isArray = false;
				let relations: FpApi.InlineRelation[];

				// resolve
				let result;
				if (typeof pathConfig.linkId === "string") {
					const path = toPath(pathConfig.linkId);
					result = [ sourceObject ];

					for (const pathElement of path) {
						result = result
							.flatMap(current => {
								isArray = isArray || Array.isArray(current[pathElement]);
								return current[pathElement];
							})
							.filter(o => o != null);
					}
				} else {
					// call function
					result = pathConfig.linkId(sourceObject);
					isArray = Array.isArray(result);
					result = castArray(result);
				}

				// resolve relation object
				const linkType = pathConfig.linkType;
				if (linkType === "!relation") {
					relations = result;
				} else {
					relations = result.map(id => ({ type: linkType, [linkType]: id }));
				}

				// add to load list
				relations.forEach(relation => addLoad(relation.type, relation[relation.type]));

				this.state.matches.push({ item, sourceObject, resolvedObject, pathConfig, isArray, relations });
			}
		}
	}

	private async loadItems(): Promise<void> {
		const { loadList, resolvedMap } = this.state;

		const addResolved = (linkType: string, linkId: unknown, data: unknown): void => {
			// drop empty data (missing item, deleted, ...)
			if (data == null) return;

			// get map object
			let linkTypeMap = resolvedMap.get(linkType);
			if (linkTypeMap == null) {
				resolvedMap.set(linkType, linkTypeMap = new Map());
			}

			// set relation into resolved item
			data[RESOLVE_RELATION] = {
				type: linkType,
				[linkType]: linkId,
			};

			linkTypeMap.set(linkId, data);
		};

		const handle = (err) => {
			console.error("unhandled error!", err);
		};

		for (const [ linkType, idList ] of loadList) {
			switch (linkType) {
				case "dscatid": {
					for (const dscatid of idList) {
						addResolved(linkType, dscatid, await this.app.store.categoryUtil.getId(parseInt(dscatid as string)).catch(handle));
					}
					break;
				}
				case "dscatid:old": {
					for (const dscatid of idList) {
						addResolved(linkType, dscatid, this.app.store.systemCategory.getId(parseInt(dscatid as string)));
					}
					break;
				}
				case "dsrdsid": {
					const postLoad: string[] = [];
					for (const dsrdsid of idList) {
						const $dsrdsid = this.app.store.resource.setup.getId(dsrdsid as string);
						if(!$dsrdsid) {
							postLoad.push(dsrdsid as string);
							continue;
						}
						addResolved(linkType, dsrdsid, $dsrdsid);
					}
					if(postLoad.length) {
						const data = await apiManager.getService(FpApi.Resource.Duty.SetupService).getIds(this.app.ctx, { idList: postLoad });
						for (const item of data) {
							// insert to store
							this.app.store.resource.setup.update(item);
							addResolved(linkType, item.id, item);
						}
					}
					break;
				}
				default: {
					const [ resolvedItems, idPath ] = await this.dataLoader.load(linkType, Array.from(idList));
					for (const resolvedItem of resolvedItems) {
						addResolved(linkType, get(resolvedItem, idPath), resolvedItem);
					}
				}
			}
		}
	}

	private resolveItems(): void {
		const { matches, items, resolvedMap } = this.state;

		for (const item of items) {
			if(!item[RESOLVED]) item[RESOLVED] = {};
			item[RESOLVED]["map"] = resolvedMap;
		}

		for (const match of matches) {
			if (match.pathConfig.resolvedPath == null) continue;

			if (match.isArray) {
				const mapped = match.relations
					.map(relation => {
						return resolvedMap
							.get(relation.type)
							?.get(relation[relation.type]);
					})
					.filter(o => o != null);

				set(match.resolvedObject, match.pathConfig.resolvedPath, mapped);
			} else {
				const relation = match.relations[0];
				if (relation == null) {
					set(match.resolvedObject, match.pathConfig.resolvedPath, undefined);
					continue;
				}

				const resolved = resolvedMap
					.get(relation.type)
					?.get(relation[relation.type]);

				set(match.resolvedObject, match.pathConfig.resolvedPath, resolved);
			}
		}
	}
}
