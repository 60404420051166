import { ApiContext, FpDirClient, IContactsHR, IContactsHRRow } from "@tcs-rliess/fp-core";
import { DataProcessorGetParams, FP_QUERY } from "@tcs-rliess/fp-query";
import { DateTime } from "luxon";

export class FpDirClientDataProcessor {
	private ctx: ApiContext;
	private client: FpDirClient;

	constructor (ctx: ApiContext) {
		this.ctx = ctx;
		this.client = new FpDirClient({ use: "FpDirClientDataProcessor" });
	}

	public contractHrGetByDate(options: DataProcessorGetParams<{dscaid: number; date: number; }>): Promise<IContactsHR> {
		return FP_QUERY.query({
			key: "FpDirClient.contractHrGetByDate",
			maxAge: options.cache?.maxAge,
			params: options.params,
			work: params => this.client.contractHrGetByDate(this.ctx.dscid, params.dscaid, params.date),
		});
	}
	public contractHrGetCurrentIdList(options: DataProcessorGetParams<{list: number[] }>): Promise<IContactsHRRow[]> {
		return FP_QUERY.query({
			key: "FpDirClient.contractHrGetCurrentIdList",
			maxAge: options.cache?.maxAge,
			params: options.params,
			work: async params => (await this.client.contractHrFilterByDate(this.ctx.dscid, DateTime.local(), { filters: [{ id: params.list, }] })).rows,
		});
	}
}
