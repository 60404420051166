import { apiManager, FpApi, TimeSpan } from "@tcs-rliess/fp-core";
import { QUERY_CACHE } from "@tcs-rliess/fp-query";
import { debounce, DebouncedFunc } from "lodash-es";
import { DateTime } from "luxon";

import { handleError } from "../../../../handleError";
import { BaseStoreRange } from "../../BaseStoreRange";

export class ResourceScheduleStore extends BaseStoreRange<FpApi.Resource.Duty.Schedule, number> {
	protected itemId(item: FpApi.Resource.Duty.Schedule): number {
		return item.id;
	}
	protected itemDateModified(item: FpApi.Resource.Duty.Schedule): DateTime {
		return DateTime.fromISO(item.dateModified);
	}

	protected itemRange(item: FpApi.Resource.Duty.Schedule): TimeSpan {
		return new TimeSpan(
			DateTime.fromISO(item.dateFrom),
			DateTime.fromISO(item.dateTo),
		);
	}

	protected async fetchIdList(idList: number[]): Promise<FpApi.Resource.Duty.Schedule[]> {
		return await apiManager
			.getService(FpApi.Resource.Duty.ScheduleService)
			.getIdList(this.app.ctx, { idList });
	}

	protected async fetchRange(from: DateTime, to: DateTime): Promise<FpApi.Resource.Duty.Schedule[]> {
		return await apiManager
			.getService(FpApi.Resource.Duty.ScheduleService)
			.get(this.app.ctx, {
				from: from.toISO(),
				to: to.toISO(),
			});
	}

	private dutyRefreshDebounceMap: Record<number, DebouncedFunc<any>> = {};
	private set: Set<string> = new Set();

	/**
	 * 
	 * @param item 
	 * @param checkRefresh ONLY use this when you know you need it. If you are unsure, you probably don't need it.
	 * @param isRemove ONLY use this when you know you need it. If you are unsure, you probably don't need it.
	 * @returns 
	 */
	public update(item: FpApi.Resource.Duty.Schedule, checkRefresh?: boolean, isRemove?: boolean): void {
		super.update(item);

		QUERY_CACHE.deletePrefix("FpApi/Resource/Duty/ScheduleService.get");

		if (!checkRefresh) return;
		if (!this.app.ctx.hasModule("enterprise")) return;
		if (item.type !== FpApi.Resource.Duty.ScheduleType.Duty) return;
		// keep track of duty assignment for deduplication, if multiple similar duties get creates
		const checkKey = `${item.linkId}-${(item.data.fpdirgrp ?? 0)}-${(item.data.fpdirloc ?? 0)}-${(item.data.fpdirpos ?? 0)}`;
		if (this.set.has(checkKey)) return;

		const foundDuplicate = this.checkForDuplicates(item.linkId, {
			grp: item.data.fpdirgrp,
			loc: item.data.fpdirloc,
			pos: item.data.fpdirpos,
		});

		if (foundDuplicate.length) {
			// adding, and found nothing in assignments
			return;
		}

		// make no duplicate calls for the same assignment
		this.set.add(checkKey);
		this.contactDutyRefresh(item.linkId);
	}

	private checkForDuplicates(linkId: string, { grp, loc, pos } : { grp?: number, loc?: number, pos?: number }) {
		const membersForTargetContact = this.app.store.fpDir.directory.getMembersByResource("dscaid", linkId);

		const foundDuplicate = membersForTargetContact.filter(e =>
			// not set grp, loc, pos are undefined for duty schedules
			(grp ?? 0) === e.grp &&
			(loc ?? 0) === e.loc &&
			(pos ?? 0) === e.pos && e.src !== "duty"
		);

		// when we found a duplicate we will return true
		return foundDuplicate;
	}

	/**
	 * Notifies fp-dir when a resources schedules gets created and members for that use need to be updated
	 * @param linkId dscaid
	 */
	private contactDutyRefresh(linkId: string): void {
		// if a debounce function is already setup for this contact, just call that one
		if (this.dutyRefreshDebounceMap[linkId]) {
			this.dutyRefreshDebounceMap[linkId]();
			return;
		}

		this.dutyRefreshDebounceMap[linkId] = debounce(
			async () => {
				try {
					await this.app.fpDirClient.contactDutyRefresh(this.app.ctx.dscid, +linkId);
					// not sure if we also might want to just selective load members, currently there is no method for that
					await this.app.store.fpDir.directory.reloadMembers();
				} catch (err) {
					handleError(err);
				} finally {
					delete this.dutyRefreshDebounceMap[linkId];
				}
			},
			1000,
			{
				trailing: true,
				leading: false,
			}
		);
		// start debounce
		this.dutyRefreshDebounceMap[linkId]();
	}
}
