import { apiManager, ControlledDocumentUtil, FpApi, SubCertificate, TreeUtil } from "@tcs-rliess/fp-core";
import { FP_QUERY } from "@tcs-rliess/fp-query";
import type { AsyncAutoTasks, Dictionary } from "async";
import auto from "async/auto";
import Lock from "async-lock";
import { remove } from "lodash-es";
import { action, observable } from "mobx";

import { FleetplanApp } from "../FleetplanApp";
import { ControlledDocumentState } from "../modules/ControlledDocument/lib/ControlledDocumentState";

import { FpReadsClientDataProcessor, ServiceDataProcessor } from "./fp-query";

interface MediaApiFile {
	dsmediaapiid: number;
	LINKIDTYPE: string;
	LINKID: string;
	LINKIDSUBTYPE: string;

	KEY: string;
	FILENAME: string;

	// ACL: {DELETE: "dsdefault", OVERWRITE: "dsdefault", VIEW: "dsdefault"}
	// DESCRIPTION: string;
	// DSCAID: number;
	// DSCAID_FULLNAME: string;
	// EXPIRES: string;
	// HEIGHT: number;
	// WIDTH: number;
	// MODIFIED: number;
	// REVISION: string;
	// S3ACL: string;
	// SIGNED_URL: string;
	// SIZE: number;
	// TN_URL: string;
	// URL: string;
	// UTC_VALID_FROM: number;
	// VERSION: string;
	// filehash: string;
	// tn_url_1000_0
}

export enum DocumentMetaRow {
	Imprint = "IMPRINT",
	PersonsInCharge = "PERSONS_IN_CHARGE",
	ApprovalSheet = "APPROVAL_SHEET",
	RecordOfRevisions = "RECORD_OF_REVISIONS",
	ListOfAffectedArticles = "LIST_OF_AFFECTED_ARTICLES",
}

export const DocumentMetaTitle: Record<DocumentMetaRow, string> = {
	[DocumentMetaRow.Imprint]: "Imprint",
	[DocumentMetaRow.PersonsInCharge]: "Persons in Charge",
	[DocumentMetaRow.ApprovalSheet]: "Approval Sheet",
	[DocumentMetaRow.RecordOfRevisions]: "Record of Revisions",
	[DocumentMetaRow.ListOfAffectedArticles]: "List of Affected Articles",
};

export interface ArticleTreeNode {
	/**
	 * @example dsaid:1234
	 * @example metaRow:IMPRINT
	 */
	key: string;
	parentKey: string;

	active?: boolean;
	expanded?: boolean;
	children?: Array<ArticleTreeNode>;

	articleData?: ArticleData;
	metaRow?: DocumentMetaRow;
}

export interface ArticleRead {
	dsaid: number;
	dscaid: number;
	read: boolean;
	readTime?: number;
	sync: boolean;
}

/**
 * regulations are loaded via lucee, using this interface
 */
export interface Regulation {
	dssrid?: number;
	dssid: number;
	/** @deprecated use dssid instead! */
	dsmid: number;
	dscatidRas: number;
	name: string;

	internal?: number;
	revision?: number;
	edition?: number;
	rc?: number;
	inApproval?: any;
	datePublished?: string;
}

export interface ArticleData {
	manager: RevisionDataManager;

	article: FpApi.ControlledDocument.Article;

	relations?: FpApi.Relation.Relation[];
	diff?: FpApi.ControlledDocument.RevisionDiffEntry;
	applicable?: FpApi.ControlledDocument.ArticleApplicable;
	evaluation?: FpApi.ControlledDocument.ArticleEvaluation;
	finding?: FpApi.Quality.Event.Event;
	tasks?: FpApi.Task.Task[];
	workflowState?: FpApi.ControlledDocument.ArticleWorkflowState;
	/** readState by `dscaid` */
	readState?: Map<number, ArticleRead>;
}

export interface RevisionDataSetData {
	articles?: FpApi.ControlledDocument.Article[]

	document?: FpApi.ControlledDocument.Document;
	revision?: FpApi.ControlledDocument.Revision;
	regulation?: Regulation;
	certificate?: SubCertificate;
	audit?: FpApi.Quality.Audit.Audit;
	history?: FpApi.ControlledDocument.Revision[];
	revisionRead?: FpApi.ControlledDocument.RevisionRead[];
	diff?: FpApi.ControlledDocument.RevisionDiffEntry[];
	relations?: FpApi.Relation.Relation[];
	applicable?: FpApi.ControlledDocument.ArticleApplicable[];
	evaluations?: FpApi.ControlledDocument.ArticleEvaluation[];
	findings?: FpApi.Quality.Event.Event[];
	readState?: ArticleRead[];
	workflowState?: FpApi.ControlledDocument.ArticleWorkflowState[];
	tasks?: FpApi.Task.Task[];
	mediaApi?: MediaApiFile[];
}

export interface RevisionDataManagerOptions {
	dssid?: number;
	dsmid?: number;
	dssrid?: number | "current" | "approval" | "edit"
}

export interface RevisionLoadOptions {
	/**
	 * Load data even if already present.
	 * Default is to only load data if it isn't loaded yet.
	 */
	reload?: boolean;

	/** load diff with previous revision of the document */
	diff?: boolean;
	/** load revision history */
	history?: boolean;
	/** load relation data */
	mediaApi?: boolean;
	relations?: boolean;
	applicable?: boolean;
	evaluations?: boolean;
	/** load findings */
	findings?: boolean;
	read?: boolean;
	readState?: boolean;
	workflowState?: boolean;
	tasks?: boolean;
}

export class RevisionDataManager {
	private app: FleetplanApp;
	private options: RevisionDataManagerOptions;
	private lock = new Lock();

	// raw data - revision data
	@observable public document: FpApi.ControlledDocument.Document;
	@observable public revision: FpApi.ControlledDocument.Revision;
	@observable public regulation: Regulation;
	@observable public certificate: SubCertificate;
	@observable public audit: FpApi.Quality.Audit.Audit;
	@observable public history: FpApi.ControlledDocument.Revision[];
	@observable public metaRows: DocumentMetaRow[] = [];
	@observable public articles: FpApi.ControlledDocument.Article[];
	@observable public revisionRead: FpApi.ControlledDocument.RevisionRead[];
	@observable public mediaApi: MediaApiFile[];
	// raw data - mapped to article
	@observable public relations: FpApi.Relation.Relation[];
	@observable public diff: FpApi.ControlledDocument.RevisionDiffEntry[];
	@observable public applicable: FpApi.ControlledDocument.ArticleApplicable[];
	// 2024-05-15 - [PR] Changed this with [ET] from @observable to @observable.ref
	@observable.ref public evaluations: FpApi.ControlledDocument.ArticleEvaluation[];
	@observable public findings: FpApi.Quality.Event.Event[];
	@observable public tasks: FpApi.Task.Task[];
	@observable public workflowState: FpApi.ControlledDocument.ArticleWorkflowState[];
	@observable.ref public readState: ArticleRead[];

	// mapped and managed data
	@observable.ref public articleData: ArticleData[];
	@observable.ref public treeUtil: TreeUtil<ArticleTreeNode, string>;
	@observable private byDsaid = new Map<number, ArticleData>();
	@observable private byDsncid = new Map<number, ArticleData>();
	@observable public byStatus = new Map<FpApi.ControlledDocument.ArticleStatus, ArticleData[]>();

	// tree

	/**
	 * State of the "treeToggleExpandedAll" function. Next time you call the function the opposite state will be applied to all nodes.
	 */
	@observable public treeToggleAllState = false;

	/** used to give new temporary article a unique id before saving them */
	private uniqID = -1;

	private constructor(app: FleetplanApp, options: RevisionDataManagerOptions) {
		this.app = app;

		this.options = {
			dssid: options.dssid,
			dsmid: options.dsmid,
			dssrid: options.dssrid === -1 ? "edit" : options.dssrid,
		};
	}

	public static empty(app: FleetplanApp): RevisionDataManager {
		return new RevisionDataManager(app, {});
	}

	public static forData(app: FleetplanApp, data: RevisionDataSetData): RevisionDataManager {
		const revisionData = new RevisionDataManager(app, {
			dssid: data.document?.dssid,
			dssrid: data.revision?.dssrid,
		});

		revisionData.set(data);

		return revisionData;
	}

	public static forOptions(app: FleetplanApp, options: RevisionDataManagerOptions): RevisionDataManager {
		return new RevisionDataManager(app, options);
	}

	public userFeatures(documentState: ControlledDocumentState) {
		const bits = {
			reader: documentState.isAllowed(this.document?.dssid, "reader"),
			author: documentState.isAllowed(this.document?.dssid, "author"),
			editor: documentState.isAllowed(this.document?.dssid, "editor"),
			readTracking: documentState.isAllowed(this.document?.dssid, "read_tracking"),
			manager: documentState.isAllowed(this.document?.dssid, "manager"),
		};

		const feature = {
			readTracking: bits.readTracking && this.document?.settings.readTracking.enabled,
			search: this.document?.settings.elasticSearchIndex,
			approval: bits.author || this.document?.$workflow?.$steps.some(step => this.app.ctx.hasRole(step.$roles.map(r => r.roleName))),
			pdfDownload: false,
			pdfReprint: false,
		};

		// `export.perId` controls PDF download
		switch (this.revision?.settings.export.perId) {
			case 26: feature.pdfDownload = bits.reader; break;
			case 28: feature.pdfDownload = bits.author; break;
		}
		// chapter print
		feature.pdfReprint = feature.pdfDownload && this.revision?.settings.export.allowPdfExport;

		return feature;
	}

	// #region setters

	@action.bound public set(data: RevisionDataSetData): void {
		if (data.document) this.document = data.document;
		if (data.revision) this.revision = data.revision;
		if (data.regulation) this.regulation = data.regulation;
		if (data.certificate) this.certificate = data.certificate;
		if (data.audit) this.audit = data.audit;
		if (data.history) this.history = data.history;
		if (data.revisionRead) this.revisionRead = data.revisionRead;
		if (data.mediaApi) this.mediaApi = data.mediaApi;

		if (data.articles) {
			// also update the articleData array and "byDscaid"
			this.byDsaid.clear();
			this.byDsncid.clear();
			this.byStatus.clear();

			for (const key in FpApi.ControlledDocument.ArticleStatus) {
				this.byStatus.set(FpApi.ControlledDocument.ArticleStatus[key], []);
			}

			const sortingMap = new Map<number, number>();
			this.articles = data.articles;
			this.articleData = this.articles.map(article => {
				const data = observable.object<ArticleData>({
					manager: this,
					article: article,

					relations: undefined,
					diff: undefined,
					applicable: undefined,
					evaluation: undefined,
					finding: undefined,
					tasks: undefined,
					workflowState: undefined,
					readState: new Map(),
				}, {
					article: observable,
					relations: observable,
					// diff: undefined,
					// applicable: undefined,
					// evaluation: undefined,
					// finding: undefined,
					tasks: observable,
					// workflowState: undefined,
					readState: observable.ref,
				}, { deep: false });

				this.byDsaid.set(article.dsaid, data);
				this.byDsncid.set(article.dsncid, data);

				// set the numbering
				const sorting = sortingMap.get(article.dspaid) ?? 0;
				sortingMap.set(article.dspaid, sorting + 1);

				if (article.dspaid) {
					const parent = this.byDsaid.get(article.dspaid).article.numbering;
					article.numbering = `${parent}.${sorting + 1}`;
				} else {
					const offset = this.revision.settings.article.offset;
					article.numbering = `${sorting + offset}`;
				}

				return data;
			});
		}

		// insert
		if (data.diff) {
			this.diff = null;
			this.insertDiff(data.diff);
		} else if (data.articles && this.diff) {
			const backup = this.diff;
			this.diff = null;
			this.insertDiff(backup);
		}
		if (data.relations) {
			this.relations = null;
			this.insertRelations(data.relations);
		} else if (data.articles && this.relations) {
			const backup = this.relations;
			this.relations = null;
			this.insertRelations(backup);
		}
		if (data.applicable) {
			this.applicable = null;
			this.insertApplicable(data.applicable);
		} else if (data.articles && this.applicable) {
			const backup = this.applicable;
			this.applicable = null;
			this.insertApplicable(backup);
		}
		if (data.evaluations) {
			this.evaluations = null;
			this.insertEvaluations(data.evaluations);
		} else if (data.articles && this.evaluations) {
			const backup = this.evaluations;
			this.evaluations = null;
			this.insertEvaluations(backup);
		}
		if (data.findings) {
			this.findings = null;
			this.insertFindings(data.findings);
		} else if (data.articles && this.findings) {
			const backup = this.findings;
			this.findings = null;
			this.insertFindings(backup);
		}
		if (data.readState) {
			this.readState = null;
			this.insertReadState(data.readState);
		} else if (data.articles && this.readState) {
			const backup = this.readState;
			this.readState = null;
			this.insertReadState(backup);
		}
		if (data.workflowState) {
			this.workflowState = null;
			this.insertWorkflowState(data.workflowState);
		} else if (data.articles && this.workflowState) {
			const backup = this.workflowState;
			this.workflowState = null;
			this.insertWorkflowState(backup);
		}
		if (data.tasks) {
			this.tasks = null;
			this.insertTasks(data.tasks);
		} else if (data.articles && this.tasks) {
			const backup = this.tasks;
			this.tasks = null;
			this.insertTasks(backup);
		}

		if (data.revision) {
			// meta rows are based on revision setting
			// update them if we have a revision
			this.setMetaRows();
		}

		if (data.articles) {
			// and finally rebuild the tree using the new "articleData" array
			this.buildDocumentTree();
		}
	}

	// #endregion setters

	@action.bound public async commitAll(): Promise<ArticleData> {
		const result = await apiManager
			.getService(FpApi.ControlledDocument.ArticleService)
			.putStatus(this.app.ctx, {
				dssid: this.document.dssid,
				dsaidList: this.byStatus.get(FpApi.ControlledDocument.ArticleStatus.Draft).map(a => a.article.dsaid),
			});

		// put in new version
		const dsaidList = result.map(r => r.article.dsaid);
		const articles = this.articles.filter(a => dsaidList.includes(a.dsaid) === false);
		result.forEach(r => articles.push(r.article));

		// rebuild articleData
		this.set({ articles: ControlledDocumentUtil.sortArticles(articles) });
		this.insertWorkflowState(result.map(r => r.workflowState));

		return;
	}

	/**
	 * Inserts a new article below the set `dspaid` (default root)
	 * Will also set the sorting correctly, will always be inserted as the last child.
	 * 
	 * @param article basic article data
	 * @returns 
	 */
	@action.bound public addArticle(articleParams: Partial<FpApi.ControlledDocument.Article> = {}): ArticleData {
		const dsaid = this.uniqID--;

		const article: FpApi.ControlledDocument.Article = {
			dsaid: dsaid,
			dsncid: dsaid,
			dspaid: 0,
			dateCreated: new Date().toISOString(),
			dateModified: new Date().toISOString(),
			dscaidModified: this.app.ctx.dscaid,
			dateCustom: new Date().toISOString(),

			numbering: "",
			versionNumber: -1,
			priorApproval: false,
			sorting: -1,

			title: "",
			shortBody: "",

			...articleParams,
		};

		if (article.dspaid === 0) {
			// -------------------------------------------------
			// add a a root item
			// -------------------------------------------------

			const sorting = this.treeUtil.tree
				.filter(a => a.key.startsWith("dsaid:"))
				.reduce((max, node) => Math.max(max, node.articleData.article.sorting), 0);
			article.sorting = sorting;
		} else {
			// -------------------------------------------------
			// add a a child
			// -------------------------------------------------

			const parent = this.treeUtil.findKey(`dsaid:${this.revision.dssrid}:${article.dspaid}`);
			if (parent.children == null) parent.children = [];
			const sorting = parent.children.reduce((max, node) => Math.max(max, node.articleData.article.sorting), 0);
			article.sorting = sorting;
		}

		// rebuild articleData
		const articles = [ ...this.articles, article ];
		this.set({ articles: ControlledDocumentUtil.sortArticles(articles) });
		this.insertWorkflowState([{
			status: FpApi.ControlledDocument.ArticleStatus.Draft,
			dsncid: dsaid,
		}]);

		return this.getDsaid(article.dsaid);
	}

	@action.bound public async saveArticle(params: FpApi.ControlledDocument.ArticleServicePutParams): Promise<ArticleData> {
		// clean up id, we don't post it
		const dsaid = params.data.dsaid;
		if (params.data.dsaid < 0) delete params.data.dsaid;

		const result = await apiManager
			.getService(FpApi.ControlledDocument.ArticleService)
			.put(this.app.ctx, params);

		const articles = [ ...this.articles ];

		// remove old version of the article
		const index = articles.findIndex(a => a.dsaid === dsaid);
		if (index !== -1) {
			articles.splice(index, 1);
		}
		// put in new version
		articles.push(result.article);

		// rebuild articleData
		this.set({ articles: ControlledDocumentUtil.sortArticles(articles) });
		this.insertWorkflowState([ result.workflowState ]);

		return this.byDsaid.get(result.article.dsaid);
	}

	@action.bound public removeArticle(dsaid: number): ArticleData {
		if (dsaid > 0) throw new Error("can only remove temporary article");

		const articleData = this.byDsaid.get(dsaid);

		if (articleData == null) {
			throw new Error("unknown dsaid");
		}

		// remove from revision data manager
		this.treeUtil.removeNodeByKey(`dsaid:${this.revision.dssrid}:${articleData.article.dsaid}`);
		this.byDsaid.delete(articleData.article.dsaid);
		this.byDsncid.delete(articleData.article.dsncid);

		const index = this.articleData.findIndex(a => a.article.dsaid === articleData.article.dsaid);
		this.articleData.splice(index, 1);
		this.articles.splice(index, 1);

		this.articleData = [ ...this.articleData ];
		this.articles = [ ...this.articles ];
		this.treeShallowClone();

		return articleData;
	}

	@action.bound public async deleteArticle(dsaid: number): Promise<ArticleData> {
		const articleData = this.byDsaid.get(dsaid);

		if (articleData == null) {
			throw new Error("unknown dsaid");
		}

		const deletedItems = await apiManager
			.getService(FpApi.ControlledDocument.ArticleService)
			.delete(this.app.ctx, {
				dssid: this.document.dssid,
				dsaid: dsaid,
			});

		for (const deletedItem of deletedItems) {
			// add to revision data manager
			this.treeUtil.removeNodeByKey(`dsaid:${this.revision.dssrid}:${deletedItem.dsaid}`);
			this.byDsaid.delete(deletedItem.dsaid);
			this.byDsncid.delete(deletedItem.dsncid);

			const index = this.articleData.findIndex(a => a.article.dsaid === deletedItem.dsaid);
			this.articleData.splice(index, 1);
			this.articles.splice(index, 1);
		}

		this.articleData = [ ...this.articleData ];
		this.articles = [ ...this.articles ];
		this.treeShallowClone();

		return articleData;
	}

	@action.bound public async sortArticles(items: FpApi.ControlledDocument.ArticleServiceSortParams["data"]): Promise<void> {
		const result = await apiManager
			.getService(FpApi.ControlledDocument.ArticleService)
			.sort(this.app.ctx, {
				dssid: this.document.dssid,
				data: items,
			});

		result.forEach(item => {
			const articleData = this.byDsaid.get(item.dsaid);
			articleData.article.dspaid = item.dspaid;
			articleData.article.sorting = item.sorting;
		});

		// rebuild articleData
		this.set({
			articles: ControlledDocumentUtil.sortArticles(this.articles),
		});
	}

	// #region insert

	@action.bound public insertRelations(relations: FpApi.Relation.Relation[]): void {
		relations.forEach(relation => {
			if (relation.objectType === "dsaid") {
				const articleData = this.byDsaid.get(Number.parseInt(relation.objectId));
				if (articleData != null) {
					if (!articleData.relations) {
						articleData.relations = [ relation ];
					} else {
						remove(articleData.relations, r => r.id == relation.id);
						articleData.relations.push(relation);
					}
				}
			}
			if (relation.linkType === "dsaid") {
				const articleData = this.byDsaid.get(Number.parseInt(relation.linkId));
				if (articleData != null) {
					if (!articleData.relations) {
						articleData.relations = [ relation ];
					} else {
						remove(articleData.relations, r => r.id == relation.id);
						articleData.relations.push(relation);
					}
				}
			}
		});

		if (this.relations == null) {
			this.relations = relations;
		} else {
			remove(this.relations, old => relations.find(n => n.id === old.id) != null);
			this.relations = this.relations.concat(relations);
		}
	}

	@action.bound public insertDiff(diff: FpApi.ControlledDocument.RevisionDiffEntry[]): void {
		diff.forEach(diff => {
			const articleData = this.byDsaid.get(diff.dsaid);
			if (articleData == null) return null;

			articleData.diff = diff;
		});

		if (this.diff == null) {
			this.diff = diff;
		} else {
			remove(this.diff, old => diff.find(n => n.dsaid === old.dsaid) != null);
			this.diff = this.diff.concat(diff);
		}
	}

	@action.bound public insertApplicable(applicable: FpApi.ControlledDocument.ArticleApplicable[]): void {
		applicable.forEach(applicable => {
			const articleData = this.byDsncid.get(applicable.dsncid);
			if (articleData == null) return null;

			articleData.applicable = applicable;
		});

		if (this.applicable == null) {
			this.applicable = applicable;
		} else {
			remove(this.applicable, old => applicable.find(n => n.dsncid === old.dsncid) != null);
			this.applicable = this.applicable.concat(applicable);
		}
	}

	@action.bound public insertEvaluations(evaluations: FpApi.ControlledDocument.ArticleEvaluation[]): void {
		evaluations.forEach(evaluation => {
			const articleData = this.byDsncid.get(evaluation.sourceDsncid);
			if (articleData == null) return null;

			articleData.evaluation = evaluation;
		});

		if (this.evaluations == null) {
			this.evaluations = evaluations;
		} else {
			remove(this.evaluations, old => evaluations.find(n => n.sourceDsaid === old.sourceDsaid) != null);
			this.evaluations = this.evaluations.concat(evaluations);
		}
	}

	@action.bound public insertFindings(findings: FpApi.Quality.Event.Event[]): void {
		findings.forEach(finding => {
			const articleData = this.byDsaid.get(finding.relations.find(r => r.type === "dsaid" && r.category === "LINK")?.dsaid);
			if (articleData == null) return null;

			articleData.finding = finding;
		});

		if (this.findings == null) {
			this.findings = findings;
		} else {
			// remove(this.findings, old => findings.find(n => n.dsncid === old.dsncid) != null);
			this.findings = this.findings.concat(findings);
		}
	}

	@action.bound public insertReadState(readState: ArticleRead[]): void {
		const emptyMap = new Map();

		readState.forEach(readState => {
			const articleData = this.byDsaid.get(readState.dsaid);
			if (articleData == null) return null;

			if (articleData.readState == null) {
				articleData.readState = new Map([[ readState.dscaid, readState ]]);
			} else {
				articleData.readState.set(readState.dscaid, readState);

				const tmp = articleData.readState;
				// setting it to a different maps tricks the "observable.ref" into thinking the ref changed
				// means to it doesn't need to deep observable + we don't need to copy the entire map every time to trigger a change
				articleData.readState = emptyMap;
				articleData.readState = tmp;
			}
		});

		if (this.readState == null) {
			this.readState = readState;
		} else {
			remove(this.readState, old => readState.find(n => n.dsaid === old.dsaid && n.dscaid === old.dscaid) != null);
			this.readState = this.readState.concat(readState);
		}
	}

	@action.bound public insertWorkflowState(workflowState: FpApi.ControlledDocument.ArticleWorkflowState[]): void {
		workflowState.forEach(workflowState => {
			const articleData = this.byDsncid.get(workflowState.dsncid);
			if (articleData == null) return null;

			if (articleData.workflowState) {
				const idx = this.byStatus.get(workflowState.status).indexOf(articleData);
				if (idx) this.byStatus.get(workflowState.status).splice(idx, 1);
			}

			articleData.workflowState = workflowState;

			this.byStatus.get(workflowState.status).push(articleData);
		});

		if (this.workflowState == null) {
			this.workflowState = workflowState;
		} else {
			remove(this.workflowState, old => workflowState.find(n => n.dsncid === old.dsncid) != null);
			this.workflowState = this.workflowState.concat(workflowState);
		}
	}

	@action.bound public insertTasks(tasks: FpApi.Task.Task[]): void {
		tasks.forEach(task => {
			task.relations.forEach(relation => {
				if (relation.dsaid == null) return;

				const articleData = this.byDsaid.get(relation.dsaid);
				if (articleData == null) return null;

				if (!articleData.tasks) articleData.tasks = [ task ];
				else articleData.tasks.push(task);
			});
		});

		if (this.tasks == null) {
			this.tasks = tasks;
		} else {
			remove(this.tasks, old => tasks.find(n => n.id === old.id) != null);
			this.tasks = this.tasks.concat(tasks);
		}
	}

	// #endregion insert

	// #region remove

	@action.bound public removeRelations(ids: string[]): void {
		remove(this.relations, relation => {
			if (ids.includes(relation.id) === false) return false;

			if (relation.objectType === "dsaid") {
				const articleData = this.byDsaid.get(parseInt(relation.objectId));
				if (articleData != null) {
					remove(articleData.relations, relation);
				}
			}

			if (relation.linkType === "dsaid") {
				const articleData = this.byDsaid.get(parseInt(relation.linkId));
				if (articleData != null) {
					remove(articleData.relations, relation);
				}
			}
		});
	}

	// #endregion remove

	// #region tree utils

	/**
	 * Trigger a "change on the tree" by shallowly cloning the util. You will have to manually call if you change something in the tree data like the "expanded"
	 * state of a node.
	 */
	@action.bound public treeShallowClone(): void {
		this.treeUtil = this.treeUtil.cloneShallow();
	}

	@action.bound public treeCollapseAll(): void {
		this.treeUtil.walk(info => { info.node.expanded = true; });
		this.treeShallowClone();
	}

	@action.bound public treeExpandAll(): void {
		this.treeUtil.walk(info => { info.node.expanded = true; });
		this.treeShallowClone();
	}

	@action.bound public treeToggleExpandedAll(): void {
		this.treeToggleAllState = !this.treeToggleAllState;
		this.treeUtil.walk(info => { info.node.expanded = this.treeToggleAllState; });
		this.treeShallowClone();
	}

	// #endregion tree utils

	// #region load data

	@action.bound public async load(options: RevisionLoadOptions = {}): Promise<void> {
		await this.lock.acquire("load", async () => {
			const { ctx, store } = this.app;
			options = { reload: false, ...options };

			// revision & document
			if (this.revision == null || this.document == null || options.reload) {
				if (typeof this.options.dssrid === "number") {
					// we were given a `dssrid`
					const revision = await this.app.store.controlledDocument.revision.getId(this.options.dssrid);
					this.set({
						document: revision.$dssid,
						revision: revision,
					});
				} else {
					// we were given a `dssid` or `dsmid`
					let document: FpApi.ControlledDocument.Document;
					if (this.options.dssid) {
						document = await this.app.store.controlledDocument.document.getId(this.options.dssid);
					} else {
						document = await this.app.store.controlledDocument.documentDsmid.getId(this.options.dsmid);
					}

					if (document == null) {
						// the cache currently only has documents from the current
						// it's missing regulations etc.
						document = await ServiceDataProcessor
							.getService(this.app.ctx, FpApi.ControlledDocument.DocumentService)
							.getId({
								params: {
									dssid: this.options.dssid,
									dsmid: this.options.dsmid,
								},
								cache: {
									maxAge: options.reload ? undefined : 60 * 60 * 1000,
								},
							});
					}

					if (this.options.dssrid) {
						this.set({
							document: document,
							revision: document?.[`$${this.options.dssrid ?? "current"}Revision`],
						});
					} else {
						this.set({
							document: document,
							revision: document?.$currentRevision || document?.$approvalRevision || document?.$editRevision,
						});
					}
				}
			}

			if (this.revision == null) return;

			this.options.dsmid = this.document.dsmid;
			this.options.dssid = this.document.dssid;
			this.options.dssrid = this.revision.dssrid === -1 ? "edit" : this.revision.dssrid;

			// articles
			if (this.articles == null || options.reload) {
				if (this.options.dssrid === "edit") {
					const articles = await apiManager
						.getService(FpApi.ControlledDocument.RevisionService)
						.getEditModeArticles(ctx, { dssid: this.document.dssid });
					this.set({ articles });
				} else {
					// we were given a `dssrid`
					const articles = await store.controlledDocument.article.getArticles(this.revision.dssrid);
					this.set({ articles });
				}
			}

			const tasks: AsyncAutoTasks<Dictionary<any>, Error> = {};

			if (options.diff) tasks.diff = async () => this.loadDiff(options);
			if (options.history) tasks.history = async () => this.loadHistory(options);
			if (options.mediaApi) tasks.mediaApi = async () => this.loadMediaApi(options);
			if (options.relations) tasks.relations = async () => this.loadRelations(options);
			if (options.applicable) tasks.applicable = async () => this.loadApplicable(options);
			if (options.evaluations) tasks.evaluations = async () => this.loadEvaluation(options);
			if (options.findings) tasks.findings = async () => this.loadFindings(options);
			if (options.workflowState) tasks.workflowState = async () => this.loadWorkflowState(options);
			if (options.tasks) tasks.tasks = async () => this.loadTasks(options);
			if (options.read) tasks.read = async () => this.loadRead(options);
			// `loadReadState` need to be after `loadRead`
			if (options.readState) {
				if (tasks.read == null) tasks.read = async () => Promise.resolve();
				tasks.readState = [ "read", async () => this.loadReadState(options) ];
			}

			await auto(tasks, 3);
		});
	}

	@action.bound private async loadMediaApi(options: RevisionLoadOptions): Promise<void> {
		if (this.mediaApi != null && options.reload === false) return;

		if (window.Restrictions_Json.length > 0) {
			// user is a "restricted user"
			// this is mainly `support.fleetplan.net`

			const allowed = window.Restrictions_Json.some(rule => {
				return rule.url.dscmd === "mediaapi_mediaapi_mediaapi_view";
			});

			if (allowed === false) return;
		}

		const mediaApi = await FP_QUERY.query({
			key: "RevisionDataManager.loadMediaApi",
			maxAge: options.reload ? undefined : 60 * 60 * 1000,

			params: { dssrid: this.revision.dssrid },
			work: async (params) => {
				const res = await fetch("/dynasite.cfm?dscmd=mediaapi_mediaapi_mediaapi_view&dsxhr=1", {
					method: "POST",
					body: new URLSearchParams({
						linkIdType: "dssrid",
						linkId: params.dssrid.toString(),
						sort: "false"
					}),
				});

				return await res.json();
			},
		});

		this.set({ mediaApi });
	}

	@action.bound private async loadHistory(options: RevisionLoadOptions): Promise<void> {
		const { ctx } = this.app;

		if (this.history != null && options.reload === false) return;

		const history = await ServiceDataProcessor
			.getService(ctx, FpApi.ControlledDocument.RevisionService)
			.getHistory({
				params: {
					dssid: this.document.dssid,
					forDssrid: this.revision.dssrid,
				},
				cache: {
					maxAge: options.reload ? undefined : 60 * 60 * 1000,
				},
			});

		this.set({ history });
	}

	@action.bound private async loadRelations(options: RevisionLoadOptions): Promise<void> {
		const { ctx } = this.app;

		if (this.relations != null && options.reload === false) return;

		const relations = await ServiceDataProcessor
			.getService(ctx, FpApi.Relation.RelationService)
			.get({
				params: {
					for: this.revision.kind === FpApi.ControlledDocument.RevisionKind.Edit ? {
						// edit mode
						type: "dssid",
						id: [ this.document.dssid.toString() ],
					} : {
						// revision
						type: "dssrid",
						id: [ this.revision.dssrid.toString() ],
					},
				},
				cache: {
					maxAge: options.reload ? undefined : 60 * 60 * 1000,
				},
			});

		this.set({ relations });
	}

	@action.bound private async loadDiff(options: RevisionLoadOptions): Promise<void> {
		const { ctx } = this.app;

		if (this.diff != null && options.reload === false) return;

		if (this.revision.kind === FpApi.ControlledDocument.RevisionKind.Edit) {
			const diff = await ServiceDataProcessor
				.getService(ctx, FpApi.ControlledDocument.RevisionService)
				.getEditModeDiff({
					params: {
						dssid: this.document.dssid,
					},
					cache: {
						maxAge: options.reload ? undefined : 60 * 60 * 1000,
					},
				});

			this.set({ diff });
		} else {
			const diff = await ServiceDataProcessor
				.getService(ctx, FpApi.ControlledDocument.RevisionService)
				.getDiff({
					params: {
						current: this.revision.dssrid,
					},
					cache: {
						maxAge: options.reload ? undefined : 60 * 60 * 1000,
					},
				});

			this.set({ diff });
		}
	}

	@action.bound private async loadApplicable(options: RevisionLoadOptions): Promise<void> {
		const { ctx } = this.app;

		if (this.certificate == null) return;
		if (this.applicable != null && options.reload === false) return;

		const applicable = await apiManager
			.getService(FpApi.ControlledDocument.ApplicableService)
			.forRevision(ctx, {
				dssrid: this.revision.dssrid,
				dscdid: this.certificate.id,
			});

		this.set({ applicable });
	}

	@action.bound private async loadEvaluation(options: RevisionLoadOptions): Promise<void> {
		const { ctx } = this.app;

		if (this.evaluations != null && options.reload === false) return;

		const evaluations = await apiManager
			.getService(FpApi.ControlledDocument.EvaluationService)
			.forRevision(ctx, {
				dsqmauid: this.audit.id,
				dssrid: this.revision.dssrid,
				// dscdid: this.certificate?.id,
			});

		this.set({ evaluations });
	}

	@action.bound private async loadFindings(options: RevisionLoadOptions): Promise<void> {
		const { ctx } = this.app;

		if (this.findings != null && options.reload === false) return;

		const relation: FpApi.InlineRelation = {
			category: "FINDING",
			type: "dsncxqaid",
			dsqmauid: this.audit.id,
			dssrid: this.revision.dssrid,
		};

		const findings = await apiManager
			.getService(FpApi.Quality.Event.EventService)
			.get(ctx, { relations: [ relation ] });

		this.set({ findings });
	}

	@action.bound private async loadRead(options: RevisionLoadOptions): Promise<void> {
		if (this.revisionRead != null && options.reload === false) return;

		// check id read tracking is enabled
		if (
			this.revision.kind !== FpApi.ControlledDocument.RevisionKind.Published
			|| this.document.settings.readTracking.enabled === false
		) {
			this.set({ revisionRead: [] });
		}

		const revisionRead = await ServiceDataProcessor
			.getService(this.app.ctx, FpApi.ControlledDocument.ReadService)
			.get({
				params: {
					dssrid: this.revision.dssrid,
				},
				cache: {
					maxAge: options.reload ? undefined : 60 * 60 * 1000,
				},
			});
		this.set({ revisionRead });
	}

	@action.bound private async loadReadState(options: RevisionLoadOptions): Promise<void> {
		if (this.readState != null && options.reload === false) return;

		// check id read tracking is enabled
		if (
			this.revision.kind !== FpApi.ControlledDocument.RevisionKind.Published
			|| this.document.settings.readTracking.enabled === false
			|| this.revisionRead.length === 0
		) {
			this.set({ readState: [] });
			return;
		}

		const response = await new FpReadsClientDataProcessor(this.app, { use: "RevisionDataManager" }).getReads({
			params: {
				users: this.revisionRead.map(r => r.dscaid),
				documents: [{
					id: this.document.dssid,
					_r: this.revision.dssrid,
				}],
				includeRead: true,
				includeUnread: true,
			},
			cache: {
				maxAge: options.reload ? undefined : 60 * 60 * 1000,
			},
		});

		const readItems: ArticleRead[] = [];

		response.reads.forEach(user => {
			user.documents.forEach(document => {
				document.articles.forEach(article => {
					readItems.push({
						dscaid: user.uid,
						dsaid: article.id,
						sync: true,
						read: article.t != null,
						readTime: article.t ? article.t * 1000 : undefined,
					});
				});
			});
		});

		this.set({ readState: readItems });
	}

	@action.bound private async loadWorkflowState(options: RevisionLoadOptions): Promise<void> {
		if (this.workflowState != null && options.reload === false) return;

		if (this.revision.kind === FpApi.ControlledDocument.RevisionKind.Edit) {
			const workflowState = await apiManager
				.getService(FpApi.ControlledDocument.WorkflowService)
				.getEditModeArticleState(this.app.ctx, {
					dssidList: [ this.revision.dssid ],
				});

			this.set({ workflowState: workflowState[this.revision.dssid] ?? [] });
		} else if (this.revision.kind === FpApi.ControlledDocument.RevisionKind.Approval) {
			const workflowState = await apiManager
				.getService(FpApi.ControlledDocument.WorkflowService)
				.getArticleState(this.app.ctx, {
					dssridList: [ this.revision.dssrid ],
				});

			this.set({ workflowState: workflowState[this.revision.dssrid] ?? [] });
		}
	}

	@action.bound private async loadTasks(options: RevisionLoadOptions): Promise<void> {
		if (this.tasks != null && options.reload === false) return;

		const tasks = await ServiceDataProcessor
			.getService(this.app.ctx, FpApi.Task.TaskService)
			.get({
				params: {
					filter: [{
						field: "allRelations",
						exps: [
							{ dssid: this.document.dssid },
						]
					}],
				},
				cache: {
					maxAge: options.reload ? undefined : 60 * 60 * 1000,
				},
			});

		this.set({ tasks: tasks.items });
	}

	// #endregion load data

	public getDsaid(dsaid: number): ArticleData {
		return this.byDsaid.get(dsaid);
	}

	/**
	 * Sets "this.metaRows" based on "this.revision.settings".
	 */
	@action.bound private setMetaRows(): void {
		this.metaRows = [
			DocumentMetaRow.Imprint,
			DocumentMetaRow.PersonsInCharge,
		];

		if (this.revision.settings.approvalSheet.enabled) this.metaRows.push(DocumentMetaRow.ApprovalSheet);
		if (this.revision.settings.pages.ror) this.metaRows.push(DocumentMetaRow.RecordOfRevisions);
		if (this.revision.settings.pdf.loea.diff) this.metaRows.push(DocumentMetaRow.ListOfAffectedArticles);

		// update the meta rows in the tree
		if (this.treeUtil != null && this.revision != null) {
			for (const name in DocumentMetaRow) {
				// remove old meta rows
				this.treeUtil.removeNodeByKey(`metaRow:${this.revision.dssrid}:${DocumentMetaRow[name] as string}`);
			}

			// and in with the new rows
			this.treeUtil.tree.unshift(
				...this.metaRows.map<ArticleTreeNode>(row => {
					return {
						key: `metaRow:${this.revision.dssrid}:${row}`,
						parentKey: "dsaid:0",
						metaRow: row,
						active: false,
						expanded: false,
						articleData: {
							article: null,
							manager: this,
						},
					};
				}),
			);

			// or the UI / toc tree might not update
			this.treeShallowClone();
		}
	}

	@action.bound private buildDocumentTree(): void {
		if (this.articleData == null) return;
		if (this.revision == null) return;

		// collect current expanded state
		const map = new Map<string, boolean>();
		if (this.treeUtil) {
			this.treeUtil.walk(info => { map.set(info.node.key, info.node.expanded); });
		}

		const nodes = [
			...this.metaRows.map<ArticleTreeNode>(row => {
				return {
					key: `metaRow:${this.revision.dssrid}:${row}`,
					parentKey: `dsaid:${this.revision.dssrid}:0`,
					metaRow: row,
					active: false,
					expanded: false,
					articleData: {
						article: null,
						manager: this,
					},
				};
			}),
			...this.articleData.map<ArticleTreeNode>(data => {
				const key = `dsaid:${this.revision.dssrid}:${data.article.dsaid}`;
				return {
					key: key,
					parentKey: `dsaid:${this.revision.dssrid}:${data.article.dspaid}`,
					active: false,
					expanded: map.get(key) ?? this.treeToggleAllState,
					articleData: data,
				};
			}),
		];

		this.treeUtil = TreeUtil.fromArray(nodes, {
			getKey: n => n.key,
			getParentKey: n => n.parentKey,
			getChildren: n => n.children,
			setChildren: (n, children) => n.children = children,
			root: `dsaid:${this.revision.dssrid}:0`,
		});
	}
}
