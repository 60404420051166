import { DirectoryMember, DirectoryMemberData, FpApi, FpDirMember, LocationSetupStatus, LocationSetupUtilCore, LocationValidationSetupParams, LocationValidationSetupResult } from "@tcs-rliess/fp-core";
import { chain, every, flatten, isMatch, isNil, omitBy, pickBy, toNumber, uniq } from "lodash-es";
import { DateTime } from "luxon";

import { FleetplanApp } from "../../FleetplanApp";

const KEY = Symbol("hiddenAccessor");


export class LocationSetupUtil extends LocationSetupUtilCore {
	// 2024-07-30 - [PR] for now we ignore the set of the shifts (applying standbys will ignore the set of the shift (target))
	// needs to be checked later!
	public static partialEqualPosition(self: FpApi.Resource.Duty.Position, other: FpApi.Resource.Duty.Position) {
		// is using same set, so its ok
		if(self.set && other.set && self.set === other.set) {
			return true;
		}
		// partial compare targets of self and other
		if(self.target && other.target) {
			for(const target of self.target) {
				if(other.target.find(t => isMatch(pickBy(t, Boolean), pickBy(target, Boolean)))) {
					return true;
				}
			}
		}
		return false;
	}
	public isPrepared = false;
	constructor(private app: FleetplanApp) {
		super();
	}
	// private cache: Map<string, Map<string, SetupStatus >> = new Map();

	public async prepareUtil(): Promise<void> {
		await this.app.store.certificateV3Store.ensureSets();
		this.isPrepared = true;
	}

	protected syncGetLinkType<T = unknown>(linkId: string, linkIdType: string): T {
		switch(linkIdType) {
			case "fpvid":
				return this.app.store.resource.aircraft.getId(+linkId) as any;
		}
	}

	public getAssignmentsFromPosition(position: FpApi.Resource.Duty.Position): { fpdirgrp?: number, fpdirpos?: number }[] {
		const assignments: ReturnType<typeof this.getAssignmentsFromPosition> = [];
		if(position.target) {
			position.target.forEach(t => {
				if(t.fpdirgrp || t.fpdirpos) {
					assignments.push(t);
				}
			});
		}
		if(position.set) {
			const set = this.app.store.certificateV3Store.setsObj[position.set];
			if(set) {
				set.relations.filter(rel => rel.type === "fpdirlink" && rel.category === "ASSIGNEDTO")
					.forEach(rel => {
						assignments.push({
							fpdirgrp: rel.fpdirgrp,
							fpdirpos: rel.fpdirpos,
						});
					});
			}
		}
		return assignments;
	}

	/**
	 * To eject contacts when certificates did expire, set keepBySetInvalid to false
	 * its true per default
	 * */
	public getValidMemberForPosition(dscaid: number, position: FpApi.Resource.Duty.Position, keepBySetInvalid = true) {
		if(!this.isPrepared) throw new Error("SetupUtil not prepared");
		const membersForContact = this.app.store.fpDir.directory.getMembersByResource("dscaid", dscaid);
		let response: DirectoryMember;
		if(position.set) {
			const set = this.app.store.certificateV3Store.setsObj[position.set];
			if(set) {
				const validity = this.app.store.certificateV3Store.getSetValidityForLink(set, "dscaid", dscaid.toString());
				const applicableRelations = set.relations.filter(rel => rel.category === "ASSIGNEDTO" && (rel.fpdirgrp || rel.fpdirpos));
				const filters = applicableRelations.map(rel => {
					return omitBy({
						fpdirgrp: rel.fpdirgrp,
						fpdirpos: rel.fpdirpos,
					}, e => !e);
				});
				const applicableMember = membersForContact.find(member => {
					return filters.find(filter => {
						return isMatch(member, filter);
					});
				});
				if(validity.missing.length) response = applicableMember;
				else if (validity.expired.length) {
					if(keepBySetInvalid) response = applicableMember;
					else response = null;
				}
			}
		}
		/** when its now validated to true, we can asume this contact is fine */
		/** if not, we still need to checks for positions */
		const memberFilter = new Map(membersForContact.map(member => [ member, (omitBy({
			fpdirgrp: member.grp,
			fpdirpos: member.pos,
		}, e => !e)) ]));
		if(response || !position.target) return response;
		for(const member of membersForContact) {
			for(const target of position.target) {
				if(isMatch(memberFilter.get(member), target)) {
					return member;
				}
			}
		}
	}

	/**
	 * To eject contacts when certificates did expire, set keepBySetInvalid to false
	 * its true per default
	 * */
	public getValidMembersForPosition(dscaid: number, position: FpApi.Resource.Duty.Position, keepBySetInvalid = true) {
		if(!this.isPrepared) throw new Error("SetupUtil not prepared");
		const membersForContact = this.app.store.fpDir.directory.getMembersByResource("dscaid", dscaid);
		let response: DirectoryMember[];
		if(position.set) {
			const set = this.app.store.certificateV3Store.setsObj[position.set];
			if(set) {
				const validity = this.app.store.certificateV3Store.getSetValidityForLink(set, "dscaid", dscaid.toString());
				const applicableRelations = set.relations.filter(rel => rel.category === "ASSIGNEDTO" && (rel.fpdirgrp || rel.fpdirpos));
				const filters = applicableRelations.map(rel => {
					return omitBy({
						fpdirgrp: rel.fpdirgrp,
						fpdirpos: rel.fpdirpos,
					}, e => !e);
				});
				const applicableMembers = membersForContact.filter(member => {
					return filters.find(filter => {
						return isMatch(member, filter);
					});
				});
				if(validity.missing.length) response = applicableMembers;
				else if (validity.expired.length) {
					if(keepBySetInvalid) response = applicableMembers;
					else response = null;
				}
			}
		}
		/** when its now validated to true, we can asume this contact is fine */
		/** if not, we still need to checks for positions */
		const memberFilter = new Map(membersForContact.map(member => [ member, (omitBy({
			fpdirgrp: member.grp,
			fpdirpos: member.pos,
		}, e => !e)) ]));
		if(!position.target) return response;
		for(const member of membersForContact) {
			for(const target of position.target) {
				if(isMatch(memberFilter.get(member), target)) {
					if(!response) response = [];
					response.push(member);
				}
			}
		}
		return response;
	}


	public getApplicableMembersForPosition({ position, date, filter, providedMembers, skipValidation, shift }: {position: FpApi.Resource.Duty.Position, date: DateTime, filter?: { loc?: number }, providedMembers?: DirectoryMember[], skipValidation?: boolean, shift?: string }) {
		if(!this.isPrepared) throw new Error("SetupUtil not prepared");
		const applicableMembers = new Map<string, DirectoryMember>();
		const members = providedMembers ?? this.app.store.fpDir.directory.getMembers();
		const validateSet = (setId: number, member: FpDirMember<DirectoryMemberData>) => {
			const set = this.app.store.certificateV3Store.setsObj[setId];
			if(set) {
				const validity = this.app.store.certificateV3Store.getSetValidityForLink(set, "dscaid", member.linkid);
				if(!skipValidation && (validity.ok.length + validity.infinite.length + validity.expires.length + validity.grace.length) >= validity.required.length) {
					return true;
				}
				if(skipValidation && validity.missing.length === 0) {
					return true;
				}
			}
			return false;
		};
		for(const member of members) {
			// if this member is not a dscaid (contact), continue
			if(member.linktype !== "dscaid") continue;
			// if this member is not applicable for our filter, continue
			if(filter && !Object.keys(filter).every(key => {
				return member[key] === filter[key];
			})) {
				continue;
			}
			if(shift) {
				const shiftSettings = position.shiftSettings?.[shift];
				if(shiftSettings && shiftSettings.set) {
					if(shiftSettings.setBehavior === "replace" || shiftSettings.setBehavior == null) {
						if(validateSet(shiftSettings.set, member)) {
							applicableMembers.set(member.linkid, member);
						}
					} else if (shiftSettings.setBehavior === "add") {
						if([ shiftSettings.set, position.set ].filter(Boolean).every(set => validateSet(set, member))) {
							applicableMembers.set(member.linkid, member);
						}
					}
				}
			} else if (position.set) {
				if(validateSet(position.set, member)) {
					applicableMembers.set(member.linkid, member);
				}
			}
			if(position.target) {
				position.target.forEach(t => {
					if(isMatch({
						grp: member.grp,
						pos: member.pos,
					}, omitBy({
						grp: t.fpdirgrp,
						pos: t.fpdirpos,
					}, isNil))) {
						applicableMembers.set(member.linkid, member);
					}
				});
			}
		}
		return applicableMembers;
	}

	private validateSet(setId: number, member: FpDirMember<DirectoryMemberData>, skipValidation: boolean) {
		const set = this.app.store.certificateV3Store.setsObj[setId];
		if(set) {
			const validity = this.app.store.certificateV3Store.getSetValidityForLink(set, "dscaid", member.linkid);
			if(!skipValidation && (validity.ok.length + validity.infinite.length + validity.expires.length + validity.grace.length) >= validity.required.length) {
				return true;
			}
			if(skipValidation && validity.missing.length === 0) {
				return true;
			}
		}
		return false;
	}

	public getValidShiftsForPosition({ position, date, linkId, skipValidation } : {position: FpApi.Resource.Duty.Position, date?: DateTime, linkId: string, skipValidation?: boolean}) {
		// get all shifts first
		const shifts = new Set([ ...position.shifts ] ?? []);
		const members = this.app.store.fpDir.directory.getMembersByResource("dscaid", linkId);
		const shiftSettings = position.shiftSettings;
		const validShifts = new Set<string>();
		function acceptShift(shift: string) {
			validShifts.add(shift);
			shifts.delete(shift);
		}
		for(const member of members) {
			// check if position has target, if it has a target, match it with the member. If target does not match -> continue
			// -> when the contact does not even match the target -> it cannot be valid for any shift
			//TODO
			// 2024-07-29 - [PR] Call with RW and DL, For now we will validate against OR.
			if(position.validationBehavior === "AND") {
				if(position.target?.length) {
					const target = position.target.find(t => {
						return isMatch({
							fpdirgrp: member.grp,
							fpdirpos: member.pos,
						}, t);
					});
					if(!target) continue;
				}
				for(const shift of shifts) {
					if(shiftSettings == null || !shiftSettings[shift]) {
						acceptShift(shift);
						continue;
					} // no settings, so we can use it. There is no limit
					const shiftSetting = shiftSettings[shift];
					if(!shiftSetting.set) {
						// check if there is any other set, which is valid for this member
						if(position.set) {
							if(this.validateSet(position.set, member, skipValidation)) // -> this would mean the whole position is not valid for the contact. 
								acceptShift(shift);
							continue;
						} else {
							// in case there is no set at all, we're
							acceptShift(shift);
							continue;
						}
					} // no set, so we can use it. There is no limit
					const setBehavior = shiftSetting.setBehavior ?? "replace";
					if(setBehavior === "replace" || setBehavior == null) {
						// we only need to check against the shift set
						if(this.validateSet(shiftSetting.set, member, skipValidation)) {
							acceptShift(shift);
						}
					} else if (setBehavior === "add") {
						// we need to match against the position set and the shift set. If there is no position set, we skip it
						if([ shiftSetting.set, position.set ].filter(Boolean).every(set => this.validateSet(set, member, skipValidation))) {
							acceptShift(shift);
						}
					}
					// no need to loop further if we already accepted all shifts
					if(shifts.size === 0) break;
				}
			} else {
				if(position.target?.length) {
					const target = position.target.find(t => {
						return isMatch({
							fpdirgrp: member.grp,
							fpdirpos: member.pos,
						}, t);
					});
					if(target) {
						// accept all shifts
						shifts.forEach(acceptShift);
						break;
					}
				} else {
					for(const shift of shifts) {
						if(shiftSettings == null || !shiftSettings[shift]) {
							acceptShift(shift);
							continue;
						} // no settings, so we can use it. There is no limit
						const shiftSetting = shiftSettings[shift];
						if(!shiftSetting.set) {
							// check if there is any other set, which is valid for this member
							if(position.set) {
								if(this.validateSet(position.set, member, skipValidation)) // -> this would mean the whole position is not valid for the contact. 
									acceptShift(shift);
								continue;
							} else {
								// in case there is no set at all, we're
								acceptShift(shift);
								continue;
							}
						} // no set, so we can use it. There is no limit
						const setBehavior = shiftSetting.setBehavior ?? "replace";
						if(setBehavior === "replace" || setBehavior == null) {
							// we only need to check against the shift set
							if(this.validateSet(shiftSetting.set, member, skipValidation)) {
								acceptShift(shift);
							}
						} else if (setBehavior === "add") {
							// we need to match against the position set and the shift set. If there is no position set, we skip it
							if([ shiftSetting.set, position.set ].filter(Boolean).every(set => this.validateSet(set, member, skipValidation))) {
								acceptShift(shift);
							}
						}
						// no need to loop further if we already accepted all shifts
						if(shifts.size === 0) break;
					}
				}
			}
		}
		return Array.from(validShifts);
	}

	public getAllApplicableMembersForPosition({ position, date, filter, providedMembers, skipValidation, shift }: {position: FpApi.Resource.Duty.Position, date: DateTime, filter?: { loc?: number }, providedMembers?: DirectoryMember[], skipValidation?: boolean, shift?: string }) {
		if(!this.isPrepared) throw new Error("SetupUtil not prepared");
		const applicableMembers = new Map<string, DirectoryMember[]>();
		if(!position) return applicableMembers;
		const members = providedMembers ?? this.app.store.fpDir.directory.getMembers();
		for(const member of members) {
			// if this member is not a dscaid (contact), continue
			if(member.linktype !== "dscaid") continue;
			if(member.sec) continue;
			// if this member is not applicable for our filter, continue
			if(filter && !Object.keys(filter).every(key => {
				return member[key] === filter[key];
			})) {
				continue;
			}
			// in case a shift is provided
			if(shift) {
				const shiftSettings = position.shiftSettings?.[shift];
				if(shiftSettings && shiftSettings.set) {
					if(shiftSettings.setBehavior === "replace" || shiftSettings.setBehavior == null) {
						if(this.validateSet(shiftSettings.set, member, skipValidation)) {
							if(!applicableMembers.has(member.linkid)) {
								applicableMembers.set(member.linkid, []);
							}
							applicableMembers.get(member.linkid).push(member);
						}
					} else if (shiftSettings.setBehavior === "add") {
						if([ shiftSettings.set, position.set ].filter(Boolean).every(set => this.validateSet(set, member, skipValidation))) {
							if(!applicableMembers.has(member.linkid)) {
								applicableMembers.set(member.linkid, []);
							}
							applicableMembers.get(member.linkid).push(member);
						}
					}
				}
			} else if (position.set) {
				if(this.validateSet(position.set, member, skipValidation)) {
					if(!applicableMembers.has(member.linkid)) {
						applicableMembers.set(member.linkid, []);
					}
					applicableMembers.get(member.linkid).push(member);
				}
			}
			if(position.target) {
				position.target.forEach(t => {
					const copy = pickBy({
						fpdirgrp: t.fpdirgrp,
						fpdirpos: t.fpdirpos,
					}, Boolean);
					const memberComparator = pickBy({
						fpdirgrp: member.grp,
						fpdirpos: member.pos,
					}, Boolean);
					if(isMatch(memberComparator, copy)) {
						if(!applicableMembers.has(member.linkid)) {
							applicableMembers.set(member.linkid, []);
						}
						applicableMembers.get(member.linkid).push(member);
					}
				});
			}
		}
		return applicableMembers;
	}

	public getApplicablePositionsForMembers(members: FpDirMember<DirectoryMemberData>[], date: DateTime, setups?: FpApi.Resource.Duty.LocationSetup[]) {
		if(!this.isPrepared) throw new Error("SetupUtil not prepared");
		throw new Error("Outdated method");
		// what do we need? location -> there we get the setups -> there we get the positions and can resolve the applicable positions
		const directory = this.app.store.fpDir.directory.getTree();
		const applicable = new Map<string, FpApi.Resource.Duty.Position[]>();
		for(const member of members) {
			const location = directory.findKey(member.loc);
			const resolved = location.data.location.setups.map(s => {
				return {
					...s,
					$id: this.app.store.resource.setup.getId(s.id),
				};
			});
			const positions = flatten(resolved.map(s => ({
				...s.$id.positions,
				[KEY]: {
					...s,
					member,
				},
			})));
			const applicablePositions: FpApi.Resource.Duty.Position[] = [];
			for(const position of positions) {
				if(position.set) {
					const set = this.app.store.certificateV3Store.setsObj[position.set];
					if(set) {
						const validity = this.app.store.crewCheck.getValidityForSet(toNumber(member.linkid), date, set.id);
						const v = validity.sets.find((e: any) => e.set?.id === set.id);
						if(v?.ttl) {
							applicablePositions.push(position);
						}
					}
				}
				if(position.target) {
					position.target.forEach(t => {
						if(
							(member.grp === t.fpdirgrp && member.pos === t.fpdirpos) ||
							(member.grp === t.fpdirgrp && !t.fpdirpos) ||
							(!t.fpdirgrp && member.pos === t.fpdirpos)
						) {
							applicablePositions.push(position);
						}
					});
				}
			}
			applicable.set(member.id, applicablePositions);
		}
		return applicable;
	}

	public validateSetup(params: LocationValidationSetupParams): LocationValidationSetupResult {
		if (!this.isPrepared) throw new Error("SetupUtil not prepared");
		const setupResult = super.validateSetup(params);

		for (const dateResult of setupResult.dates.values()) {
			for (const positionResult of dateResult.positions) {
				if (positionResult.position.set) {
					const dscaidList = uniq(positionResult.schedules.map(v => +v.linkId));
					dscaidList.forEach(dsc => {
						const validity = this.app.store.crewCheck.getValidityForSet(dsc, dateResult.date, positionResult.position.set);
						validity.sets.forEach((e, i) => {
							if (e["missing"].length) {
								positionResult.missingCerts = e["missing"];
							}
						});
					});
				}
			}
		}

		return setupResult;
	}



	public performPositionCheckReason(params: { schedule: FpApi.Resource.Duty.Schedule; position: FpApi.Resource.Duty.Position; }): { title: string, message: string }[] {
		const reasons: ReturnType<typeof this.performPositionCheckReason> = [];
		// check if contact is archived
		const contact = this.app.store.contact.getId(+params.schedule.linkId);
		if(!contact) reasons.push({ title: "Contact is not available", message: "The contact is not available anymore" });
		if(contact.isActive === false) reasons.push({ title: "Contact is archived", message: "The contact is archived" });
		// check if certs are fine, if they are required
		const members = this.app.store.fpDir.directory.getMembersByResource("dscaid", params.schedule.linkId);
		const applicable = this.getAllApplicableMembersForPosition({ position: params.position, date: DateTime.fromISO(params.schedule.dateFrom), providedMembers: members, shift: params.schedule.data.dsrsid, skipValidation: true });
		if(!applicable.get(params.schedule.linkId)?.length) reasons.push({ title: "Not an applicable member", message: "Contact is not applicable anymore" });
		// if set, check certificates
		const sets = [];
		const shiftSettings = params.position.shiftSettings[params.schedule.data.dsrsid];
		if(shiftSettings?.set) {
			if(shiftSettings.setBehavior === "add") {
				sets.push(...([ shiftSettings.set, params.position.set ].filter(Boolean)));
			} else { // replace or undefined
				sets.push(shiftSettings.set);
			}
		} else if(params.position.set) {
			sets.push(params.position.set);
		}
		if(sets.length) {
			const result = every(sets, set => {
				// doesnt matter which member, the method only uses the linkid
				// TODO Talk to DL: Need to validate set for a given timestamp
				const response = this.app.store.certificateV3Store.getSetValidityForLinkAtDate(set, "dscaid", params.schedule.linkId, params.schedule.dateFrom);
				// if(response.isValid == false) debugger;
				return response.isValid;
			});
			if(result === false) reasons.push({ title: "Certificate is invalid or missing", message: "Certificate is invalid or missing" });
		}
		return reasons;
	}

	public performPositionCheck(params: { schedule: FpApi.Resource.Duty.Schedule; position: FpApi.Resource.Duty.Position; }): boolean {
		return this.performPositionCheckReason(params).length === 0;
	}

	public getCertificateSetsForContact(dscaid: number, setups: FpApi.Resource.Duty.LocationSetup[] = []): Array<number> {
		const uniSetsFromPositions = chain(setups)
			.map(e => e.data.positions.map(e => e.set))
			.flatten()
			.filter(Boolean)
			.uniq()
			.value();
		const fpSets = uniSetsFromPositions.map(e => this.app.store.certificateV3Store.setsObj[e]);
		const resSets = new Set<number>();
		for(const fpSet of fpSets) {
			const validity = this.app.store.certificateV3Store.getSetValidityForLink(fpSet, "dscaid", dscaid.toString());
			if(!validity) continue;
			if(validity.missing.length === 0) {
				resSets.add(fpSet.id);
			}
		}
		return Array.from(resSets);
	}

	public groupContactsByValidRoles(dscaids: number[], setups: FpApi.Resource.Duty.LocationSetup[] = []): Map<string, number[]> {
		const res = new Map<string, Set<number>>();
		for(const dscaid of dscaids) {
			this.getApplicablePositionsForContact(dscaid, setups)
				.forEach(position => {
					if(!position.role) return;
					if(!res.has(position.role)) {
						res.set(position.role, new Set<number>());
					}
					res.get(position.role).add(dscaid);
				});
		}
		return new Map(Array.from(res.entries()).map(([ key, value ]) => [ key, Array.from(value) ]));
	}

	public static flushCache(): void {
		LocationSetupUtil.getApplicablePositionsForContactCache.clear();
	}
	private static getApplicablePositionsForContactCache: Map<string, (FpApi.Resource.Duty.Position & { dsrlsid: number, fpdirloc: number })[]> = new Map();
	private static getApplicablePositionsForContactGenerateKey(dscaid: number, setups: FpApi.Resource.Duty.LocationSetup[] = []): string {
		return `${dscaid}-${JSON.stringify(setups)}`;
	}
	/**
	 * @description useful method for the scheduler to get a list of positions, which are applicable for a given contact. Results are cached globally.
	 * @returns an array of positions, which are applicable for the given contact on the given setups
	 */
	public getApplicablePositionsForContact(dscaid: number, setups: FpApi.Resource.Duty.LocationSetup[] = [], force = false): (FpApi.Resource.Duty.Position & { dsrlsid: number, fpdirloc: number })[] {
		if(!dscaid) return [];
		if(Number.isNaN(dscaid)) return [];
		if(!this.isPrepared) throw new Error("SetupUtil not prepared");
		const cacheKey = LocationSetupUtil.getApplicablePositionsForContactGenerateKey(dscaid, setups);
		if(!force && LocationSetupUtil.getApplicablePositionsForContactCache.has(cacheKey)) {
			return LocationSetupUtil.getApplicablePositionsForContactCache.get(cacheKey);
		}
		// get the users members
		const userMembers = this.app.store.fpDir.directory.getMembersByResource("dscaid", dscaid.toString());
		// get all positions out of the setups
		let filterMembers: Partial<{
			fpdirgrp: number;
			fpdirpos: number;
		}>[];
		const positions = flatten(setups.map(e => e.data?.positions.map(pos => ({ ...pos, dsrlsid: e.id, fpdirloc: e.fpdirloc })) ?? []));
		const responsePositions: (FpApi.Resource.Duty.Position & { dsrlsid: number, fpdirloc: number })[] = [];
		/**m loop over positions  */
		// if(dscaid === 5018231) debugger;
		const workPosition = (position: typeof positions[number]) => {
			/** when set is defined, check if set matches contact */
			if(position.set) {
				const set = this.app.store.certificateV3Store.setsObj[position.set];
				if(set) {
					const validity = this.app.store.certificateV3Store.getSetValidityForLink(set, "dscaid", dscaid.toString());
					if(validity && validity.missing.length === 0) {
						responsePositions.push(position);
						return;
					}
				}
			}
			if(position.target) {
				/** build filter members if did not previously exist */
				if(!filterMembers) {
					filterMembers = chain(userMembers).map(e => {
						const obj = chain({
							fpdirgrp: e.grp,
							fpdirpos: e.pos,
						}).omitBy(e => !e).value();
						if(Object.keys(obj).length) {
							return obj;
						}
					}).filter(Boolean).value();
				}
				// check if the member is in the target using _.isMatch
				const matched = position.target.find(target => {
					return filterMembers.find(e => {
						return isMatch(e, target);
					});
				});
				if(matched) {
					responsePositions.push(position);
				}
			}
		};
		for(const position of positions) {
			/** when set is defined, check if set matches contact */
			workPosition(position);
		}
		LocationSetupUtil.getApplicablePositionsForContactCache.set(cacheKey, responsePositions);
		return responsePositions;
	}

	protected validateResourceStatus<T>(type: string, resource: T, date: DateTime): { status: LocationSetupStatus; reason: string; } {
		switch(type) {
			case "fpvid": {
				const aircraft = resource as FpApi.Resource.Aircraft;
				const state = this.app.store.resource.aircraftState.getId(aircraft.id);
				if(state.maintenance.next_maintenance_at < date.valueOf()) {
					return {
						status: LocationSetupStatus.NotOk,
						reason: "Needs Maintenance",
					};
				}
			}
		}
	}

	/*
	private validatePosition(schedules: Array<FpApi.Resource.Duty.Schedule>, position: FpApi.Resource.Duty.Position): ValidationPositionResult {
		throw new Error("validatePosition");
		if (!this.isPrepared) throw new Error("SetupUtil not prepared");

		const validations = super.validatePosition(schedules, position);
		if (position.set) {
			const dscaids = uniq(validations.duties.map(v => +v.linkId));
			dscaids.forEach(dsc => {
				const validity = this.app.store.crewCheck.getValidityForSet(dsc, day, position.set);
				validity.sets.forEach((e, i) => {
					if (e["missing"].length) {
						validations["missing_certs"] = e["missing"];
					}
				});
			});
		}

		return validations;
	}
	*/

	/**
	 * @description useful method for the scheduler to get a map of roles and dscaids for a given setup
	 * 
	 * @param setups the setups to get the role map from
	 * @param date if none is provided, DateTime.now() is used
	 * @param loc if none is provided, all locations are used
	 * @param skipValidation if true, the certificate validation-filter is skipped. Useful to get all potential members
	 * @returns Map with role as key and an array of dscaids as value. Dscaids are unique within the array.
	 */
	public getRoleMapFromSetups(
		{ setups, date, loc, skipSetValidationFilter }
		: {
			setups: FpApi.Resource.Duty.LocationSetup[],
			date?: DateTime,
			loc?: number,
			skipSetValidationFilter?: boolean
		}): Map<string, number[]> {
		const map = new Map<string, Set<number>>();
		// go over all setups and then over all positions
		for(const setup of setups) {
			for(const position of setup.data.positions) {
				// we want to map after the role, so we need to skip those without a role
				if(!position.role) continue;
				// get all members for this position
				const members = this.getApplicableMembersForPosition({
					position,
					date: date ?? DateTime.now(),
					filter: {
						loc
					},
					providedMembers: this.app.store.fpDir.directory.getMembers(),
					skipValidation: skipSetValidationFilter,
				});
				for(const member of members.values()) {
					const key = position.role;
					if(!map.has(key)) {
						map.set(key, new Set<number>());
					}
					map.get(key).add(+member.linkid);
				}
			}
		}
		// create Array map from Set Map
		return new Map(Array.from(map.entries()).map(([ key, value ]) => [ key, Array.from(value) ]));
	}
}
