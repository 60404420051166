import { BaseCertificate, Category, ClientCategoryOption, ClientCategoryUtil, DirContact, DirectoryNode, FPEvent, FpApi, FpDirClientState, FpLegClient, HazardNode, LandingField, SubCertificate, TreeUtil, apiManager } from "@tcs-rliess/fp-core";
import { fpLog } from "@tcs-rliess/fp-log";
import Aigle from "aigle";
import { clone, toNumber } from "lodash-es";

import { type FleetplanApp } from "../../FleetplanApp";
import { FPSet, LandingFieldDistrict } from "../ClientStore/impls";
import { mapFpLegToFpApiLeg } from "../FpLegHelper";

export class DataLoader {
	constructor(private app: FleetplanApp) {}

	private preventMissingIds<T>(data: Array<T>, ids, key) {
		const mapping = new Map(data.map(e => clone(e)).map(e => [ e?.[key], e ]));
		return ids.map(id => mapping.get(Number.parseInt(id) == id ? Number.parseInt(id) : id) || { id });
	}

	public async load(linkType: string, ids: Array<number | string>): Promise<[ Array<any>, string ]>{
		switch(linkType) {
			case "dscaid":
				return [ this.preventMissingIds(this.loadDscaid(ids as Array<number>), ids, "id"), "id" ]; // -> we need to know where to find the id for this entry
			case "fpdbvmid":
				return [ this.preventMissingIds(this.loadFpdbvmid(ids as Array<string>), ids, "fpdbvmid"), "fpdbvmid" ];
			case "fpvid":
				return [ this.preventMissingIds(this.loadFpvid(ids as Array<number>), ids, "id"), "id" ];
			case "dscatid":
				return [ this.preventMissingIds(this.loadDscatid(ids as Array<number>), ids, "id"), "id" ];
			case "dspiid":
				return [ this.preventMissingIds(await this.loadDspiid(ids as Array<number>), ids, "id"), "id" ];
			case "fplaid":
				return [ this.preventMissingIds(this.loadFplaid(ids as Array<number>), ids, "id"), "id" ];
			case "fplaid_district":
				return [ this.preventMissingIds(await this.loadFplaid_district(ids as Array<number>), ids, "id"), "id" ];
			case "fpdirloc":
			case "fpdirpos":
			case "fpdirgrp":
			case "fpdirsec":
				return [ this.loadDirectoryNodes(ids as Array<number>), "id" ];
			case "dsrlid":
				return [ this.preventMissingIds(await this.loadDsrlid(ids as Array<number>), ids, "id"), "id" ];
			case "dssid":
				return [ this.preventMissingIds(await this.loadDssid(ids as Array<number>), ids, "dssid"), "dssid" ];
			case "dscalid":
				return [ this.preventMissingIds(await this.loadDscalid(ids as Array<number>), ids, "id"), "id" ];
			case "dsqmprcsid":
				return [ this.preventMissingIds(await this.loadDsqmprcsid(ids as Array<number>), ids, "id"), "id" ];
			case "category":
				return [ this.preventMissingIds(await this.loadCategory(ids as Array<number>), ids, "id"), "id" ];
			case "dsqmrepid":
				return [ this.preventMissingIds(await this.loadDsqmrepid(ids as Array<number>), ids, "id"), "id" ];
			case "dsqmauid":
				return [ this.preventMissingIds(await this.loadDsqmauid(ids as Array<number>), ids, "id"), "id" ];
			case "dscdid":
				return [ this.preventMissingIds(await this.loadDscdid(ids as Array<number>), ids, "id"), "id" ];
			case "dscdsid":
				return [ this.preventMissingIds(await this.loadDscdsid(ids as Array<number>), ids, "id"), "id" ];
			case "dscdxcid":
				return [ this.preventMissingIds(await this.loadDscdxcid(ids as Array<number>), ids, "id"), "id" ];
			case "dsbid":
				return [ this.preventMissingIds(await this.loadDsbid(ids as Array<string>), ids, "id"), "id" ];
			case "dseid":
				return [ this.preventMissingIds(await this.loadDseid(ids as Array<string>), ids, "id"), "id" ];
			case "dsdocid":
				return [ this.preventMissingIds(await this.loadDsdocid(ids as Array<number>), ids, "id"), "id" ];
			case "fpresloglid":
				return [ this.preventMissingIds(await this.loadFpresloglid(ids as Array<number>), ids, "id"), "id" ];
			case "fpreslogid":
				return [ this.preventMissingIds(await this.loadFpreslogid(ids as Array<number>), ids, "id"), "id" ];
			case "dscid":
				return [ this.preventMissingIds(await this.loadDscid(ids as Array<number>), ids, "id"), "id" ];
			case "dsqmprocid":
				return [ this.preventMissingIds(await this.loadDsqmprocid(ids as Array<number>), ids, "id"), "id" ];
			case "dsqmctrlid":
				return [ this.preventMissingIds(await this.loadDsqmctrlid(ids as Array<number>), ids, "id"), "id" ];
			case "dsqmrskid":
				return [ this.preventMissingIds(await this.loadDsqmrskid(ids as Array<number>), ids, "id"), "id" ];
			case "dsbiid":
				return [ this.preventMissingIds(await this.loadDsbiid(ids as Array<string>), ids, "id"), "id" ];
			case "priceUnit":
				return [ this.preventMissingIds(await this.loadPriceUnit(ids as Array<string>), ids, "idName"), "idName" ];
			case "dsstocklid":
				return [ this.preventMissingIds(await this.loadDsstocklid(ids as Array<string>), ids, "id"), "id" ];
			case "dsttid":
				return [ this.preventMissingIds(await this.loadDsttid(ids as Array<number>), ids, "id"), "id" ];
			case "dswfid":
				return [ this.preventMissingIds(this.loadDswfid(ids as Array<string>), ids, "id"), "id" ];
			case "dsrdid":
				return [ this.preventMissingIds(this.loadDsrdid(ids as Array<string>), ids, "id"), "id" ];
			case "dsrsid":
				return [ this.preventMissingIds(this.loadDsrsid(ids as Array<string>), ids, "id"), "id" ];
			case "dsrschid":
				return [ this.preventMissingIds(await this.loadDsrschid(ids as Array<number>), ids, "id"), "id" ];
			case "dsrdsid":
				return [ this.preventMissingIds(this.loadDsrdsid(ids as Array<string>), ids, "id"), "id" ];
			case "dserrid":
				return [ this.preventMissingIds(this.loadDserrid(ids as Array<FpApi.Calendar.Event.EventResourceRole>), ids, "id"), "id" ];
			case "dsqmhzid":
				return [ this.preventMissingIds(this.loadDsqmhzid(ids as Array<number>), ids, "id"), "id" ];
			case "dsrswpid":
				return [ this.preventMissingIds(await this.loadDsrswpid(ids as Array<number>), ids, "id"), "id" ];
			case "dsigid":
				return [ this.preventMissingIds(await this.loadDsigid(ids as Array<number>), ids, "id"), "id" ];
			case "dsrlsid":
				return [ this.preventMissingIds(await this.loadDsrlsid(ids as Array<number>), ids, "id"), "id" ];
			case "dscaid:state":
				return [ this.preventMissingIds(await this.loadDscaidState(ids as Array<number>), ids, "id"), "id" ];
			case "licenseEndorsement":
				return [ this.preventMissingIds(this.loadLicenseEndorsement(ids as Array<string>), ids, "id"), "id" ];
			default: {
				console.error(`Unknown link type "${linkType}". ${this[`load${this.capitalize(linkType)}`] ? "But is defined. Maybe you forgot to add it to the switch case" : "" }\nnot able to load ids: ${ids}`);
				return [[], "id" ];
			}
		}
	}

	// method to captilize the first letter of a string
	private capitalize(str: string): string {
		if(!str) return str;
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	public loadDscaid(ids: Array<number> = []): Array<DirContact> {
		const newIds = [];
		for(const e of ids) {
			const id = toNumber(e);
			if(id == null || Number.isNaN(id)) continue;
			if(newIds.indexOf(id) > -1) continue;
			newIds.push(id);
		}
		ids = [ ...newIds ];
		if(ids.length === 0) return [];
		return this.app.store.contact.getIds(ids);
	}

	public loadFpdbvmid(ids: Array<string>): Array<FpApi.Resource.AircraftModel> {
		return this.app.store.resource.aircraftModel.getIds(ids);
	}

	public loadFpvid(ids: Array<number>): Array<FpApi.Resource.Aircraft> {
		return this.app.store.resource.aircraft.getIds(ids);
	}

	public async loadDsqmrepid(ids: Array<number>): Promise<Array<FpApi.Quality.Report.Report>> {
		const response = [];
		for(const id of ids) {
			try {
				response.push(await this.app.store.qualityReport.getId(id));
			} catch (err) {
				fpLog.error(err);
			}
		}
		return response;
	}

	public loadDscatid(ids: Array<number>): Array<FpApi.System.SystemCategory> {
		return this.app.store.systemCategory.getIds(ids.filter(Boolean));
	}

	public loadInlineRelation() {
		return [];
	}

	public loadDynamic() {
		return [];
	}

	public async loadCategory(ids: Array<number>): Promise<Array<Category>> {
		const all = await this.app.store.categoryUtil.getAll();
		const set = new Set(ids);
		const ret: Array<Category> = [];

		const util = new TreeUtil(all, {
			getChildren: e => e.children,
			getKey: e => e.id
		});

		util.walk(e => {
			if(set.has(e.node.id)) {
				ret.push(e.node);
				set.delete(e.node.id);
			}
			if(set.size === 0) return true;
		});
		return ret;
	}

	public async loadDspiid(ids: Array<number>): Promise<Array<FpApi.Product.ProductItem>> {
		return this.app.store.productItemStore.getAll();
	}

	public loadFplaid(ids: Array<number>): Array<LandingField> {
		const req = ids.filter(Boolean);
		if(req.length === 0) return [];
		return this.app.store.landingField.getIds(req);
	}

	public async loadFplaid_district(ids: Array<number>): Promise<Array<LandingFieldDistrict>> {
		await this.app.store.landingField.ensureDistricts();

		const items = [];
		for (const id of ids) {
			const district = this.app.store.landingField.allDistrictsMap.get(id);
			if (district) items.push({
				...district,
				id: district.ZdbOrtId
			});
		}

		return items;
	}

	/**
	 * this methods are only needed to satisfy the interface, internally we just use {@link loadDirectoryNodes}
	 */
	public loadFpdirloc(ids: Array<number>): Array<DirectoryNode> {
		return this.loadDirectoryNodes(ids);
	}
	/**
	 * this methods are only needed to satisfy the interface, internally we just use {@link loadDirectoryNodes}
	 */
	public loadFpdirpos(ids: Array<number>): Array<DirectoryNode> {
		return this.loadDirectoryNodes(ids);
	}
	/**
	 * this methods are only needed to satisfy the interface, internally we just use {@link loadDirectoryNodes}
	 */
	public loadFpdirgrp(ids: Array<number>): Array<DirectoryNode> {
		return this.loadDirectoryNodes(ids);
	}
	/**
	 * this methods are only needed to satisfy the interface, internally we just use {@link loadDirectoryNodes}
	 */
	public loadFpdirsec(ids: Array<number>): Array<DirectoryNode> {
		return this.loadDirectoryNodes(ids);
	}

	private loadDirectoryNodes(ids: Array<number>): Array<DirectoryNode> {
		const items: Array<DirectoryNode> = [];
		const set = new Set(ids);
		const util = TreeUtil.fromDirectoryNodes(this.app.directory);
		util.walk(info => {
			if(ids.includes(info.node.id)) {
				items.push(info.node);
				set.delete(info.node.id);
			}
			if(set.size === 0) return true; // stop walking
		});
		return items;
	}

	public loadDsrsid(ids: Array<string>): Array<FpApi.Resource.Duty.Shift> {
		const response = [];
		for(const id of ids) {
			try {
				response.push(this.app.store.resource.shift.getId(id));
			} catch (err) {
				fpLog.error(err);
			}
		}
		return response;
	}

	/** resource schedule */
	public async loadDsrschid(ids: Array<number>): Promise<Array<FpApi.Resource.Duty.Schedule>> {
		return await this.app.store.resource.schedule.getIdList(ids);
	}

	public loadDsqmhzid(ids: Array<number>): Array<HazardNode> {
		const hazardTree = this.app.store.fpDir.hazard.getTree();
		const dsqmhzidSet = new Set(ids);
		const ret: Array<HazardNode> = [];
		hazardTree.walk(e => {
			if(dsqmhzidSet.has(e.node.id)) {
				dsqmhzidSet.delete(e.node.id);
				ret.push(e.node);
			}
			if(dsqmhzidSet.size === 0) return true;
		});
		return ret;
	}

	public loadDsrdsid(ids: Array<string>): Array<FpApi.Resource.Duty.Setup> {
		return this.app.store.resource.setup.getIds(ids);
	}

	public loadDserrid(ids: Array<FpApi.Calendar.Event.EventResourceRole>): Array<ClientCategoryOption<FpApi.Calendar.Event.EventResourceRole>> {
		const response = [];
		for(const id of ids) {
			try {
				const opt = ClientCategoryUtil.byEnum(FpApi.Calendar.Event.EventResourceRole).getOption(id);
				if (opt) response.push({
					...opt,
					// FpGrid needs an ID!
					id: opt.value
				});
			} catch (err) {
				fpLog.error(err);
			}
		}
		return response;
	}

	public loadLicenseEndorsement(idNames: Array<string>): Array<{ id: string }> {
		// we have to return an array of objects here
		return idNames.map(idName => ({ id: idName }));
	}

	/** security role */
	public async loadDsrlid(ids: number[]): Promise<Array<FpApi.Security.Role>> {
		const roles = await apiManager
			.getService(FpApi.Security.RoleService)
			.get(this.app.ctx);

		return roles.filter(r => ids.includes(r.id));
	}

	/** controlled document */
	public loadDssid(ids: number[]): Promise<FpApi.ControlledDocument.Document[]> {
		return this.app.store.controlledDocument.document.getIdList(ids);
	}

	public async loadDscalid(ids: number[]): Promise<FpApi.Calendar.Calendar[]> {
		const calendars = await apiManager
			.getService(FpApi.Calendar.CalendarService)
			.get(this.app.ctx, {});

		return calendars.filter(r => ids.includes(r.id));
	}

	public async loadDsqmprcsid(ids: number[]): Promise<FpApi.Quality.Process.Process[]> {
		const processes = await apiManager
			.getService(FpApi.Quality.Process.ProcessService)
			.get(this.app.ctx, {
				ids: ids,
			});

		return processes;
	}

	public async loadDsqmauid(ids: number[]): Promise<FpApi.Quality.Audit.Audit[]> {
		return this.app.store.qualityAudit.getIdList(ids);
	}

	public async loadDscdid(ids: number[]): Promise<Array<BaseCertificate> > {
		if (this.app.store.certificateV3Store.initialLoadComplete) {
			return ids.map(e => this.app.store.certificateV3Store.getBaseCertificateById(e));
		} else {
			const res = await this.app.store.certificateV3Store.fetchCertificates({
				id: ids,
			});

			if (res.rows.length) {
				// one base certificate is returned and the cert we're looking for is in members!
				const target: Array<BaseCertificate> = [];
				for (const cert of res.rows) {
					target.push(cert);
				}
				return target;
			}

			return [];
		}
	}

	public async loadDscdsid(ids: number[]): Promise<Array<FPSet>> {
		if (this.app.store.certificateV3Store.initialSetLoadComplete) {
			return ids.map(e => this.app.store.certificateV3Store.setsObj[e]);
		} else {
			await this.app.store.certificateV3Store.ensureSets();

			return ids.map(e => this.app.store.certificateV3Store.setsObj[e]);
		}
	}

	public async loadDscdxcid(ids: number[]): Promise<Array<SubCertificate> > {
		if (this.app.store.certificateV3Store.initialLoadComplete) {
			return ids.map(e => this.app.store.certificateV3Store.getCertificateById(e));
		} else {
			const res = await this.app.store.certificateV3Store.fetchCertificates({
				id_subcert: ids,
			});

			if (res.rows.length) {
				// one base certificate is returned and the cert we're looking for is in members!
				const target: Array<SubCertificate> = [];
				for(const cert of res.rows) {
					target.push(...cert.members.filter(cert => ids.includes(cert.id)));
				}
				return target;
			}
		}

		return [];
	}

	// TODO: Only get the actual bookings defined by ids
	public async loadDsbid(ids: string[]): Promise<Array<FpApi.Booking.Booking>> {
		const bookingService = apiManager.getService(FpApi.Booking.BookingService);
		/*
			2023-07-06
		*/
		return bookingService.get(this.app.ctx, { id: ids });
	}

	public async loadDseid(ids: string[]): Promise<Array<FPEvent>> {
		const events = [];
		for (const id of ids) {
			events.push(await this.app.store.event.getId(id));
		}
		return events ?? [];
	}

	public async loadDsdocid(ids: number[]): Promise<Array<FpApi.Finance.Document>> {
		const financeService = apiManager.getService(FpApi.Finance.DocumentService);
		const items = await financeService.get(this.app.ctx, { ids: ids });
		return items ?? [];
	}

	public async loadFpresloglid(ids: number[]): Promise<Array<FpApi.Resource.AircraftLeg.AircraftLeg>> {
		if(this.app.flags.flightOpsNew) {
			const client = new FpLegClient({ use: "FpGridLoader" });
			const legs = await client.getLegs_byIds(this.app.dscid, {
				ids: ids.map(e => +e),
			});
			return Aigle.map(legs.rows, e => mapFpLegToFpApiLeg(e, this.app));
		}
		const service = apiManager.getService(FpApi.Resource.AircraftLeg.AircraftLegService);
		return await service.get(this.app.ctx, { ids });
	}

	public async loadFpreslogid(ids: number[]): Promise<Array<FpApi.Resource.AircraftLeg.AircraftLogbook>> {
		const service = apiManager.getService(FpApi.Resource.AircraftLeg.AircraftLogbookService);
		return await service.get(this.app.ctx, { ids });
	}

	public async loadDscid(ids: number[]): Promise<Array<FpApi.Customer.Customer>> {
		const service = apiManager.getService(FpApi.Customer.CustomerService);
		return service.getIds(this.app.ctx, { ids });
	}

	public async loadPriceUnit(ids: string[]): Promise<Array<FpApi.System.SystemCategory>> {
		const units = await this.app.store.systemCategory.getType("price_unit");
		return units.filter(e => ids.includes(e.idName));
	}

	public async loadDsqmctrlid(ids: number[]): Promise<FpApi.Quality.Control.Control[]> {
		const controls = await apiManager
			.getService(FpApi.Quality.Control.ControlService)
			.getControls(this.app.ctx, {
				ids: ids,
			});

		return controls;
	}

	public async loadDsqmprocid(ids: number[]): Promise<FpApi.Quality.Procedure.Procedure[]> {
		const procedures = await apiManager
			.getService(FpApi.Quality.Procedure.ProcedureService)
			.get(this.app.ctx, {
				ids: ids,
			});

		return procedures;
	}

	public async loadDsqmrskid(ids: number[]): Promise<FpApi.Quality.Risk.Risk[]> {
		const risks = await apiManager
			.getService(FpApi.Quality.Risk.RiskService)
			.get(this.app.ctx, {
				ids: ids,
			});

		return risks;
	}

	public async loadDsbiid(ids: string[]): Promise<FpApi.Booking.BookingItem[]> {
		return this.app.store.bookingItem.get({ id: ids });
	}

	public async loadDsstocklid(ids: string[]): Promise<FpApi.Stock.Location[]> {
		return apiManager.getService(FpApi.Stock.LocationService)
			.get(this.app.ctx, {
				id: ids,
			});
	}

	public async loadDsttid(ids: number[]): Promise<FpApi.Tag.Tag[]> {
		return this.app.store.tags.getIds(ids);
	}

	public loadDswfid(ids: string[]): FpApi.System.Workflow.Workflow[] {
		return this.app.store.workflow.getAll();
	}

	public loadDsrdid(ids: string[]): FpApi.Resource.Duty.Duty[] {
		return;
	}

	public async loadDsrswpid(ids: number[]): Promise<FpApi.Resource.Duty.WorkPattern[]> {
		return this.app.store.resource.scheduleWorkPattern.getIds(ids);
	}

	public async loadDsigid(ids: number[]): Promise<FpApi.System.Notification.Group[]> {
		return this.app.store.notificationGroup.getIds(ids);
	}

	public async loadDscaidState(ids: number[]): Promise<FpDirClientState[]> {
		return this.app.store.contactState.getIdList(ids);
	}

	public async loadDsrlsid(ids: number[]): Promise<FpApi.Resource.Duty.LocationSetup[]> {
		return this.app.store.resource.locationSetup.getIdList(ids);
	}
}
