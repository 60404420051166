import { DirectoryMember, DirectoryMemberData, FpApi, FpDirMember, SetupStatus, SetupUtilCore, SetupUtilCore_DEPRECATED, ValidationSetupParams, ValidationSetupResult } from "@tcs-rliess/fp-core";
import Aigle from "aigle";
import { chain, flatten, isMatch, omitBy, pickBy, toNumber, uniq } from "lodash-es";
import { DateTime } from "luxon";

import { FleetplanApp } from "../../FleetplanApp";

const KEY = Symbol("hiddenAccessor");
/**
 * @deprecated
 */
export class SetupUtil_DEPRECATED extends SetupUtilCore_DEPRECATED {
	public isPrepared = false;
	constructor(private app: FleetplanApp) {
		super();
	}
	private cache: Map<string, Map<string, SetupStatus >> = new Map();

	public async prepareUtil(): Promise<void> {
		await this.app.store.certificateV3Store.ensureSets();
		this.isPrepared = true;
	}

	public getApplicableMembersForPosition({ position, date, filter, providedMembers }: {position: FpApi.Resource.Duty.Position, date: DateTime, filter: { loc?: number }, providedMembers?: DirectoryMember[] }) {
		if(!this.isPrepared) throw new Error("SetupUtil not prepared");
		const applicableMembers = new Map<string, DirectoryMember>();
		let members = providedMembers ?? this.app.members;
		if(filter && Object.keys(filter)) {
			members = members.filter(m => {
				return Object.keys(filter).every(key => {
					return m[key] === filter[key];
				});
			});
		}
		for(const member of members) {
			if(position.set) {
				const set = this.app.store.certificateV3Store.setsObj[position.set];
				if(set) {
					const validity = this.app.store.crewCheck.getValidityForSet(toNumber(member.linkid), date, set.id);
					const v = validity.sets.find((e: any) => e.set?.id === set.id);
					if(v?.ttl) {
						applicableMembers.set(member.linkid, member);
					}
				}
			}
			if(position.target) {
				position.target.forEach(t => {
					if(member.grp === t.fpdirgrp && member.pos === t.fpdirpos) {
						applicableMembers.set(member.linkid, member);
					}
				});
			}
		}
		return applicableMembers;
	}

	public async getApplicablePositionsForMembers(members: FpDirMember<DirectoryMemberData>[], date: DateTime) {
		if(!this.isPrepared) throw new Error("SetupUtil not prepared");
		// what do we need? location -> there we get the setups -> there we get the positions and can resolve the applicable positions
		const directory = await this.app.store.fpDir.directory.getTree();
		const applicable = new Map<string, FpApi.Resource.Duty.Position[]>();
		for(const member of members) {
			const location = directory.findKey(member.loc);
			const resolved = await Aigle.map(location.data.location.setups, async s => {
				return {
					...s,
					$id: await this.app.store.resource.setup.getId(s.id),
				};
			});
			const positions = flatten(resolved.map(s => ({
				...s.$id.positions,
				[KEY]: {
					...s,
					member,
				},
			})));
			const applicablePositions: FpApi.Resource.Duty.Position[] = [];
			for(const position of positions) {
				if(position.set) {
					const set = this.app.store.certificateV3Store.setsObj[position.set];
					if(set) {
						const validity = this.app.store.crewCheck.getValidityForSet(toNumber(member.linkid), date, set.id);
						const v = validity.sets.find((e: any) => e.set?.id === set.id);
						if(v?.ttl) {
							applicablePositions.push(position);
						}
					}
				}
				if(position.target) {
					position.target.forEach(t => {
						if(
							(member.grp === t.fpdirgrp && member.pos === t.fpdirpos) ||
							(member.grp === t.fpdirgrp && !t.fpdirpos) ||
							(!t.fpdirgrp && member.pos === t.fpdirpos)
						) {
							applicablePositions.push(position);
						}
					});
				}
			}
			applicable.set(member.id, applicablePositions);
		}
		return applicable;
	}

	public validateSetup(duties: Array<FpApi.Resource.Duty.Duty>, setup: FpApi.Resource.Duty.Setup, day: DateTime, options: {
		loc: number;
		subId?: string;
		force?: boolean;
	}): SetupStatus {
		if(!this.isPrepared) throw new Error("SetupUtil not prepared");

		const filteredDuties = duties.filter(d => {
			return d.fpdirloc === options.loc;
		});
		const positions = setup.positions.map(p => {
			const [ res ] = this.validatePosition(filteredDuties, p, day, {
				force: options.force ?? false,
				subId: options.subId
			});
			return res;
		});
		return SetupUtil_DEPRECATED.findWorstStatus(positions);
	}

	public validatePosition(duties: Array<FpApi.Resource.Duty.Duty>, position: FpApi.Resource.Duty.Position, day: DateTime, options: {
		force: boolean;
		subId: string
	}): [ SetupStatus, string[] ] {
		if(!this.isPrepared) throw new Error("SetupUtil not prepared");

		const dayKey = day.toFormat("d-M-y");
		let status = options.force ? null : this.cache.get(dayKey)?.get(`${options.subId}-${position.id}`);
		const used: string[] = [];
		if(status == null) {
			const filteredDuties: Map<string, FpApi.Resource.Duty.Duty> = new Map();
			if(position.target?.length) {
				duties
					// .filter(duty => {
					// 	return position.target.find(loopTarget => loopTarget.fpdirgrp === duty.fpdirgrp && (loopTarget.fpdirpos === duty.fpdirpos || duty.fpdirpos == undefined)) !== undefined;
					// })
					.filter(d => {
						const [ dateStart, dateEnd ] = [ d.dateStart, d.dateEnd ].map(e => e ? DateTime.fromISO(e) : null);
						if(((dateStart?.hour ?? 0) < (dateEnd?.hour ?? 0))) { // is over midnight
							return dateStart.hasSame(day, "day") || dateEnd.hasSame(day, "day");
						}
						return dateStart.hasSame(day, "day");
					})
					.filter(e => e.dsrdsid && e.dsrdsidPos === position.id)
					.forEach(d => {
						filteredDuties.set(d.id, d);
					});
			}
			if (position.set) {
				if(!this.app.store.certificateV3Store.initialSetLoadComplete) return null;
				const set = this.app.store.certificateV3Store.setsObj[position.set];
				if(set == null) return [ null, null ];
				const possibleDuties = duties.filter(e => e.dsrdsid && e.dsrdsidPos === position.id && DateTime.fromISO(e.dateStart).hasSame(day, "day"));
				possibleDuties.filter(e => {
					const validity = this.app.store.crewCheck.getValidityForSet(toNumber(e.linkId), DateTime.fromISO(e.dateEnd), set.id);
					const v = validity.sets.find((e: any) => e.set?.id === set.id);
					return v?.ttl;
				}).forEach(e => {
					filteredDuties.set(e.id, e);
				});
			}
			const isRuleValid = (rule: string[]) => {
				let copy = [ ...rule ];
				rule.forEach(shiftIdFromRule => {
					filteredDuties.forEach(e => {
						if(copy.length === 0) return;
						if(used.includes(e.id)) return;
						if(e.dsrsid === shiftIdFromRule) {
							used.push(e.id);
							// duties.push(e);
							copy = copy.filter(e => e !== shiftIdFromRule);
						}
					});
				});
				if(copy.length === 0) {
					return SetupStatus.Ok;
				} else if(copy.length < rule.length) {
					return SetupStatus.PartialOk;
				} else {
					return SetupStatus.NotOk;
				}
			};
			const ret: SetupStatus[] = [];
			for(let i = 0; i < (position.amount ?? 1); i++) {
				const status: Array<SetupStatus> = position.rule.map((rule: string[]) => {
					return isRuleValid(rule);
				});
				ret.push(SetupUtilCore_DEPRECATED.findBestStatus(status));
			}

			// check once more to try overbooked
			const st: Array<SetupStatus> = position.rule.map((rule: string[]) => {
				return isRuleValid(rule);
			});
			if(st.includes(SetupStatus.Ok) || st.includes(SetupStatus.PartialOk)) ret.push(SetupStatus.Overbooked);
			// ret.push(status);
			status = SetupUtilCore_DEPRECATED.findWorstStatus(ret);
			const cacheMap = this.cache.get(dayKey);
			if(cacheMap == null) {
				this.cache.set(dayKey, new Map([[ `${options.subId}-${position.id}`, status ]]));
			} else {
				cacheMap.set(`${options.subId}-${position.id}`, status);
			}
		}
		return [ status, used ];
	}
}

export class SetupUtil extends SetupUtilCore {
	public isPrepared = false;
	constructor(private app: FleetplanApp) {
		super();
	}
	// private cache: Map<string, Map<string, SetupStatus >> = new Map();

	public async prepareUtil(): Promise<void> {
		await this.app.store.certificateV3Store.ensureSets();
		this.isPrepared = true;
	}

	protected syncGetLinkType<T = unknown>(linkId: string, linkIdType: string): T {
		switch(linkIdType) {
			case "fpvid":
				return this.app.store.resource.aircraft.getId(+linkId) as any;
		}
	}

	public getAssignmentsFromPosition(position: FpApi.Resource.Duty.Position): { fpdirgrp?: number, fpdirpos?: number }[] {
		const assignments: ReturnType<typeof this.getAssignmentsFromPosition> = [];
		if(position.target) {
			position.target.forEach(t => {
				if(t.fpdirgrp || t.fpdirpos) {
					assignments.push(t);
				}
			});
		}
		if(position.set) {
			const set = this.app.store.certificateV3Store.setsObj[position.set];
			if(set) {
				set.relations.filter(rel => rel.type === "fpdirlink" && rel.category === "ASSIGNEDTO")
					.forEach(rel => {
						assignments.push({
							fpdirgrp: rel.fpdirgrp,
							fpdirpos: rel.fpdirpos,
						});
					});
			}
		}
		return assignments;
	}

	/**
	 * To eject contacts when certificates did expire, set keepBySetInvalid to false
	 * its true per default
	 * */
	public getValidMemberForPosition(dscaid: number, position: FpApi.Resource.Duty.Position, keepBySetInvalid = true) {
		if(!this.isPrepared) throw new Error("SetupUtil not prepared");
		const membersForContact = this.app.store.fpDir.directory.getMembersByResource("dscaid", dscaid);
		let response: DirectoryMember;
		if(position.set) {
			const set = this.app.store.certificateV3Store.setsObj[position.set];
			if(set) {
				const validity = this.app.store.certificateV3Store.getSetValidityForLink(set, "dscaid", dscaid.toString());
				const applicableRelations = set.relations.filter(rel => rel.category === "ASSIGNEDTO" && (rel.fpdirgrp || rel.fpdirpos));
				const filters = applicableRelations.map(rel => {
					return omitBy({
						fpdirgrp: rel.fpdirgrp,
						fpdirpos: rel.fpdirpos,
					}, e => !e);
				});
				const applicableMember = membersForContact.find(member => {
					return filters.find(filter => {
						return isMatch(member, filter);
					});
				});
				if(validity.missing.length) response = applicableMember;
				else if (validity.expired.length) {
					if(keepBySetInvalid) response = applicableMember;
					else response = null;
				}
			}
		}
		/** when its now validated to true, we can asume this contact is fine */
		/** if not, we still need to checks for positions */
		const memberFilter = new Map(membersForContact.map(member => [ member, (omitBy({
			fpdirgrp: member.grp,
			fpdirpos: member.pos,
		}, e => !e)) ]));
		if(response || !position.target) return response;
		for(const member of membersForContact) {
			for(const target of position.target) {
				if(isMatch(memberFilter.get(member), target)) {
					return member;
				}
			}
		}
	}

	/**
	 * To eject contacts when certificates did expire, set keepBySetInvalid to false
	 * its true per default
	 * */
	public getValidMembersForPosition(dscaid: number, position: FpApi.Resource.Duty.Position, keepBySetInvalid = true) {
		if(!this.isPrepared) throw new Error("SetupUtil not prepared");
		const membersForContact = this.app.store.fpDir.directory.getMembersByResource("dscaid", dscaid);
		let response: DirectoryMember[];
		if(position.set) {
			const set = this.app.store.certificateV3Store.setsObj[position.set];
			if(set) {
				const validity = this.app.store.certificateV3Store.getSetValidityForLink(set, "dscaid", dscaid.toString());
				const applicableRelations = set.relations.filter(rel => rel.category === "ASSIGNEDTO" && (rel.fpdirgrp || rel.fpdirpos));
				const filters = applicableRelations.map(rel => {
					return omitBy({
						fpdirgrp: rel.fpdirgrp,
						fpdirpos: rel.fpdirpos,
					}, e => !e);
				});
				const applicableMembers = membersForContact.filter(member => {
					return filters.find(filter => {
						return isMatch(member, filter);
					});
				});
				if(validity.missing.length) response = applicableMembers;
				else if (validity.expired.length) {
					if(keepBySetInvalid) response = applicableMembers;
					else response = null;
				}
			}
		}
		/** when its now validated to true, we can asume this contact is fine */
		/** if not, we still need to checks for positions */
		const memberFilter = new Map(membersForContact.map(member => [ member, (omitBy({
			fpdirgrp: member.grp,
			fpdirpos: member.pos,
		}, e => !e)) ]));
		if(!position.target) return response;
		for(const member of membersForContact) {
			for(const target of position.target) {
				if(isMatch(memberFilter.get(member), target)) {
					if(!response) response = [];
					response.push(member);
				}
			}
		}
		return response;
	}


	public getApplicableMembersForPosition({ position, date, filter, providedMembers, skipValidation }: {position: FpApi.Resource.Duty.Position, date: DateTime, filter?: { loc?: number }, providedMembers?: DirectoryMember[], skipValidation?: boolean }) {
		if(!this.isPrepared) throw new Error("SetupUtil not prepared");
		const applicableMembers = new Map<string, DirectoryMember>();
		const members = providedMembers ?? this.app.store.fpDir.directory.getMembers();
		for(const member of members) {
			// if this member is not a dscaid (contact), continue
			if(member.linktype !== "dscaid") continue;
			// if this member is not applicable for our filter, continue
			if(filter && !Object.keys(filter).every(key => {
				return member[key] === filter[key];
			})) {
				continue;
			}
			if(position.set) {
				const set = this.app.store.certificateV3Store.setsObj[position.set];
				if(set) {
					const validity = this.app.store.certificateV3Store.getSetValidityForLink(set, "dscaid", member.linkid);
					if(!skipValidation && (validity.ok.length + validity.infinite.length + validity.expires.length + validity.grace.length) >= validity.required.length) {
						applicableMembers.set(member.linkid, member);
					} else if(skipValidation && validity.missing.length === 0) {
						applicableMembers.set(member.linkid, member);
					}
				}
			}
			if(position.target) {
				position.target.forEach(t => {
					if(member.grp === t.fpdirgrp && member.pos === t.fpdirpos) {
						applicableMembers.set(member.linkid, member);
					}
				});
			}
		}
		return applicableMembers;
	}

	public getAllApplicableMembersForPosition({ position, date, filter, providedMembers, skipValidation }: {position: FpApi.Resource.Duty.Position, date: DateTime, filter?: { loc?: number }, providedMembers?: DirectoryMember[], skipValidation?: boolean }) {
		if(!this.isPrepared) throw new Error("SetupUtil not prepared");
		const applicableMembers = new Map<string, DirectoryMember[]>();
		const members = providedMembers ?? this.app.store.fpDir.directory.getMembers();
		for(const member of members) {
			// if this member is not a dscaid (contact), continue
			if(member.linktype !== "dscaid") continue;
			if(member.sec) continue;
			// if this member is not applicable for our filter, continue
			if(filter && !Object.keys(filter).every(key => {
				return member[key] === filter[key];
			})) {
				continue;
			}
			if(position.set) {
				const set = this.app.store.certificateV3Store.setsObj[position.set];
				if(set) {
					const validity = this.app.store.certificateV3Store.getSetValidityForLink(set, "dscaid", member.linkid);
					if(!skipValidation && (validity.ok.length + validity.infinite.length + validity.expires.length + validity.grace.length) >= validity.required.length) {
						if(!applicableMembers.has(member.linkid)) {
							applicableMembers.set(member.linkid, []);
						}
						applicableMembers.get(member.linkid).push(member);
					} else if(skipValidation && validity.missing.length === 0) {
						if(!applicableMembers.has(member.linkid)) {
							applicableMembers.set(member.linkid, []);
						}
						applicableMembers.get(member.linkid).push(member);
					}
				}
			}
			if(position.target) {
				position.target.forEach(t => {
					const copy = pickBy({
						fpdirgrp: t.fpdirgrp,
						fpdirpos: t.fpdirpos,
					}, Boolean);
					const memberComparator = pickBy({
						fpdirgrp: member.grp,
						fpdirpos: member.pos,
					}, Boolean);
					if(isMatch(memberComparator, copy)) {
						if(!applicableMembers.has(member.linkid)) {
							applicableMembers.set(member.linkid, []);
						}
						applicableMembers.get(member.linkid).push(member);
					}
				});
			}
		}
		return applicableMembers;
	}

	public getApplicablePositionsForMembers(members: FpDirMember<DirectoryMemberData>[], date: DateTime, setups?: FpApi.Resource.Duty.Setup[]) {
		if(!this.isPrepared) throw new Error("SetupUtil not prepared");
		// what do we need? location -> there we get the setups -> there we get the positions and can resolve the applicable positions
		const directory = this.app.store.fpDir.directory.getTree();
		const applicable = new Map<string, FpApi.Resource.Duty.Position[]>();
		for(const member of members) {
			const location = directory.findKey(member.loc);
			const resolved = location.data.location.setups.map(s => {
				return {
					...s,
					$id: this.app.store.resource.setup.getId(s.id),
				};
			});
			const positions = flatten(resolved.map(s => ({
				...s.$id.positions,
				[KEY]: {
					...s,
					member,
				},
			})));
			const applicablePositions: FpApi.Resource.Duty.Position[] = [];
			for(const position of positions) {
				if(position.set) {
					const set = this.app.store.certificateV3Store.setsObj[position.set];
					if(set) {
						const validity = this.app.store.crewCheck.getValidityForSet(toNumber(member.linkid), date, set.id);
						const v = validity.sets.find((e: any) => e.set?.id === set.id);
						if(v?.ttl) {
							applicablePositions.push(position);
						}
					}
				}
				if(position.target) {
					position.target.forEach(t => {
						if(
							(member.grp === t.fpdirgrp && member.pos === t.fpdirpos) ||
							(member.grp === t.fpdirgrp && !t.fpdirpos) ||
							(!t.fpdirgrp && member.pos === t.fpdirpos)
						) {
							applicablePositions.push(position);
						}
					});
				}
			}
			applicable.set(member.id, applicablePositions);
		}
		return applicable;
	}

	public validateSetup(params: ValidationSetupParams): ValidationSetupResult {
		if (!this.isPrepared) throw new Error("SetupUtil not prepared");
		const setupResult = super.validateSetup(params);

		for (const dateResult of setupResult.dates.values()) {
			for (const positionResult of dateResult.positions) {
				if (positionResult.position.set) {
					const dscaidList = uniq(positionResult.schedules.map(v => +v.linkId));
					dscaidList.forEach(dsc => {
						const validity = this.app.store.crewCheck.getValidityForSet(dsc, dateResult.date, positionResult.position.set);
						validity.sets.forEach((e, i) => {
							if (e["missing"].length) {
								positionResult.missingCerts = e["missing"];
							}
						});
					});
				}
			}
		}

		return setupResult;
	}

	public getCertificateSetsForContact(dscaid: number, setups: FpApi.Resource.Duty.Setup[] = []): Array<number> {
		const uniSetsFromPositions = chain(setups)
			.map(e => e.positions.map(e => e.set))
			.flatten()
			.filter(Boolean)
			.uniq()
			.value();
		const fpSets = uniSetsFromPositions.map(e => this.app.store.certificateV3Store.setsObj[e]);
		const resSets = new Set<number>();
		for(const fpSet of fpSets) {
			const validity = this.app.store.certificateV3Store.getSetValidityForLink(fpSet, "dscaid", dscaid.toString());
			if(!validity) continue;
			if(validity.missing.length === 0) {
				resSets.add(fpSet.id);
			}
		}
		return Array.from(resSets);
	}

	public groupContactsByValidRoles(dscaids: number[], setups: FpApi.Resource.Duty.Setup[] = []): Map<string, number[]> {
		const res = new Map<string, Set<number>>();
		for(const dscaid of dscaids) {
			this.getApplicablePositionsForContact(dscaid, setups)
				.forEach(position => {
					if(!position.role) return;
					if(!res.has(position.role)) {
						res.set(position.role, new Set<number>());
					}
					res.get(position.role).add(dscaid);
				});
		}
		return new Map(Array.from(res.entries()).map(([ key, value ]) => [ key, Array.from(value) ]));
	}

	public static flushCache(): void {
		SetupUtil.getApplicablePositionsForContactCache.clear();
	}
	private static getApplicablePositionsForContactCache: Map<string, FpApi.Resource.Duty.Position[]> = new Map();
	private static getApplicablePositionsForContactGenerateKey(dscaid: number, setups: FpApi.Resource.Duty.Setup[] = []): string {
		return `${dscaid}-${JSON.stringify(setups)}`;
	}
	/**
	 * @description useful method for the scheduler to get a list of positions, which are applicable for a given contact. Results are cached globally.
	 * @returns an array of positions, which are applicable for the given contact on the given setups
	 */
	public getApplicablePositionsForContact(dscaid: number, setups: FpApi.Resource.Duty.Setup[] = [], force = false): FpApi.Resource.Duty.Position[] {
		if(!dscaid) return [];
		if(Number.isNaN(dscaid)) return [];
		if(!this.isPrepared) throw new Error("SetupUtil not prepared");
		const cacheKey = SetupUtil.getApplicablePositionsForContactGenerateKey(dscaid, setups);
		if(!force && SetupUtil.getApplicablePositionsForContactCache.has(cacheKey)) {
			return SetupUtil.getApplicablePositionsForContactCache.get(cacheKey);
		}
		// get the users members
		const userMembers = this.app.store.fpDir.directory.getMembersByResource("dscaid", dscaid.toString());
		// get all positions out of the setups
		let filterMembers: Partial<{
			fpdirgrp: number;
			fpdirpos: number;
		}>[];
		const positions = flatten(setups.map(e => e.positions));
		const responsePositions: FpApi.Resource.Duty.Position[] = [];
		/**m loop over positions  */
		// if(dscaid === 5018231) debugger;
		for(const position of positions) {
			/** when set is defined, check if set matches contact */
			if(position.set) {
				const set = this.app.store.certificateV3Store.setsObj[position.set];
				if(set) {
					const validity = this.app.store.certificateV3Store.getSetValidityForLink(set, "dscaid", dscaid.toString());
					if(validity && validity.missing.length === 0) {
						responsePositions.push(position);
						continue;
					}
				}
			}
			if(position.target) {
				/** build filter members if did not previously exist */
				if(!filterMembers) {
					filterMembers = chain(userMembers).map(e => {
						const obj = chain({
							fpdirgrp: e.grp,
							fpdirpos: e.pos,
						}).omitBy(e => !e).value();
						if(Object.keys(obj).length) {
							return obj;
						}
					}).filter(Boolean).value();
				}
				// check if the member is in the target using _.isMatch
				const matched = position.target.find(target => {
					return filterMembers.find(e => {
						return isMatch(e, target);
					});
				});
				if(matched) {
					responsePositions.push(position);
				}
			}
		}
		SetupUtil.getApplicablePositionsForContactCache.set(cacheKey, responsePositions);
		return responsePositions;
	}

	protected validateResourceStatus<T>(type: string, resource: T, date: DateTime): { status: SetupStatus; reason: string; } {
		switch(type) {
			case "fpvid": {
				const aircraft = resource as FpApi.Resource.Aircraft;
				const state = this.app.store.resource.aircraftState.getId(aircraft.id);
				if(state.maintenance.next_maintenance_at < date.valueOf()) {
					return {
						status: SetupStatus.NotOk,
						reason: "Needs Maintenance",
					};
				}
			}
		}
	}

	/*
	private validatePosition(schedules: Array<FpApi.Resource.Duty.Schedule>, position: FpApi.Resource.Duty.Position): ValidationPositionResult {
		throw new Error("validatePosition");
		if (!this.isPrepared) throw new Error("SetupUtil not prepared");

		const validations = super.validatePosition(schedules, position);
		if (position.set) {
			const dscaids = uniq(validations.duties.map(v => +v.linkId));
			dscaids.forEach(dsc => {
				const validity = this.app.store.crewCheck.getValidityForSet(dsc, day, position.set);
				validity.sets.forEach((e, i) => {
					if (e["missing"].length) {
						validations["missing_certs"] = e["missing"];
					}
				});
			});
		}

		return validations;
	}
	*/

	/**
	 * @description useful method for the scheduler to get a map of roles and dscaids for a given setup
	 * 
	 * @param setups the setups to get the role map from
	 * @param date if none is provided, DateTime.now() is used
	 * @param loc if none is provided, all locations are used
	 * @param skipValidation if true, the certificate validation-filter is skipped. Useful to get all potential members
	 * @returns Map with role as key and an array of dscaids as value. Dscaids are unique within the array.
	 */
	public getRoleMapFromSetups(
		{ setups, date, loc, skipSetValidationFilter }
		: {
			setups: FpApi.Resource.Duty.Setup[],
			date?: DateTime,
			loc?: number,
			skipSetValidationFilter?: boolean
		}): Map<string, number[]> {
		const map = new Map<string, Set<number>>();
		// go over all setups and then over all positions
		for(const setup of setups) {
			for(const position of setup.positions) {
				// we want to map after the role, so we need to skip those without a role
				if(!position.role) continue;
				// get all members for this position
				const members = this.getApplicableMembersForPosition({
					position,
					date: date ?? DateTime.now(),
					filter: {
						loc
					},
					providedMembers: this.app.store.fpDir.directory.getMembers(),
					skipValidation: skipSetValidationFilter,
				});
				for(const member of members.values()) {
					const key = position.role;
					if(!map.has(key)) {
						map.set(key, new Set<number>());
					}
					map.get(key).add(+member.linkid);
				}
			}
		}
		// create Array map from Set Map
		return new Map(Array.from(map.entries()).map(([ key, value ]) => [ key, Array.from(value) ]));
	}
}
