import { FpDirClient, IContactsHRRow } from "@tcs-rliess/fp-core";
import { DateTime } from "luxon";

import { BaseStoreSingle } from "../BaseStoreSingle";

export class ActiveContractStore extends BaseStoreSingle<IContactsHRRow, number> {
	private dirClient = new FpDirClient({ use: "ActiveContractStore" });

	protected itemId(item: IContactsHRRow): number {
		return item.id;
	}

	protected async fetchIdList(idList: number[]): Promise<IContactsHRRow[]> {
		const response = await this.dirClient.contractHrFilterByDate(this.app.dscid, DateTime.local(), { filters: [{ id: idList, }] });
		const resMap = new Map(response.rows.map(row => [ row.id, row ] as [ number, IContactsHRRow ]));
		idList.forEach(id => this.update(resMap.get(id)));
		return response.rows;
	}
}
