import { FpApi, FpId, TreeUtil, FpDirNodeKind, DirectoryNode, CertificatePermission, DirContact, sleep, CertificateStatus, BaseCertificate, SubCertificate, getPermissionTypeData, ClientCategoryUtil, FpDirClientStateCertificate } from "@tcs-rliess/fp-core";
import { uniq, clone, uniqBy, uniqWith, groupBy, mapValues, toNumber, cloneDeep, intersection, chunk } from "lodash-es";
import { DateTime } from "luxon";
import { action, autorun, computed, observable, reaction, transaction } from "mobx";
import moment from "moment-timezone";

import { FleetplanApp } from "../../../FleetplanApp";
import { handleError } from "../../../handleError";
import { Relation } from "../../../modules/Common/Util/ObjectLabel/Relation";

// name should never be touched!
export const certificatePermissions = [
	{ label: "Own Reader", name: "OwnReader", value: CertificatePermission.OwnReader, description: "Read own Certificate" }, // view a base certificate but only your own sub certificate(s) - only latest version
	{ label: "Reader", name: "Reader", value: CertificatePermission.Reader, description: "Read all Certificates (only latest version)" }, // see all sub certificates - only latest version
	{ label: "Download", name: "Download", value: CertificatePermission.Download, description: "Download own Certificate" }, // download attached file(s)
	{ label: "Reader (History)", name: "ReaderHistory", value: CertificatePermission.ReaderHistory, description: "Read all Certificates including all versions" }, // see all sub certificates - all versions
	{ label: "Own Editor", name: "OwnEditor", value: CertificatePermission.OwnEditor, description: "Edit own Certificate" }, // can edit/add certificates to themselves
	{ label: "Editor", name: "Editor", value: CertificatePermission.Editor, description: "Add Versions or add/edit Certificates for any User" }, // add version or new sub certificate
	{ label: "Verify", name: "Verify", value: CertificatePermission.Verify, description: "Change Status from Requested/NonRequested to Verified/Unverified on any Certificate" }, // set certificates from requested/not_requested/unverified to verified
	{ label: "Delete", name: "Delete", value: CertificatePermission.Delete, description: "Delete Sub Certificates" }, // can delete sub certificates
	{ label: "Manager", name: "Manager", value: CertificatePermission.Manager, description: "Edit, Delete and Manage Base Certificate and all Sub Certificates" },  // edit and delete base certificate
];

export interface GenericAPIResponse<T> {
	rows: T[];
	scope: "certificates" | "sub_certificates" | "sets";
	type: "list" | "insert" | "update" | "delete";
}

export interface BaseCertsSortedByCat {
	[key: number]: BaseCertificate[];
}

// we call this FPSet instead of Set because Set is a reserved word..
export interface FPSet {
	// assignedFor: Array<{ type: string, itemType: string, itemId: number | string }>;
	dscdidLinkType: string; // which base certificates can be assigned to this set? (e.g. "fpvid", "dscaid", ...)
	relations: FpApi.InlineRelation[];
	dscid: number;
	dscdids?: number[];
	tagList: number[];
	id: number;
	members: FPSetMember[];
	name: string;
	abbreviation: string;
	viewerGroupIDs: number[];
	_r: string;
	permissions?: Array<FpApi.Security.Policy>;
	itemsInSet: Array<{ linkId: number; linkType: string; relId: number; relType: string; }>;
	rlog: FpApi.rlog;
	strict?: boolean;
	displayMode?: "doe" | "doi";
}

export interface FPSetMember {
	rlog: FpApi.rlog;
	dscdid: number; // base certificate id
	dscdsid: number; // set id
	id: number;
	sort: number;
}

export interface CertificateFilter {
	// general
	id?: number[];
	dscdcid?: number[];
	dscaid?: number[]; // person
	dscaid_organization?: number[]; // organization
	fpvid?: number[]; // aircraft
	fplaid?: number[]; // landing field

	masterId?: number;
	groupid?: string[];

	// get all versions of one certificate for one user
	versions?: {
		dscdid: number;
		groupid: string;
		linkid: string;
	};

	// only use for objectlabel fetching!!
	id_subcert?: number[];
	linkidtype?: string[];

	// directory related
	dirid?: number[];
}

export interface BaseCertificateGridItem extends BaseCertificate {
	memberCount: number;
	sets: number;
	group: string;
	expiry: number;
	type: "BC" | "MBC" | "REF" | "GM";
}

export interface TreeNode {
	originalType?: string;
	parentid?: number; // only available if node comes from directory
	kind?: FpDirNodeKind; // only available if node comes from directory
	set?: number; // only available for sets
	members?: any[];
	type: string;
	expanded: boolean;
	active?: boolean;
	children: TreeNode[];
	id: string;
	pureId?: number;
	name: string;
	countTotal?: number;
	countRenewal?: number;
	countDue?: number;
	countExpired?: number;
	countGrace?: number;
	countValid?: number;
	countInvalid?: number; // exclusive to sets
	view?: { // needed for onclick to change event
		module: string;
		id?: string;
		type?: string;
		detail?: boolean;
	};
	typeSysCat?: string;
}

export class CertificateV3Store {
	private app: FleetplanApp;
	public initialLoadComplete = false;

	// fp items
	@observable public certificateStatusList: CertificateStatus[] = [ CertificateStatus.Verified, CertificateStatus.Unverified, CertificateStatus.Requested, CertificateStatus.NotRequested ];
	@observable public verifiedStatusList: CertificateStatus[] = [ CertificateStatus.Verified, CertificateStatus.Unverified ];
	@observable public requestedStatusList: CertificateStatus[] = [ CertificateStatus.Requested, CertificateStatus.NotRequested ];

	// fp-certificates api items
	@observable.shallow public sets: FPSet[] = [];
	@observable.shallow public setsObj: { [key: string]: FPSet } = {};
	public baseCertificates: BaseCertificate[] = [];
	@observable.shallow public baseCertificatesObj: { [key: string]: BaseCertificate } = {};
	@observable.shallow public activeCertificatesObj: { [key: string]: BaseCertificate } = {};
	@observable.shallow public certificates: { [key: number]: SubCertificate } = {};
	@observable.shallow public certificatesByLink: { [key: string]: { // linkType
		[key: string]: { // linkId
			[key: string]: SubCertificate // dscdid
		}
	}} = {};

	public fetchCache = new Map<string, Map<number, number>>();

	// directory and tree
	public directoryId: number = null;
	public defaultDirectoryParentId: number = null;
	@observable.ref public util: TreeUtil<TreeNode, string>;

	public linkTypes = [{ label: "Aircraft", value: "fpvid" }, { label: "Person", value: "dscaid" }, { label: "Organization", value: "dscaid_organization" }, { label: "Landing Field", value: "fplaid" }];

	constructor(app: FleetplanApp) {
		this.app = app;

		autorun(() => {
			const setsObj: { [key: string]: FPSet } = {};

			this.sets.map((set) => {
				setsObj[set.id] = set;
			});

			this.setsObj = setsObj;
			this.refreshSetsInTree();
		});

		autorun(() => {
			this.baseCertificatesObj;

			this.remapCertificates();
		});

		// autorun to wait for util to be created
		const cancelRun = autorun(() => {
			this.util;

			if (this.util) {
				cancelRun(); // remove the autorun for this.util

				void this.setTreeActive(this.currentView); // initial run
				// recurring run
				reaction(
					() => this.currentView,
					(currentView) => {
						void this.setTreeActive(currentView);
					}
				);
			}
		});
	}

	// check if current view is a set => set tree nodes to active/expanded
	private setTreeActive = async (currentView) => {
		if (currentView) {
			if (currentView.module === "sets") {
				// deselect all nodes
				this.util.walk((info) => {
					if (info.node.active === true) info.node.active = false;
				});

				if (!this.initialSetLoadComplete) await this.ensureSets();

				this.util.findKey("-200").expanded = true;
				const setsTree = this.util?.subTree("-200");

				let parents = [];
				setsTree.walk((wd) => {
					if (wd.node.set === +currentView.id) {
						wd.node.active = true;

						parents = wd.parents;
						parents.forEach((parent) => {
							parent.expanded = true;
						});
					} else {
						// if (![ +currentView.id, ...parents.map(p => p.id) ].includes(wd.node.id)) wd.node.active = false;
					}
				});

				this.countTreeNodes();
				this.util = this.util.cloneShallow();
			}
		}
	};

	@observable currentView: TreeNode["view"];
	@observable setCurrentView = (view: TreeNode["view"]): void => {
		if (view.module == "dashboard") view.module = "certificates"; // [2023-09-15] RL: Hide Dashboard for now, its useless
		this.currentView = view;
	}

	currentViewToURL = (): string => {
		let url = "/certificates/manager";
		if (!this.currentView) return url;

		if (this.currentView.module) url += `/${this.currentView.module}`;
		if (this.currentView.type) url += `/${this.currentView.type}`;
		if (this.currentView.id) url += `/${this.currentView.id}`;

		return url;
	}

	@action.bound
	private remapCertificates = () => {
		// base certificates
		const activeCertsObj: { [key: string]: BaseCertificate } = {};
		const members: { [key: string]: SubCertificate } = {};
		const membersByLink: { [key: string]: {
			[key: string]: {
				[key: string]: SubCertificate
			}
		}} = {};

		this.baseCertificates = Object.values(this.baseCertificatesObj);
		this.baseCertificates.map(baseCert => {
			if (!baseCert.isArchived) {
				activeCertsObj[baseCert.id] = {
					...baseCert,
					members: baseCert.members?.filter(el => el.isCurrentVersion && !el.isdeleted && !el.isArchived && this.isAllowed(baseCert.id, "cert", "read", el))
				};
			}

			baseCert.members.forEach((cert) => {
				members[cert.id] = cert;

				if (cert.isCurrentVersion) {
					if (!membersByLink[cert.linkidtype]) {
						membersByLink[cert.linkidtype] = {
							[cert.linkid]: {
								[baseCert.id]: cert
							}
						};
					} else {
						if (!membersByLink[cert.linkidtype][cert.linkid]) {
							membersByLink[cert.linkidtype][cert.linkid] = {
								[baseCert.id]: cert
							};
						} else {
							const exists = membersByLink[cert.linkidtype][cert.linkid][baseCert.id];
							// case: we have multiple of the same base certificate
							if (exists) {
								// required certificate weighs more than not required
								if (cert.isRequired && !exists.isRequired) {
									membersByLink[cert.linkidtype][cert.linkid][baseCert.id] = cert;
								// always choose the one that expires later
								} else if (moment(cert.dateExpiry) > moment(exists.dateExpiry) && ![ "requested", "notrequested" ].includes(cert.dscatidStatus)) {
									membersByLink[cert.linkidtype][cert.linkid][baseCert.id] = cert;
								}
							} else {
								membersByLink[cert.linkidtype][cert.linkid][baseCert.id] = cert;
							}
						}
					}
				}
			});
		});

		this.activeCertificatesObj = activeCertsObj;
		this.certificates = members;
		this.certificatesByLink = membersByLink;
	};

	private directoryItems: DirectoryNode[] = [];

	@action.bound
	public async ensureDirectory(tree?, force = false): Promise<void> {
		/*if ((!this.util && tree) || force) {
			const directoryItems = await this.loadDirectoryItems(tree);
			await this.generateTree(directoryItems);
		} else */
		if (this.util && !force) {
			this.countTreeNodes();
			this.refreshTree(true);
		} else {
			if (!this.util) {
				this.directoryItems = await this.loadDirectoryItems(tree); // this only has to happen once
				await this.generateTree(this.directoryItems);
			} else {
				await this.generateTree(this.directoryItems);
			}
		}
	}

	private ensureSetsPromise: Promise<void>;
	@action.bound
	public async ensureSets(sets?: FPSet[], force = false): Promise<void> {
		if (!this.initialSetLoadComplete || force) {
			this.initialSetLoadComplete = true; // avoid multiple runs
			this.ensureSetsPromise = this.fetchSets(sets, true);

			// update sets in tree!
			// this.refreshSetsInTree();
		}
		await this.ensureSetsPromise;
	}

	@action.bound
	public async refresh(force = false): Promise<void> {
		if (!this.initialLoadComplete || force) {
			this.setLoading(true);
			this.initialLoadComplete = true;

			// order is relevant
			try {
				// make requests and wait for them
				const [ /*sets,*/ certs ] = await Promise.all(
					[
						// this.getSetsFromAPI(),
						this.fetchCertificates(null)
					]
				);

				await this.refreshCertificateDataset(null, certs.rows, true);
				// await this.ensureSets(sets);
				await this.ensureDirectory(undefined, true);
			} catch (err) {
				handleError(err);
			} finally {
				this.setLoading(false);
			}
		}
	}

	public async waitForLoaded(): Promise<boolean> {
		let tries = 0;
		while (this.loading) {
			if (tries < 5) {
				await sleep(1000);
				tries++;
			} else {
				break;
			}
		}

		return this.loading;
	}

	@computed get loading(): boolean {
		return this.loadingCtr > 0;
	}

	@observable public loadingCtr = 0;
	@action
	setLoading(loading: boolean): void {
		if (loading) {
			this.loadingCtr++;
		} else {
			this.loadingCtr--;
		}
	}

	@computed
	public get getActiveCertificates(): BaseCertificate[] {
		return Object.values(this.activeCertificatesObj);
	}

	public getCertificateById(id: number): SubCertificate {
		return this.certificates[id];
	}

	public getBaseCertificateById(id: number): BaseCertificate {
		return this.activeCertificatesObj[id];
	}

	public getBaseCertificateByIdUnfiltered(id: number): BaseCertificate {
		return this.baseCertificatesObj[id];
	}

	private filtersToUrl(filter: CertificateFilter): string {
		// map CertificateFilter to query param
		let url = "";

		// map CertificateFilter to query param
		const _filter = Object.keys(filter).map(key => { return { [key === "dirid" ? "dirId" : key === "dscdid" ? "id" : key]: Array.isArray(filter[key]) ? filter[key] : [ filter[key] ] }; });
		url += `?filters=${JSON.stringify(_filter)}`;

		return url;
	}

	public async fetchStats(filter?: CertificateFilter): Promise<Array<any>> {
		let url = "/api/fp-certificates/certificates/stats";

		if (filter) {
			url += this.filtersToUrl(filter);
		}

		const response = (await (await fetch(url)).json());
		if (response.rows) {
			return response.rows;
		} else {
			throw response;
		}
	}

	public async fetchCertificates(filter?: CertificateFilter, withVersions?: boolean): Promise<{ rows: BaseCertificate[] }> {
		let url = `/api/fp-certificates/certificates/${this.app.ctx.dscid}`;

		const rows = [];

		if (filter) {
			if (filter.id) {
				if (filter.id.length > 1000) {
					const idsToLoad = chunk(filter.id, 1000);
					for await (const ids of idsToLoad) {
						const { rows: rowsChunk } = await this.fetchCertificates({ ...filter, id: ids }, withVersions);
						rows.push(...rowsChunk);
					}
				} else {
					url += this.filtersToUrl(filter);
				}
			} else {
				url += this.filtersToUrl(filter);
			}
		}

		/*if (withDir) {
			url += `${filter ? "&" : "?"}withDir=true`;
		}*/
		if (withVersions) {
			url += `${filter ? "&" : "?"}withVersions=true`;
		}

		const response = (await (await fetch(url)).json());
		if (response.rows) {
			rows.push(...response.rows);
			return {
				rows: rows
			};
		} else {
			throw response;
		}
	}

	private now = moment().valueOf();
	private thisMonth = DateTime.utc().month;
	private thisYear = DateTime.utc().year;
	public getTotalsForSingleSubCertificate(baseCert: BaseCertificate, cert: SubCertificate, date = this.now, month = this.thisMonth): SubCertificate {
		if (!cert) return cert;
		// reset values as they're going to be recomputed
		cert.isValid = false;
		cert.isExpired = false;
		cert.isInGrace = false;
		cert.isInRenewal = false;
		cert.isDue = false;

		if (cert.dateExpiry === "1901-01-01") cert.dateExpiry = "";
		const dateExpiry = new Date(cert.dateExpiry);
		const dateExpiryEOD = new Date(dateExpiry.getFullYear(), dateExpiry.getMonth(), dateExpiry.getDate(), 23, 59, 59);

		if (this.requestedStatusList.includes(cert.dscatidStatus)) return cert;

		const dateExpiryValid = cert.dateExpiry?.length;
		if (!baseCert.expiryIsOn && !dateExpiryValid) {
			if (cert.isCurrentVersion) baseCert.countValid++;
			cert.isValid = true;
			return cert;
		}

		if (cert.dateExpiryRefresh === "1901-01-01") cert.dateExpiryRefresh = "";
		if (cert.dateGrace === "1901-01-01") cert.dateGrace = "";

		// copied from fp-api
		if (baseCert.hasBaseMonth && cert.baseMonth === month && dateExpiry.getFullYear() === this.thisYear) {
			if (cert.isCurrentVersion) baseCert.countDue++;
			cert.isDue = true;
		} else if (baseCert.hasBaseMonth && dateExpiryEOD.valueOf() < date && moment(cert.dateGrace, "YYYY-MM-DD").isValid() && date < moment(cert.dateGrace, "YYYY-MM-DD").valueOf()) {
			if (cert.isCurrentVersion) baseCert.countGrace++;
			cert.isInGrace = true;
		} else if (dateExpiryValid && dateExpiryEOD.valueOf() < date) {
			if (cert.isCurrentVersion) baseCert.countExpired++;
			cert.isExpired = true;
		} else if (dateExpiryValid && moment(cert.dateExpiryRefresh, "YYYY-MM-DD").isValid() && moment(cert.dateExpiryRefresh, "YYYY-MM-DD").valueOf() < date) {
			if (cert.isCurrentVersion) baseCert.countRenewal++;
			cert.isInRenewal = true;
		} else {
			if (cert.isCurrentVersion) baseCert.countValid++;
			cert.isValid = true;
		}

		return cert;
	}

	public getTotalsFromStateCertificate(cert: FpDirClientStateCertificate & { isValid: boolean; isExpired: boolean; isInGrace: boolean; isInRenewal: boolean; isDue: boolean; }, date = this.now, month = this.thisMonth): FpDirClientStateCertificate & { isValid: boolean; isExpired: boolean; isInGrace: boolean; isInRenewal: boolean; isDue: boolean; } {
		// reset values as they're going to be recomputed
		cert.isValid = false;
		cert.isExpired = false;
		cert.isInGrace = false;
		cert.isInRenewal = false;
		cert.isDue = false;

		if (cert.properties.dateExpiry === "1901-01-01") cert.properties.dateExpiry = "";
		const dateExpiry = new Date(cert.properties.dateExpiry);
		const dateExpiryEOD = new Date(dateExpiry.getFullYear(), dateExpiry.getMonth(), dateExpiry.getDate(), 23, 59, 59);

		if (this.requestedStatusList.includes(cert.properties.dscatidStatus)) return cert;

		const dateExpiryValid = cert.properties.dateExpiry?.length;
		if (!cert.properties.expiryIsOn && !dateExpiryValid) {
			cert.isValid = true;
			return cert;
		}

		if (cert.properties.dateExpiryRefresh === "1901-01-01") cert.properties.dateExpiryRefresh = "";
		if (cert.properties.dateGrace === "1901-01-01") cert.properties.dateGrace = "";

		// copied from fp-api
		if (cert.properties.hasBaseMonth && cert.properties.baseMonth === month && dateExpiry.getFullYear() === this.thisYear) {
			cert.isDue = true;
		} else if (cert.properties.hasBaseMonth && dateExpiryEOD.valueOf() < date && moment(cert.properties.dateGrace, "YYYY-MM-DD").isValid() && date < moment(cert.properties.dateGrace, "YYYY-MM-DD").valueOf()) {
			cert.isInGrace = true;
		} else if (dateExpiryValid && dateExpiryEOD.valueOf() < date) {
			cert.isExpired = true;
		} else if (dateExpiryValid && moment(cert.properties.dateExpiryRefresh, "YYYY-MM-DD").isValid() && moment(cert.properties.dateExpiryRefresh, "YYYY-MM-DD").valueOf() < date) {
			cert.isInRenewal = true;
		} else {
			cert.isValid = true;
		}

		return cert;
	}

	private getTotalsForBaseCertificate(baseCert: BaseCertificate): BaseCertificate {
		baseCert = clone(baseCert);

		// set defaults
		baseCert.countDue = 0;
		baseCert.countExpired = 0;
		baseCert.countGrace = 0;
		baseCert.countRenewal = 0;
		baseCert.countValid = 0;

		if (!baseCert.advocates?.length) {
			// filter for certificates (disallowed: requested, notrequested)
			const members = baseCert.members.filter(el => !this.requestedStatusList.includes(el.dscatidStatus) && !el.isArchived);
			members.forEach((cert) => {
				cert = this.getTotalsForSingleSubCertificate(baseCert, cert);
			});
		} else {
			// reset
			baseCert.members = [];
			const allAdvocateCerts: SubCertificate[] = [];

			// get all certificates that are associated
			for (const advocate of baseCert.advocates) {
				const advocateBaseCert = this.getBaseCertificateByIdUnfiltered(advocate);
				if (advocateBaseCert) {
					const advocateCerts = advocateBaseCert.members;
					for (const cert of advocateCerts) {
						allAdvocateCerts.push(this.getTotalsForSingleSubCertificate(advocateBaseCert, cert));
					}
				}
			}

			// group by linkid and try to find out if this person is valid or not!
			for (const certs of Object.values(groupBy(allAdvocateCerts, "linkid"))) {
				const relevantCert = certs.reduce((cert, currentCert) => {
					const item = {
						...currentCert,
						groupid: "G" + FpId.new(),
						dscdid: baseCert.id,
						isAdvocate: true,
						advocateName: currentCert.name,
						isRequired: true,
						id: parseInt(`${Math.random() * 3360248192 + 1000000}`)
					};

					// set "default" if doesnt exist
					if (!cert) {
						cert = item;
					}

					// if current item is requested/notrequested but we have a certificate with validity, replace it
					if ([ CertificateStatus.Verified, CertificateStatus.Unverified ].includes(item.dscatidStatus) && [ CertificateStatus.Requested, CertificateStatus.NotRequested ].includes(cert.dscatidStatus)) {
						cert = item;
					} else {
						// unlimited validity is always preferred
						if (item.isValid && item.dateExpiry == null) {
							cert = item;
						// look for cert with longest validity
						} else if (moment(cert.dateExpiry, "YYYY-MM-DD").isBefore(moment(item.dateExpiry, "YYYY-MM-DD"))) {
							cert = item;
						}
					}

					return cert;
				}, null);

				// create artificial certificate to represent validity
				baseCert.members.push(relevantCert);
			}
		}

		return baseCert;
	}

	public mergeLinkWithCertificate(certificate: SubCertificate): SubCertificate {
		if (certificate.linkid == null) return certificate;

		// resolve contacts
		if ((certificate.linkidtype === "dscaid" || certificate.linkidtype === "dscaid_organization") && certificate.linkid != "0") {
			try {
				const linkId = parseInt(certificate.linkid, 10);
				const contact = this.app.store.contact.getId(linkId);

				if (certificate.linkidtype === "dscaid" && contact.$person) {
					const dateOfBirth = contact.$person.dateOfBirth;
					certificate.age = dateOfBirth !== "1901-01-01"
						? new Date(new Date().valueOf() - new Date(dateOfBirth).valueOf()).getFullYear() - 1970
						: null;

					certificate.email = contact.$person.preferredEmail;
				}

				certificate.$dscaid = contact;
				certificate.isArchived = !contact.isActive;
			} catch (err) {
				// console.error(err);
				return certificate;
			}
		} else if (certificate.linkidtype === "fpvid") {
			try {
				const aircraft = this.app.store.resource.aircraft.getId(parseInt(certificate.linkid, 10));
				certificate.isArchived = aircraft == null;
				certificate.$fpvid = aircraft;
			} catch (err) {
				return certificate;
				// console.error(err);
			}
		} else if (certificate.linkidtype === "fplaid") {
			try {
				const landingfield = this.app.store.landingField.getId(parseInt(certificate.linkid, 10));
				certificate.isArchived = landingfield == null;
				certificate.$fplaid = landingfield;
			} catch (err) {
				return certificate;
				// console.error(err);
			}
		}

		return certificate;
	}

	public upsertSingleSubCertificate(baseCert: BaseCertificate, certificate: SubCertificate, previousCurrentVersion?: number) {
		let cert = clone(certificate);
		if (!cert) return console.error("(STEP 1) MISSING CERT upsertSingleSubCertificate:", baseCert, certificate, previousCurrentVersion);
		const baseCertReference = this.baseCertificatesObj[baseCert.id];

		cert = this.mergeLinkWithCertificate(cert);
		if (!cert) return console.error("(STEP 2) MISSING CERT upsertSingleSubCertificate:", baseCert, certificate, previousCurrentVersion);
		cert = this.getTotalsForSingleSubCertificate(baseCertReference, cert);
		if (!cert) return console.error("(STEP 3) MISSING CERT upsertSingleSubCertificate:", baseCert, certificate, previousCurrentVersion);


		// set the old current version to 
		if (previousCurrentVersion) {
			const previousVersionIdx = baseCertReference.members.findIndex(el => el.id === previousCurrentVersion);
			if (previousVersionIdx > -1) {
				baseCertReference.members[previousVersionIdx].isCurrentVersion = false;
			}
		}

		const existingIdx = baseCertReference.members?.findIndex(el => el?.id === cert?.id);
		if (existingIdx > -1) {
			baseCertReference.members[existingIdx] = cert;
		} else {
			baseCertReference.members.push(cert);
		}

		this.baseCertificatesObj = clone(this.baseCertificatesObj);

		// TODO: Improve this! Do not remap *all*!
		//this.remapCertificates();
	}

	@action.bound
	public async refreshCertificateDataset(filter?: CertificateFilter, providedCerts?: BaseCertificate[], force?: boolean, withDir?: boolean, withVersions?: boolean): Promise<any> {
		// we are passing references, we don't want to modify the original!
		if (filter) filter = Object.keys(clone(filter))
			.filter((k) => filter[k] != null)
			.reduce((a, k) => ({ ...a, [k]: filter[k] }), {});
		try {
			// filter/cache preparation
			let continueFetch = (!filter || providedCerts) || false;
			if (filter) {
				// try to find each filter in fetchCache
				Object.entries(filter).forEach(([ filterType, ids ]) => {
					if (this.fetchCache.has(filterType)) {
						const cache = this.fetchCache.get(filterType);
						if (force) {
							continueFetch = true;
						} else {
							// put id into according filtertype (cache), unless forced
							filter[filterType] = filter[filterType].filter(id => {
								const ts = cache.get(id);
								if (ts + 900000 > Date.now()) {
									return false;
								}
								return true;
							});
							if (!filter[filterType].length) delete filter[filterType];
							else continueFetch = true;
						}
					} else {
						this.fetchCache.set(filterType, new Map());
						continueFetch = true;
					}
				});
			}

			if (!continueFetch && !force) {
				this.setLoading(false);
				return;
			}

			const { rows } = providedCerts ? { rows: providedCerts } : await this.fetchCertificates(filter, withVersions);

			// set fetched ids in cache
			if (filter) {
				Object.entries(filter).forEach(([ filterType, ids ]) => {
					const cache = this.fetchCache.get(filterType);
					(Array.isArray(ids) ? ids : [ ids ]).forEach((id) => {
						cache.set(id, Date.now());
					});
				});
			} else {
				// if no filter is provided, still set the cache
				rows.forEach((baseCert) => {
					const cache = this.fetchCache.get("id");
					if (cache) cache.set(baseCert.id, Date.now());
					else {
						this.fetchCache.set("id", new Map([[ baseCert.id, Date.now() ]]));
					}

					baseCert.members.forEach((cert) => {
						const cache = this.fetchCache.get(cert.linkidtype);
						if (cache) cache.set(+cert.linkid, Date.now());
						else {
							this.fetchCache.set(cert.linkidtype, new Map([[ +cert.linkid, Date.now() ]]));
						}
					});
				});
			}

			// map contacts and aircrafts to certificates
			for (const cert of rows) {
				const members = clone(cert.members);
				cert.members = [];
				for (let i = 0; i < members.length; i++) {
					const newMember = this.mergeLinkWithCertificate(members[i]);
					// may not exist because contact is archived or not active
					if (newMember) cert.members.push(newMember);
				}
			}

			transaction(() => {
				rows.sort((a, b) => {
					if (a.advocates?.length) return 1;
					if (b.advocates?.length && !a.advocates?.length) return -1;

					return 1;
				});

				// set certificates into observable
				// if we load a whole base certificate, replace it entirely;
				if (!filter || (filter && Object.keys(filter).every(e => e === "id"))) {
					rows.forEach((baseCert) => {
						this.baseCertificatesObj[baseCert.id] = this.getTotalsForBaseCertificate(baseCert);
					});
				} else {
					// merge old base certificate with new
					rows.forEach((baseCert) => {
						this.upsertCertificate(baseCert);
					});
				}

				// this triggers a re-render
				this.baseCertificatesObj = clone(this.baseCertificatesObj);
			});


			if (!this.util) {
				await this.ensureDirectory(undefined, filter == null);
			}
		} catch (err) {
			handleError(err);
		}
	}

	@action.bound
	public async editPermissions(baseCertId: number, permissions): Promise<void> {
		const res = await fetch(`/api/fp-certificates/permissions/${baseCertId}`, {
			method: "PUT",
			body: JSON.stringify(permissions),
			headers: {
				"Content-Type": "application/json"
			}
		});
		await res.json();
	}

	@action.bound
	public async saveSet(set: FPSet): Promise<void> {
		const res = await fetch(`/api/fp-certificates/sets/${this.app.ctx.dscid}/${set.id}`, {
			body: JSON.stringify(set),
			method: "PUT",
			headers: {
				"Content-Type": "application/json"
			}
		});
		const json = await res.json();

		if (json.error) return handleError(json);

		await this.fetchSets(null, true);
	}

	@action.bound
	public async deleteBaseCertificate(baseCertId: number, deleteCertificates = false): Promise<boolean> {
		const baseCertificate = this.getBaseCertificateById(baseCertId);
		if (!baseCertificate) return; // safety measure

		if (!this.initialSetLoadComplete) {
			await this.ensureSets();
		}

		if (this.sets.find(s => s.members.some(m => m.dscdid === baseCertificate.id))) {
			alert("Please delete this Base Certificate from all Sets first!");
			return false;
		}

		// masterId advocates are handled specially in sql
		if (!baseCertificate.masterId && this.baseCertificates.find(s => s.advocates?.some(m => m === baseCertificate.id))) {
			alert("Please delete this Base Certificate from all Group Masters first!");
			return false;
		}

		if (this.baseCertificates.find(s => s.masterId === baseCertificate.id)) {
			alert("Please delete all Group Children first!");
			return false;
		}

		try {
			const res = await fetch(`/api/fp-certificates/certificates/${this.app.ctx.dscid}/${baseCertificate.id}?_r=${baseCertificate._r}&deleteCerts=${deleteCertificates.toString()}`, {
				method: "DELETE"
			});

			const json = await res.json();
			if (json.error) throw handleError(json);

			// find base certificate in array and delete
			delete this.baseCertificatesObj[baseCertificate.id];

			this.baseCertificatesObj = clone(this.baseCertificatesObj); // trigger re-render

			if (this.util) this.util.removeNodeByKey(`baseCert:${baseCertId}`);
			this.countTreeNodes();
			this.refreshTree();

			return true;
		} catch (err) {
			handleError(err);
			return false;
		}
	}

	@action.bound
	public async deleteSubCertificate(dscdid: number, id: number, _r: string): Promise<void> {
		try {
			const res = await fetch(`/api/fp-certificates/certificates/sub/${this.app.ctx.dscid}/${dscdid}/${id}?_r=${_r}`, {
				method: "DELETE"
			});
			const json = await res.json();
			if (json.error) return handleError(json);

			// re-load the base certificate
			await this.refreshCertificateDataset({ id: [ dscdid ] }, null, true).catch(handleError);
			this.baseCertificatesObj[dscdid] = {
				...this.baseCertificatesObj[dscdid],
				members: this.baseCertificatesObj[dscdid].members.filter(e => e.id !== id)
			};

			// update tree..
			this.util.updateNodeByKey(this.certToTreeNode(this.getBaseCertificateByIdUnfiltered(dscdid)), `baseCert:${dscdid}`);

			this.countTreeNodes();
			this.refreshTree();
		} catch (err) {
			handleError(err);
		}
	}

	@action.bound
	public upsertCertificate(certificate: BaseCertificate): void {
		if (this.baseCertificatesObj[certificate.id]) {
			// reset members so they don't get appended
			if (certificate.isArchived) this.baseCertificatesObj[certificate.id].members = [];

			this.baseCertificatesObj[certificate.id] = this.getTotalsForBaseCertificate({
				...certificate,
				members: [
					...certificate.members,
					...this.baseCertificatesObj[certificate.id].members.filter(e => !certificate.members.find(cert => cert.id === e.id)),
				]
			});
		} else {
			this.baseCertificatesObj[certificate.id] = this.getTotalsForBaseCertificate(certificate);
		}
	}

	private async getSetsFromAPI(withCerts = false): Promise<{ rows: FPSet[], certificates?: BaseCertificate[]; }> {
		return (await (await fetch(`/api/fp-certificates/sets/${this.app.ctx.dscid}?withCerts=${withCerts.toString()}`)).json());
	}

	@observable public initialSetLoadComplete = false;
	public async fetchSets(sets?: FPSet[], force?: boolean): Promise<void> {
		if (!force && this.initialSetLoadComplete) {
			if (!sets) return;

			this.sets = uniqWith(this.sets.concat(sets), (a, b) => a.id === b.id);
			return;
		}

		await this.waitForLoaded();

		// fetch sets from fp-certificates api
		const { rows: setsFromAPI, certificates } = sets ? { rows: sets, certificates: null } : await this.getSetsFromAPI(!this.initialLoadComplete);

		if (certificates) {
			await this.refreshCertificateDataset(null, certificates);
		}

		// get all existing tags (unique)
		const tags = uniq(setsFromAPI.map(s => s.tagList).flat());
		let tagData = [];
		if (tags.length) {
			// get contacts from all tags
			tagData = this.app.store.contact.getFilter(c => intersection(c.dsttidList, tags).length > 0)
				.map(el => {
					return {
						...el,
						dsttidList: el.dsttidList,
					};
				});
		}

		// merge items from tags or relations with sets
		const newSets = [];

		// ensure all base certificates for set are loaded!
		if (!this.initialLoadComplete) {
			// get all base certificate ids from sets
			const dscdids = setsFromAPI.reduce((ids, set) => {
				// consider all dscdids from relations
				ids.push(...set.relations.filter(e => e.type === "dscdid").map(e => e.dscdid));

				// get every base certificate included in set
				set.members?.forEach((member) => {
					ids.push(member.dscdid);
				});

				return ids;
			}, []);
			await this.refreshCertificateDataset({ id: dscdids });

			/*const idsToFetch = [];
			for (const id of dscdids) {
				if (this.baseCertificatesObj[id] && this.baseCertificatesObj[id].advocates?.length) {
					for (const dscdid of this.baseCertificatesObj[id].advocates) {
						idsToFetch.push(dscdid);
					}
				}
			}
			await this.refreshCertificateDataset({ id: idsToFetch });*/
		}

		const aircraft = this.app.store.resource.aircraft.getAll();
		for await (const set of setsFromAPI) {
			// if we are missing base certificates we don't have perms!
			// set.members = set.members.filter(e => this.baseCertificatesObj[e.dscdid]);
			/*if (!set.members.filter((el => !this.baseCertificatesObj[el.dscdid]))) {
				continue;
			}*/

			set.itemsInSet = [];

			const relevantRelations = set.relations?.filter(r => r.category === "ASSIGNEDTO");

			if (!relevantRelations?.length) {
				const items = set.members.map(e => this.baseCertificatesObj[e.dscdid]).filter(Boolean);

				const includesFpvid = items.some(baseCert => baseCert.linkTo.includes("fpvid"));
				const includesDscaid = items.some(baseCert => baseCert.linkTo.includes("dscaid"));
				const includesDscaidOrg = items.some(baseCert => baseCert.linkTo.includes("dscaid_organization"));
				const includesFplaid = items.some(baseCert => baseCert.linkTo.includes("fplaid"));

				if (includesFpvid) {
					set.itemsInSet = set.itemsInSet.concat(aircraft.map(c => ({ linkId: c.id, linkType: "fpvid", relId: null, relType: "" })));
				}

				if (includesDscaid && !set.tagList.length) {
					set.itemsInSet = set.itemsInSet.concat(
						this.app.store.contact.getAll()
							.filter(e => !e.isCompany)
							.map(c => ({ linkId: c.id, linkType: "dscaid", relId: null, relType: "" }))
					);
				}

				if (includesDscaidOrg && !set.tagList.length) {
					set.itemsInSet = set.itemsInSet.concat(
						this.app.store.contact.getAll()
							.filter(e => e.isCompany)
							.map(c => ({ linkId: c.id, linkType: "dscaid_organization", relId: null, relType: "" }))
					);
				}

				if (includesFplaid && !set.tagList.length) {
					const fplaids = items.flatMap(i => i.members).filter(e => e.linkidtype === "fplaid").map(e => +e.linkid);
					const landingFields = await this.app.store.landingField.getIds(fplaids);
					set.itemsInSet = set.itemsInSet.concat(landingFields.map(fpla => ({ linkId: fpla.id, linkType: "fplaid", relId: null, relType: "" })));
				}

				items.forEach((baseCert) => {
					if (baseCert.advocates?.length) {
						for (const advocate of baseCert.advocates) {
							const advocateCert = this.baseCertificatesObj[advocate];
							if (advocateCert) {
								advocateCert.members.forEach((cert) => {
									if (!set.itemsInSet.find(e => e.linkId === +cert.linkid && e.linkType === cert.linkidtype)) {
										set.itemsInSet.push({
											linkId: +cert.linkid, linkType: cert.linkidtype, relId: null, relType: "group member"
										});
									}
								});
							}
						}
					}
				});
			}

			if (relevantRelations?.length) {
				for (const relation of set.relations) {
					if (relation.fpdirgrp) {
						set.itemsInSet.push(...this.app.members.filter(e =>
							e.grp === relation.fpdirgrp // main relation
								&& (relation.fpdirloc ? e.loc === relation.fpdirloc : true) // additional relation
								&& (relation.fpdirpos ? e.pos === relation.fpdirpos : true) // additional relation
						).map(m => ({ linkId: +m.linkid, linkType: "dscaid", relId: relation.fpdirgrp, relType: "fpdirgrp" })));
					} else if (relation.fpdirloc) {
						set.itemsInSet.push(...this.app.members.filter(e =>
							e.loc === relation.fpdirloc // main relation
								&& (relation.fpdirgrp ? e.grp === relation.fpdirloc : true) // additional relation
								&& (relation.fpdirpos ? e.pos === relation.fpdirpos : true) // additional relation
						).map(m => ({ linkId: +m.linkid, linkType: "dscaid", relId: relation.fpdirloc, relType: "fpdirloc"  })));
					} else if (relation.fpdirpos) {
						set.itemsInSet.push(...this.app.members.filter(e =>
							e.pos === relation.fpdirpos // main relation
								&& (relation.fpdirgrp ? e.grp === relation.fpdirgrp : true) // additional relation
								&& (relation.fpdirloc ? e.loc === relation.fpdirloc : true) // additional relation
						).map(m => ({ linkId: +m.linkid, linkType: "dscaid", relId: relation.fpdirpos, relType: "fpdirpos" })));
					} else if (relation.fpvid) {
						set.itemsInSet.push(({ linkId: relation.fpvid, linkType: "fpvid", relId: relation.fpvid, relType: "fpvid"  }));
					} else if (relation.dscdid) {
						if (this.baseCertificatesObj[relation.dscdid]) {
							set.itemsInSet.push(...this.baseCertificatesObj[relation.dscdid].members.map(e => ({ linkId: +e.linkid, linkType: e.linkidtype, relId: relation.dscdid, relType: "dscdid" })));
						}
					}
				}

				set.itemsInSet = uniqBy(set.itemsInSet, "linkId");
			}

			if (set.tagList.length) {
				const contacts = tagData.filter(t => t.dsttidList.some(e => set.tagList.includes(e)));

				const uniqueIDs = new Set<number>();

				// get all IDs (unique)
				contacts.forEach((contact) => uniqueIDs.add(contact.id));

				// get contact by id and insert them into state
				const uniqueContacts: DirContact[] = [];
				for (const dscaid of Array.from(uniqueIDs)) {
					try {
						const contact = await this.app.store.contact.getId(dscaid);
						if (contact && contact.isActive && contact.$person) uniqueContacts.push(contact);
					} catch (err) {
						// console.error(err);
					}
				}

				const existingIDs = [];
				// delete all IDs that we have at least one certificate of
				set.members.forEach((singleSet) => {
					this.activeCertificatesObj[singleSet.dscdid]?.members
						.map(el => el.linkid)
						.forEach((id) => existingIDs.push(+id));
				});

				for (const contact of uniqueContacts) {
					if (!set.itemsInSet.find(el => el.linkId === contact.id)) {
						set.itemsInSet.push({ linkId: contact.id, linkType: "dscaid", relId: null, relType: "tags" });
					}
				}
				// set.itemsInSet = uniqueContacts.map(c => ({ linkId: c.id, linkType: "dscaid", relId: null, relType: "tags" }));
			}

			newSets.push(set);
		}

		this.sets = newSets;
	}

	public getSetValidityForLink(set: number | FPSet, linkType: string, linkId: string): {
		missing: BaseCertificate[];
		all: Array<SubCertificate>;
		expired: Array<SubCertificate>;
		expires: Array<SubCertificate>;
		infinite: Array<SubCertificate>;
		ok: Array<BaseCertificate>;
		grace: Array<SubCertificate>;
		due: Array<SubCertificate>;
		requested: Array<SubCertificate>;
		notrequested: Array<SubCertificate>;
		required: Array<BaseCertificate>;
		notrequired: Array<BaseCertificate>;
	} {
		set = typeof set === "number" ? this.setsObj[set] : set;
		const stats = { missing: [], all: [], expired: [], expires: [], infinite: [], ok: [], grace: [], due: [], requested: [], notrequested: [], required: [], notrequired: [] };
		for(const setItem of (set?.members ?? [])) {
			const baseCert = this.baseCertificatesObj[setItem.dscdid];
			if (!baseCert) continue;
			const cert = this.certificatesByLink[linkType] && this.certificatesByLink[linkType][linkId] ? this.certificatesByLink[linkType][linkId][setItem.dscdid] : null;
			const isRequired = cert?.isRequired ?? true;
			if (cert) stats.all.push(cert);
			if (!isRequired && !set.strict) {
				stats.notrequired.push(baseCert);
				stats.ok.push(baseCert);
			} else if (cert) {
				if (isRequired) {
					stats.required.push(baseCert);
				} else if (!isRequired) {
					stats.notrequired.push(baseCert);
				}
				if (cert.isExpired) stats.expired.push(cert);
				if (cert.isInRenewal) stats.expires.push(cert);
				if (cert.isInGrace) stats.grace.push(cert);
				if (cert.isDue) stats.due.push(cert);
				if (cert.isValid && !cert.dateExpiry) stats.infinite.push(cert);
				if (cert.isValid && cert.dateExpiry) stats.ok.push(baseCert);
				if (cert.dscatidStatus === CertificateStatus.Requested) stats.requested.push(cert);
				else if (cert.dscatidStatus === CertificateStatus.NotRequested) stats.notrequested.push(cert);
			} else {
				if (isRequired || set.strict) stats.missing.push(baseCert);
			}
		}
		return stats;
	}

	public getSetValidityForLinkAtDate(set: number | FPSet, linkType: string, linkId: string, date?: string): {
		missing: BaseCertificate[];
		all: Array<SubCertificate>;
		expired: Array<SubCertificate>;
		expires: Array<SubCertificate>;
		infinite: Array<SubCertificate>;
		ok: Array<BaseCertificate>;
		grace: Array<SubCertificate>;
		due: Array<SubCertificate>;
		requested: Array<SubCertificate>;
		notrequested: Array<SubCertificate>;
		required: Array<BaseCertificate>;
		notrequired: Array<BaseCertificate>;
		isValid: boolean;
	} {
		set = typeof set === "number" ? this.setsObj[set] : set;
		if (!set) return { missing: [], all: [], expired: [], expires: [], infinite: [], ok: [], grace: [], due: [], requested: [], notrequested: [], required: [], notrequired: [], isValid: undefined };

		const validityDate = moment(date);
		const certs = set.members.map(m => this.baseCertificatesObj[m.dscdid]);
		const validity = certs.reduce((stats, baseCert) => {
			let cert = this.app.store.certificateV3Store.certificatesByLink[linkType]?.[linkId]?.[baseCert.id];
			if (cert?.id) cert = this.getTotalsForSingleSubCertificate(clone(baseCert), clone(cert), validityDate.valueOf(), validityDate.month());

			const isRequired = cert?.isRequired ?? true;
			if (!isRequired) {
				if (cert) stats.all.push(cert);
				stats.notrequired.push(baseCert);
				stats.ok.push(baseCert);
				return stats;
			}

			if (cert) {
				stats.all.push(cert);

				if (isRequired) {
					stats.required.push(baseCert);
				} else if (!isRequired) {
					stats.notrequired.push(baseCert);
				}

				if (cert.isExpired) stats.expired.push(cert);
				if (cert.isInRenewal) stats.expires.push(cert);
				if (cert.isInGrace) stats.grace.push(cert);
				if (cert.isDue) stats.due.push(cert);
				if (cert.isValid && !cert.dateExpiry) stats.infinite.push(cert);
				if (cert.isValid && cert.dateExpiry) stats.ok.push(baseCert);

				if (cert.dscatidStatus === CertificateStatus.Requested) stats.requested.push(cert);
				else if (cert.dscatidStatus === CertificateStatus.NotRequested) stats.notrequested.push(cert);
			} else {
				if (isRequired) stats.missing.push(baseCert);
			}

			return stats;
		}, { missing: [], all: [], expired: [], expires: [], infinite: [], ok: [], grace: [], due: [], requested: [], notrequested: [], required: [], notrequired: [] });

		const isValid = (validity.ok.length + validity.infinite.length + validity.expires.length + validity.grace.length) >= validity.required.length && !validity.missing.length;

		return {
			...validity,
			isValid
		};
	}

	public getValidityForLink(linkType: string, linkId: string, certs: SubCertificate[], date?: string): {
		missing: BaseCertificate[];
		all: Array<SubCertificate>;
		expired: Array<SubCertificate>;
		expires: Array<SubCertificate>;
		infinite: Array<SubCertificate>;
		ok: Array<BaseCertificate>;
		grace: Array<SubCertificate>;
		due: Array<SubCertificate>;
		requested: Array<SubCertificate>;
		notrequested: Array<SubCertificate>;
		required: Array<BaseCertificate>;
		notrequired: Array<BaseCertificate>;
		isValid: boolean;
	} {
		if (!this.certificatesByLink[linkType] || !this.certificatesByLink[linkType][linkId]) {
			return null;
		}

		const validityDate = moment(date);
		const validity = certs.reduce((stats, cert) => {
			const baseCert = this.baseCertificatesObj[cert.dscdid];
			if (cert?.id && baseCert) cert = this.getTotalsForSingleSubCertificate(clone(baseCert), clone(cert), validityDate.valueOf(), validityDate.month());

			const isRequired = cert?.isRequired ?? true;
			if (!isRequired) {
				if (cert) stats.all.push(cert);
				stats.notrequired.push(baseCert);
				stats.ok.push(baseCert);
				return stats;
			}

			if (cert) {
				stats.all.push(cert);

				if (isRequired) {
					stats.required.push(baseCert);
				} else if (!isRequired) {
					stats.notrequired.push(baseCert);
				}

				if (cert.isExpired) stats.expired.push(cert);
				if (cert.isInRenewal) stats.expires.push(cert);
				if (cert.isInGrace) stats.grace.push(cert);
				if (cert.isDue) stats.due.push(cert);
				if (cert.isValid && !cert.dateExpiry) stats.infinite.push(cert);
				if (cert.isValid && cert.dateExpiry) stats.ok.push(baseCert);

				if (cert.dscatidStatus === CertificateStatus.Requested) stats.requested.push(cert);
				else if (cert.dscatidStatus === CertificateStatus.NotRequested) stats.notrequested.push(cert);
			} else {
				if (isRequired) stats.missing.push(baseCert);
			}

			return stats;
		}, { missing: [], all: [], expired: [], expires: [], infinite: [], ok: [], grace: [], due: [], requested: [], notrequested: [], required: [], notrequired: [] });

		const isValid = (validity.ok.length + validity.infinite.length + validity.expires.length + validity.grace.length) >= validity.required.length && !validity.missing.length;

		return {
			...validity,
			isValid
		};
	}

	public getValidityForLinkBC(linkType: string, linkId: string, certs: BaseCertificate[], date?: string): {
		missing: BaseCertificate[];
		all: Array<SubCertificate>;
		expired: Array<SubCertificate>;
		expires: Array<SubCertificate>;
		infinite: Array<SubCertificate>;
		ok: Array<BaseCertificate>;
		grace: Array<SubCertificate>;
		due: Array<SubCertificate>;
		requested: Array<SubCertificate>;
		notrequested: Array<SubCertificate>;
		required: Array<BaseCertificate>;
		notrequired: Array<BaseCertificate>;
		isValid: boolean;
	} {
		if (!this.certificatesByLink[linkType] || !this.certificatesByLink[linkType][linkId]) {
			return null;
		}

		const validityDate = moment(date);
		const validity = certs.reduce((stats, baseCert) => {
			let cert = this.app.store.certificateV3Store.certificatesByLink[linkType]?.[linkId]?.[baseCert.id];
			if (cert?.id) cert = this.getTotalsForSingleSubCertificate(clone(baseCert), clone(cert), validityDate.valueOf(), validityDate.month());

			const isRequired = cert?.isRequired ?? true;
			if (!isRequired) {
				if (cert) stats.all.push(cert);
				stats.notrequired.push(baseCert);
				stats.ok.push(baseCert);
				return stats;
			}

			if (cert) {
				stats.all.push(cert);

				if (isRequired) {
					stats.required.push(baseCert);
				} else if (!isRequired) {
					stats.notrequired.push(baseCert);
				}

				if (cert.isExpired) stats.expired.push(cert);
				if (cert.isInRenewal) stats.expires.push(cert);
				if (cert.isInGrace) stats.grace.push(cert);
				if (cert.isDue) stats.due.push(cert);
				if (cert.isValid && !cert.dateExpiry) stats.infinite.push(cert);
				if (cert.isValid && cert.dateExpiry) stats.ok.push(baseCert);

				if (cert.dscatidStatus === CertificateStatus.Requested) stats.requested.push(cert);
				else if (cert.dscatidStatus === CertificateStatus.NotRequested) stats.notrequested.push(cert);
			} else {
				if (isRequired) stats.missing.push(baseCert);
			}

			return stats;
		}, { missing: [], all: [], expired: [], expires: [], infinite: [], ok: [], grace: [], due: [], requested: [], notrequested: [], required: [], notrequired: [] });

		const isValid = (validity.ok.length + validity.infinite.length + validity.expires.length + validity.grace.length) >= validity.required.length && !validity.missing.length;

		return {
			...validity,
			isValid
		};
	}

	@action.bound
	public async deleteSet(setId: number): Promise<void> {
		try {
			// check if set exists
			const set = this.sets.find(el => el.id === setId);
			if (!set) return;

			const res = await fetch(`/api/fp-certificates/sets/${this.app.ctx.dscid}/${set.id}?_r=${set._r}`, {
				method: "DELETE",
				headers: {
					"Content-Type": "application/json"
				}
			});

			const json = await res.json();
			if (json.error) return handleError(json);

			// find set in array and delete it
			const idx = this.sets.findIndex(el => el.id === setId);
			if (idx > -1) this.sets.splice(idx, 1);

			// this.refreshSetsInTree();
		} catch (err) {
			handleError(err);
		}
	}

	isAllowedBypass() {
		return this.app.ctx.isSuperUser || this.app.ctx.hasRole("dscertificatemanager");
	}

	isAllowed(baseCertId: number, scope: "base_cert" | "cert", action: "add" | "add_version" | "add_others" | "edit" | "delete" | "clone" | "verify" | "read", item?: BaseCertificate | SubCertificate): boolean {
		if (this.isAllowedBypass()) return true;

		const baseCert = this.baseCertificatesObj[baseCertId];
		if (!baseCert) return false;

		let viablePermissions = baseCert.permissions ?? [];

		// check if user is reading own certificates
		if (action === "read" && baseCert.everyoneCanRead && (baseCert.members.find(e => +e.linkid === this.app.ctx.dscaid && e.linkidtype === "dscaid") || (item && +item.linkid === this.app.ctx.dscaid && item.linkidtype === "dscaid"))) return true;

		// normal perm checks down below
		const flag = this.app.ctx.getPermission("dscdid", baseCert.id, { policies: viablePermissions.filter(e => e.principal) });
		if (!flag) return false;

		// if the user is only allowed to read sets, he can't do anything else
		if (flag === getPermissionTypeData("dscdid").required[CertificatePermission.ReaderSetOnly]) return false;

		// consider permissions from certificates this certificate is an advocate from!
		const advocates = this.baseCertificates.filter(b => b.advocates?.includes(baseCertId));
		for (const advocate of advocates) {
			if (advocate.permissions) viablePermissions = viablePermissions.concat(advocate.permissions);
		}

		if (action === "read") return baseCert.everyoneCanRead || this.isAllowedBitmaskCheck(flag, CertificatePermission.OwnReader);

		if (scope === "cert") {
			if ([ "add", "edit", "add_version", "add_others" ].includes(action)) {
				// if the certificate is his own => check for OwnEditor
				if (item && item.linkidtype === "dscaid" && item.linkid === this.app.ctx.dscaid.toString()) {
					return this.isAllowedBitmaskCheck(flag, CertificatePermission.OwnEditor);
				} else {
					// he can add if he is own editor - but only to himself!
					if (action === "add") {
						return this.isAllowedBitmaskCheck(flag, CertificatePermission.OwnEditor);
					}

					return this.isAllowedBitmaskCheck(flag, CertificatePermission.Editor);
				}
			} else if (action === "delete") {
				return this.isAllowedBitmaskCheck(flag, CertificatePermission.Delete);
			} else if (action === "verify") {
				return this.isAllowedBitmaskCheck(flag, CertificatePermission.Verify);
			}
		} else if (scope === "base_cert") {
			if ([ "clone", "edit", "delete" ].includes(action)) {
				return this.isAllowedBitmaskCheck(flag, CertificatePermission.Manager);
			} else if (action === "add") {
				return this.isAllowedBypass();
			}
		}

		return false;
	}

	isAllowedBitmaskCheck(flag: number, lvl: number): boolean {
		return ((flag & lvl) === lvl);
	}

	async isAllowedDirectory(activeDirNode): Promise<boolean> {
		if (this.isAllowedBypass()) return true;
		if (!activeDirNode) return false;

		if (!this.initialSetLoadComplete) {
			await this.ensureSets();
		}

		const linkType = activeDirNode.kind === FpDirNodeKind.Group
			? "fpdirgrp"
			: activeDirNode.kind === FpDirNodeKind.Location
				? "fpdirloc"
				: activeDirNode.kind === FpDirNodeKind.Position
					? "fpdirpos"
					: null;

		// try to find all sets that are related to the linkId through relations
		const viableSets = this.sets.filter(s => s.relations?.find(r => {
			return activeDirNode.id === r[linkType];
		}));

		if (viableSets.length) return true;
		else return false;
	}

	getExpiryStatus = (cert: SubCertificate): string => {
		if (cert.isValid) return "success";
		else if (cert.isDue) return "info";
		else if (cert.isInGrace) return "deeporange";
		else if (cert.isExpired) return "danger";
		else if (cert.isInRenewal) return "warning";
		else if (cert.dscatidStatus === CertificateStatus.Requested) return "purple";
		else if (cert.dscatidStatus === CertificateStatus.NotRequested) return "muted";
		else return null;
	};

	getWorkflowStatus = (cert: SubCertificate | { dscatidStatus: CertificateStatus }): string => {
		if (cert.dscatidStatus === CertificateStatus.Verified) return "success"; // verified
		else if (cert.dscatidStatus === CertificateStatus.Unverified) return "warning"; // unverified
		else if (cert.dscatidStatus === CertificateStatus.Requested) return "purple"; // requested
		else if (cert.dscatidStatus === CertificateStatus.NotRequested) return "muted"; // not requested
		else return null;
	};

	getVersionStyling = (cert: SubCertificate): [ string, string ] => {
		const workflow = this.getWorkflowStatus(cert);
		const expiry = this.getExpiryStatus(cert);

		if (!expiry) return [ workflow, workflow ];
		if (!workflow) return [ expiry, expiry ];

		return [ expiry, workflow ];
	}

	getVersionStylingText = (cert: SubCertificate): string => {
		let expiryStatus = "Invalid";
		if (cert.isValid) expiryStatus = "Valid";
		else if (cert.isDue) expiryStatus = "Due";
		else if (cert.isInGrace) expiryStatus = "In Grace";
		else if (cert.isExpired) expiryStatus = "Expired";
		else if (cert.isInRenewal) expiryStatus = "In Renewal";
		else if (cert.dscatidStatus === CertificateStatus.Requested) expiryStatus = "Requested";
		else if (cert.dscatidStatus === CertificateStatus.NotRequested) expiryStatus = "Not Requested";

		let requiredStatus = "";
		if (cert.isRequired) requiredStatus = "Required";
		else requiredStatus = "Not Required";

		return `${expiryStatus}, ${requiredStatus}`;
	}

	@action.bound async loadDirectoryItems(dir?: any): Promise<DirectoryNode[]> {
		let certDirectory = dir?.tree;
		if (!certDirectory) {
			// abort if no directory found
			if (this.app.customer.fpdir.certificate == null) return;

			this.directoryId = this.app.customer.fpdir.certificate;
			certDirectory = (await (await fetch(`/api/dir/trees/${this.app.ctx.dscid}/${this.directoryId}`)).json()).tree;
		} else {
			this.directoryId = dir.directory_id;
		}

		// certificates module: only groups are relevant
		const directoryRootElement = certDirectory.find(e => e.type === "SYSTEM" && e.kind === "GROUP");
		this.defaultDirectoryParentId = directoryRootElement.id;
		certDirectory = directoryRootElement?.children ?? [];

		// get all root objects from certificate directory
		const cleanedCertDir = certDirectory.reduce((curr, node) => {
			if (!node.data.parentid) curr.push(node);
			return curr;
		}, []);

		// get all root objects from main directory and combine it with items from the certificate directory
		const generateItems = (items): TreeNode[] => {
			return (items ?? []).filter(el => el.kind === "GROUP" && el.type.startsWith("dscatid:") && !el.data?.externalIds?.dsuid).map((el) => {
				const sysCat = this.app.store.systemCategory.getId(toNumber(el.type.split(":")[1]));
				return {
					...el,
					typeSysCat: sysCat.idName,
					children: [ ...generateItems(el.children), ...certDirectory.filter(d => d.data.parentid && d.data.parentid === el.id) ]
				};
			});
		};

		return [ ...cleanedCertDir, ...(this.app.store.fpDir.directory.getUserTree() ? generateItems(this.app.store.fpDir.directory.getUserTree().tree.find(e => e.type === "SYSTEM" && e.kind === "GROUP")?.children) : []) ];
	}

	@observable public initialDirLoadComplete = false;

	@action
	private async generateTree(directoryItems: DirectoryNode[]): Promise<void> {
		if (!this.util) {
			this.initialDirLoadComplete = true;
			this.util = new TreeUtil<TreeNode, string>(await this.generateTreeNodes(directoryItems), {
				getChildren: e => e.children,
				getKey: e => e.id,
				compare: (a, b) => {
					if (a.type === "DASHBOARD" && b.type !== "DASHBOARD") return -1;
					if (a.type !== "DASHBOARD" && b.type === "DASHBOARD") return 1;

					if (a.type === "CERTIFICATE_MASTER_NODE" && b.type !== "CERTIFICATE_MASTER_NODE") return -1;
					if (a.type !== "CERTIFICATE_MASTER_NODE" && b.type === "CERTIFICATE_MASTER_NODE") return 1;

					if (a.type === "SETS" && b.type !== "SETS") return -1;
					if (a.type !== "SETS" && b.type === "SETS") return 1;

					if (a.type === "CLASSROOM_TRAININGS" && b.type !== "CLASSROOM_TRAININGS") return -1;
					if (a.type !== "CLASSROOM_TRAININGS" && b.type === "CLASSROOM_TRAININGS") return 1;

					// sort by name
					if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
					if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
				}
			});
		} else {
			this.util.tree = await this.generateTreeNodes(directoryItems);
		}

		// delete "Unassigned" if its empty!
		const unassignedItem = this.util.findByProperty("name", "Unassigned");
		if (unassignedItem && !unassignedItem.children.length) this.util.removeNodeByKey(unassignedItem.id);

		this.countTreeNodes();
		this.refreshTree(true);
	}

	public certToTreeNode(cert: BaseCertificate): TreeNode {
		const realMembers = this.getBaseCertificateById(cert.id)?.members ?? []; // we do this because "cert" might include archived certificates!
		return {
			...cert,
			expanded: false,
			children: [],
			id: `baseCert:${cert.id}`,
			pureId: cert.id,
			name: cert.name,
			active: false,
			type: "CERTIFICATE",
			countTotal: realMembers.length,
			countRenewal: cert.countRenewal,
			countDue: cert.countDue,
			countExpired: cert.countExpired,
			countGrace: cert.countGrace,
			countValid: cert.countValid,
			view: {
				module: "certificates",
				type: "dscdid",
				id: cert.id.toString()
			}
		} as TreeNode;
	}

	@action.bound certificatesToTreeNodes(dirid: number): TreeNode[] {
		return this.getCertificates({ dirid: [ dirid ] }, true).filter(cert => (this.isAllowed(cert.id, "cert", "read") && cert.members.length) || this.isAllowed(cert.id, "cert", "add") || this.isAllowedBypass()).map((cert) => {
			return this.certToTreeNode({ ...cert, members: cert.members.filter(e => this.isAllowed(e.id, "cert", "read", e)) });
		});
	}

	private getRemappedDirectory(): TreeUtil<TreeNode, number> {
		const dir = this.app.store.fpDir.directory.getUserTree();

		const mapDirNode = (node) => {
			node = clone(node);
			if (node.parentid === 0) {
				if (node.kind === FpDirNodeKind.Group) {
					node.name = "Groups";
					node.id = "set:groups";
				} else if (node.kind === FpDirNodeKind.Location) {
					node.name = "Locations";
					node.id = "set:locations";
				} else if (node.kind === FpDirNodeKind.Position) {
					node.name = "Positions";
					node.id = "set:positions";
				}
			}

			return {
				children: node.children.map(n => mapDirNode(n)),
				id: `dir:${node.id}`,
				pureId: node.id,
				name: node.name,
				active: false,
				type: "RELATION_FOLDER",
				originalType: node.type,
				kind: node.kind,
				expanded: false,
				parentid: node.parentid
			};
		};

		return dir.map((node) => {
			return mapDirNode(node);
		}, {
			key: "id",
			children: "children"
		});
	}

	@action.bound async setsToTreeNodes(): Promise<TreeNode[]> {
		const dir = this.getRemappedDirectory();

		// create basic new tree with 'Directory' and 'Fleet', 'Unassigned' will be created later on
		const tree = new TreeUtil<TreeNode, string>([{
			expanded: false,
			children: cloneDeep(dir.tree.filter(e => e.kind !== FpDirNodeKind.Security)),
			id: "set:directory",
			pureId: -991,
			name: "Directory",
			active: false,
			type: "FOLDER",
		}, {
			id: "set:fleet",
			pureId: -994,
			name: "Fleet",
			active: false,
			type: "FOLDER",
			children: this.generateFleet(),
			expanded: false
		}], {
			getChildren: e => e.children,
			getKey: e => e.id,
			compare: (first, second) => {
				return first.name.localeCompare(second.name);
			}
		});
		const dirMap: Record<string, TreeNode> = {};
		let didBuildTree = false;
		const preBuildTree = () => {
			if (didBuildTree) return;
			tree.walk((info) => {
				dirMap[info.node.id] = info.node;
			});
			didBuildTree = true;
		};
		// insert set into directory
		const insertIntoDir = (setNode: TreeNode, dirLink: Relation): void => {
			preBuildTree();
			const grp = dirLink.fpdirgrp ? dirMap[`dir:${dirLink.fpdirgrp}` as any] : null;
			const loc = dirLink.fpdirloc ? dirMap[`dir:${dirLink.fpdirloc}` as any] : null;
			const pos = dirLink.fpdirpos ? dirMap[`dir:${dirLink.fpdirpos}` as any] : null;
			if (grp) {
				grp.children.push({
					...setNode,
					id: setNode.id + "_grp_" + grp.id
				});
			}
			if (loc) {
				loc.children.push({
					...setNode,
					id: setNode.id + "_loc_" + loc.id
				});
			}
			if (pos) {
				pos.children.push({
					...setNode,
					id: setNode.id + "_pos_" + pos.id
				});
			}
			return;
		};

		// insert set into fleet license endorsement
		const insertIntoLE = (setNode: TreeNode, leLink: Relation) => {
			preBuildTree();
			dirMap[`le:${leLink.licenseEndorsement}`]?.children.push({
				...setNode,
				id: setNode.id + "_le_" + leLink.licenseEndorsement
			});
		};
		const items = this.sets.map((set) => {
			const dscaidInvalid = new Set<number>();
			for (const item of set.itemsInSet) {
				const validity = this.getSetValidityForLink(set, item.linkType, item.linkId.toString());
				const isValid = (validity.ok.length + validity.infinite.length + validity.expires.length + validity.grace.length) >= validity.required.length;
				const isInvalid = validity.missing.length || validity.requested.length || validity.notrequested.length || validity.due.length || validity.expired.length || !isValid;
				if (isInvalid) {
					dscaidInvalid.add(item.linkId);
				}
			}
			const setNode = {
				...set,
				expanded: false,
				children: [],
				members: set.members,
				id: `set:${set.id}`,
				pureId: set.id,
				name: set.name,
				active: false,
				type: "SET",
				set: set.id,
				countValid: set.itemsInSet.length - dscaidInvalid.size,
				countInvalid: dscaidInvalid.size,
				view: {
					module: "sets",
					id: set.id.toString()
				}
			};

			// check if set is assigned to directory
			const dirLink = set.relations.filter(e => e.category === "ASSIGNEDTO" && [ "fpdirlink", "fpdirgrp", "fpdirloc", "fpdirpos" ].includes(e.type));
			if (dirLink.length) {
				for (const link of dirLink) {
					insertIntoDir(setNode, link);
				}
			}

			// check if set is assigned to license endorsement
			const fpvidLink = set.relations.filter(e => e.category === "ASSIGNEDFOR" && e.type === "licenseEndorsement");
			if (fpvidLink.length) {
				for (const link of fpvidLink) {
					insertIntoLE(setNode, link);
				}
			}
			if (!dirLink && !fpvidLink) {
				return setNode;
			}
		});
		// merge DIRECTORY to our new sets directory clone
		/*const grpChildren = tree.findKey("set:groups").children;
			const locChildren = tree.findKey("set:locations").children;
			const posChildren = tree.findKey("set:positions").children;
	
			// insert locations, groups and children into tree
			dir.map((node) => {
				// only insert root nodes
				if (node.parentid == 0) {
					if (node.kind === FpDirNodeKind.Group) {
						grpChildren.push(...node.children);
					} else if (node.kind === FpDirNodeKind.Location) {
						locChildren.push(...node.children);
					} else if (node.kind === FpDirNodeKind.Position) {
						posChildren.push(...node.children);
					}
				}
	
				return node;
			}, {
				key: "id",
				children: "children"
			});*/

		if (items.filter(Boolean).length) {
			return [ ...tree.tree, {
				expanded: false,
				children: items.filter(Boolean),
				id: "set:unassigned",
				pureId: -994,
				name: "Unassigned",
				active: false,
				type: "FOLDER",
			}];
		} else {
			return tree.tree;
		}
	}

	private generateFleet(): TreeNode[] {
		const nodes: TreeNode[] = [];

		const aircrafts = this.app.store.resource.aircraft.getAll();

		const nest = function (seq, keys) {
			if (!keys.length) {
				return seq;
			}
			const first = keys[0];
			const rest = keys.slice(1);
			return mapValues(groupBy(seq, first), function (value) {
				return nest(value, rest);
			});
		};

		const t = nest(aircrafts, [ "$fpdbvmid.vehicleSubType", "$fpdbvmid.vehicleDesignApprovalHolder", "$fpdbvmid.licenceEndorsement" ]);
		const subtypeClientCategory = ClientCategoryUtil.byEnum(FpApi.Resource.AircraftType);
		for (const subtype in t) {
			const subTypeNode = {
				expanded: false,
				type: "FOLDER",
				id: `set:fleet:${subtype}`,
				//kind: "FLEET_SUBTYPE",
				name: subtypeClientCategory.getLabel(subtype as FpApi.Resource.AircraftType),
				children: []
			};
			for(const manufa in t[subtype]) {
				const manufaNode = {
					expanded: false,
					type: "FOLDER",
					id: `set:fleet:${subtype}_${manufa}`,
					//kind: "FLEET_MANUFACTURER",
					name: manufa,
					children: []
				};
				for(const model in t[subtype][manufa]) {
					const modelNode = {
						expanded: false,
						type: "RELATION_FOLDER",
						//kind: "FLEET_MODEL",
						id: `le:${model}`,
						name: model,
						children: [] // sets here!
					};

					manufaNode.children.push(modelNode);
				}

				subTypeNode.children.push(manufaNode);
			}

			nodes.push(subTypeNode);
		}

		return nodes;
	}

	@action.bound generateTreeNodeChildren(nodes: DirectoryNode[]): TreeNode[] {
		return nodes.length ? nodes.map((node) => {
			const children = [ ...this.generateTreeNodeChildren(node.children), ...this.certificatesToTreeNodes(node.id) ];

			const { countRenewal, countTotal, countDue, countExpired, countGrace, countValid } = this.doCountTreeNodes(children);
			return {
				...node,
				expanded: false,
				active: false,
				children,
				id: `cat:${node.id}`,
				pureId: node.id,
				name: node.name,
				type: node.type === "DIRECTORY_LINK" ? "dscatid:-1" : node.type,
				countRenewal,
				countTotal,
				countDue,
				countExpired,
				countGrace,
				countValid,
				view: {
					module: "certificates",
					type: "dirid",
					id: node.id.toString()
				}
			};
		}) : [];
	}

	public async generateSetNode(): Promise<TreeNode> {
		return {
			type: "SETS",
			name: "Sets",
			expanded: false,
			children: this.initialSetLoadComplete ? await this.setsToTreeNodes() : [{
				type: "PLACEHOLDER",
				name: "Loading...",
				expanded: false,
				id: "SETS_PLACEHOLDER",
				pureId: -201,
				children: []
			}],
			id: "-200",
			pureId: -200,
			view: {
				module: "sets"
			}
		};
	}

	private async generateTreeNodes(nodes: DirectoryNode[]): Promise<TreeNode[]> {
		// [2023-09-15] RL: Hide Dashboard for now, its useless
		/*{
			type: "DASHBOARD",
			name: "Dashboard",
			expanded: false,
			active: this.util?.findByProperty("active", true) == null,
			children: [],
			id: "-100",
			pureId: -100,
			view: {
				module: "dashboard"
			}
		},*/

		return [{
			type: "CERTIFICATE_MASTER_NODE",
			name: "Certificates",
			expanded: false,
			children: Array.isArray(nodes) && nodes.length ? this.generateTreeNodeChildren(nodes.filter(el => el.type !== "CERTIFICATE_SET")) : [],
			id: "-400",
			pureId: -400,
			view: {
				module: "certificates"
			}
		},
		{
			type: "CLASSROOM_TRAININGS",
			name: "Classroom Trainings",
			expanded: false,
			children: [],
			id: "-300",
			pureId: -300,
			view: {
				module: "classroom_trainings"
			}
		},
		await this.generateSetNode(),
		...(this.isAllowedBypass() ? [{
			type: "SETTINGS",
			name: "Settings",
			expanded: false,
			children: [{
				type: "EXPIRY_NOTF_RULESETS",
				name: "Expiry Notification Rulesets",
				children: [],
				expanded: false,
				id: "expiryNotificationsRuleset",
				pureId: -5000200,
				view: {
					module: "settings",
					id: "expiryNotificationsRuleset"
				}
			}, {
				type: "REQUEST_NOTF_RULESETS",
				name: "Request Notification Rulesets",
				children: [],
				expanded: false,
				id: "requestNotificationsRuleset",
				pureId: -5000201,
				view: {
					module: "settings",
					id: "requestNotificationsRuleset"
				}
			}, {
				type: "STATUS",
				name: "Status",
				children: [],
				expanded: false,
				id: "status",
				pureId: -5000202,
				view: {
					module: "settings",
					id: "status"
				}
			}, {
				type: "RASTYPES",
				name: "Regulation & Standard Types",
				children: [],
				expanded: false,
				id: "rasTypes",
				pureId: -5000203,
				view: {
					module: "settings",
					id: "rasTypes"
				}
			}, {
				type: "ELEARNING",
				name: "CBT",
				children: [],
				expanded: false,
				id: "elearning",
				pureId: -5000204,
				view: {
					module: "settings",
					id: "elearning"
				}
			}],
			id: "-500",
			pureId: -500
		}] : [])
		];
	}

	private doCountTreeNodes = (nodes: TreeNode[]): { countTotal: number, countRenewal: number, countDue: number, countExpired: number, countGrace: number, countValid: number } => nodes.reduce((sum, item) => {
		// if an item has children its not a certificate and can not have properties "countRenewal" and "countTotal"
		if (item.children.length) {
			const { countTotal, countRenewal, countDue, countExpired, countGrace, countValid } = this.doCountTreeNodes(item.children);
			sum.countRenewal += countRenewal;
			sum.countTotal += countTotal;
			sum.countDue += countDue;
			sum.countExpired += countExpired;
			sum.countGrace += countGrace;
			sum.countValid += countValid;
		} else {
			sum.countRenewal += item.countRenewal;
			sum.countTotal += item.countTotal;
			sum.countDue += item.countDue;
			sum.countExpired += item.countExpired;
			sum.countGrace += item.countGrace;
			sum.countValid += item.countValid;
		}

		return sum;
	}, { countRenewal: 0, countTotal: 0, countDue: 0, countExpired: 0, countGrace: 0, countValid: 0 });

	@action.bound public countTreeNodes(): void {
		this.util.walk((e) => {
			if (e.node.type !== "SET" && e.node.type !== "CERTIFICATE") {
				const { countRenewal, countTotal, countDue, countExpired, countGrace, countValid } = this.doCountTreeNodes(e.node.children);

				Object.assign(e.node, {
					countRenewal,
					countTotal,
					countDue,
					countExpired,
					countGrace,
					countValid
				}, e.node.id);
			} else if (e.node.type === "SET") {
				const baseCertificatesIds = e.node.members.map(el => el.dscdid);
				const baseCertificatesInSet = baseCertificatesIds.map(e => this.activeCertificatesObj[e]).filter(e => e);

				// grab all unique dscaids that can be found in the base certificates of a set
				const uniqueIDs = new Set();
				baseCertificatesInSet.map((baseCert) => {
					baseCert.members.map((cert) => {
						if (cert.linkidtype === "dscaid") {
							uniqueIDs.add(cert.$dscaid?.id);
						}
					});
				});

				Object.assign(e.node, {
					countTotal: uniqueIDs.size
				}, e.node.id);
			} else if (e.node.type === "CERTIFICATE") {
				const cert = this.getBaseCertificateById(e.node.pureId);

				Object.assign(e.node, {
					countTotal: cert?.members?.length ?? 0,
					countRenewal: cert?.countRenewal ?? 0,
					countDue: cert?.countDue ?? 0,
					countExpired: cert?.countExpired ?? 0,
					countGrace: cert?.countGrace ?? 0,
					countValid: cert?.countValid ?? 0
				}, e.node.id);
			}
		});
	}

	@action.bound public refreshTree(sort = false): void {
		if (!this.util) return;

		if (sort) this.util.sort();
		if (this.currentView?.module === "sets" && this.currentView?.id) {
			void this.setTreeActive(this.currentView);
		} else {
			this.util = this.util.cloneShallow();
		}
	}

	@action.bound public refreshSetsInTree(): void {
		if (this.util) {
			const node = this.util.findByProperty("type", "SETS");
			if (node) {
				void this.generateSetNode()
					.then((node) => {
						// find our master node and update the children (the actual sets)
						this.util.updateNodeByKey({
							...node,
							expanded: this.util.findKey("-200").expanded
						}, node.id);
						/*Object.assign(node, {
							expanded: this.util.findKey("-200").expanded
						}, node.id);*/
						this.countTreeNodes();
						this.refreshTree(true);
					})
					.catch(handleError);
			}
		}
	}

	public filterByLinkType(linktype: "dscaid" | "dscaid_organization" | "fpvid" | "fplaid", linkids: number[]): BaseCertificate[] {
		return Object.values(this.certificates).reduce((_certs, curr) => {
			if (!curr.isCurrentVersion) return _certs;

			const eligable = curr.linkidtype === linktype && linkids.includes(+curr.linkid);

			if (eligable) {
				const baseCert = this.baseCertificatesObj[curr.dscdid];

				const item = _certs.find(e => e.id === baseCert.id);
				if (item) {
					item.members.push(curr);
				} else {
					_certs.push({
						...baseCert,
						members: [ curr ]
					});
				}
			}

			return _certs;
		}, []);
	}

	public getCertificates(filter: CertificateFilter, includeInactive?: boolean): BaseCertificate[] {
		let certs = [];

		if (filter && Object.keys(filter).length) {
			if (filter.id) {
				for (const id of filter.id) {
					if (includeInactive) {
						if (this.baseCertificatesObj[id]) certs.push(this.baseCertificatesObj[id]);
					} else {
						if (this.activeCertificatesObj[id]) certs.push(this.activeCertificatesObj[id]);
					}
				}
			}

			if (filter.dscdcid) {
				certs = [ ...certs, ...(includeInactive ? this.baseCertificates : this.getActiveCertificates).filter(el => filter.dscdcid.includes(el.dscdcid)) ];
			}

			if (filter.dscaid) {
				certs = [ ...certs, ...this.filterByLinkType("dscaid", filter.dscaid) ];
			}

			if (filter.dscaid_organization) {
				certs = [ ...certs, ...this.filterByLinkType("dscaid_organization", filter.dscaid_organization) ];
			}

			if (filter.fpvid) {
				certs = [ ...certs, ...this.filterByLinkType("fpvid", filter.fpvid) ];
			}

			if (filter.fplaid) {
				certs = [ ...certs, ...this.filterByLinkType("fplaid", filter.fplaid) ];
			}

			if (filter.dirid) {
				if (filter.dscaid) {
					certs = certs.filter(el => filter.dirid.includes(el.dirId));
				} else {
					certs = [ ...certs, ...(includeInactive ? this.baseCertificates : this.getActiveCertificates).filter(el => filter.dirid.includes(el.dirId)) ];
				}
				// certs = [ ...certs, ...this.getActiveCertificates.filter(el => filter.dirid.includes(el.dirId)) ];
			}

			if (filter.versions) {
				let baseCert = this.baseCertificatesObj[filter.versions.dscdid];

				if (baseCert) {
					baseCert = {
						...baseCert,
						members: baseCert.members.filter(el => el.groupid === filter.versions.groupid && !el.isdeleted && el.linkid === filter.versions.linkid)
					};

					certs = [ ...certs, baseCert ];
				}
			}
		} else {
			certs = includeInactive ? this.baseCertificates : this.getActiveCertificates;
		}

		return certs;
	}

	public certificatesToStats = (certs: Partial<SubCertificate>[]) => {
		const output: Array<{
			expiry: string;
			workflow: string;
			required: boolean;
			total: number;
		}> = [];
		const _temp = {};

		for (const cert of certs) {
			let expiryStatus = "none";
			if (!this.requestedStatusList.includes(cert.dscatidStatus)) {
				if (cert.isDue) {
					expiryStatus = "due";
				} else if (cert.isInGrace) {
					expiryStatus = "grace";
				} else if (cert.isExpired) {
					expiryStatus = "expired";
				} else if (cert.isInRenewal) {
					expiryStatus = "renewal";
				} else {
					expiryStatus = "valid";
				}
			}

			// if required isn't set, use base cert default
			cert.isRequired = cert.isRequired ?? true;
			if (!_temp[`${expiryStatus}_${cert.dscatidStatus}_${cert.isRequired.toString()}`])
				_temp[`${expiryStatus}_${cert.dscatidStatus}_${cert.isRequired.toString()}`] = 0;

			_temp[`${expiryStatus}_${cert.dscatidStatus}_${cert.isRequired.toString()}`] += 1;
		}

		for (const key in _temp) {
			const _split = key.split("_");

			let required = false;
			if (_split[2] === "true")
				required = true;

			output.push({
				"expiry": _split[0],
				"workflow": _split[1],
				"required": required,
				"total": _temp[key]
			});
		}

		return output;
	};

	/*public async deleteFileFromCertificate(certificate: SubCertificate, update = true) {
		const formData = new FormData();
		formData.append("linkId", certificate.id.toString());
		formData.append("linkIdType", "DSCDXCID");
		formData.append("id", certificate.DSMEDIAAPIID.toString());

		await fetch("/dynasite.cfm?dscmd=mediaapi_mediaapi_mediaapi_delete", {
			method: "POST",
			body: formData
		});

		// send the newly created DSMEDIAAPIID to certificates api
		const certsResponseRaw = await fetch(`/api/fp-certificates/certificates/sub/${this.app.ctx.dscid}/${certificate.dscdid}/${certificate.id}`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				...certificate,
				DSMEDIAAPIID: null
			})
		});

		// api error handling
		const certsResponse = await certsResponseRaw.json();
		if (certsResponse.error) return handleError(certsResponse);

		const baseCert = this.getBaseCertificateById(certsResponse.rows[0].dscdid);

		// re-load certificate
		if (update) await this.upsertSingleSubCertificate(baseCert, certsResponse.rows[0]);
	}*/

	// special linktype cases are mapped/handled here!
	public getLinkType(linkType: string) {
		switch (linkType) {
			case "dscaid_organization":
				return "dscaid";
			default:
				return linkType;
		}
	}

	@action.bound public async generateCertificate(params: { dscdid: number; linkId: string; linkType: string; sourceId: string; sourceType: string; dateIssue: string; dscatidStatus: CertificateStatus; host: number; }): Promise<void> {
		// send the newly created DSMEDIAAPIID to certificates api
		const certsResponseRaw = await fetch("/api/fp-certificates/certificates/generate", {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(params)
		});

		const certsResponse = await certsResponseRaw.json();
		if (certsResponse.error) throw certsResponse;
	}

	@action.bound public async convertMaster(id: number): Promise<BaseCertificate[]> {
		// send the newly created DSMEDIAAPIID to certificates api
		const certsResponseRaw = await fetch(`/api/fp-certificates/certificates/convertmaster/${id}`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			}
		});

		const certsResponse = await certsResponseRaw.json();
		await this.refreshCertificateDataset(null, certsResponse.rows, true);

		for (const row of certsResponse.rows) {
			if (this.app.store.certificateV3Store.util.findKey(`baseCert:${row.id}`)) {
				this.app.store.certificateV3Store.util.updateNodeByKey(this.app.store.certificateV3Store.certToTreeNode(this.app.store.certificateV3Store.getBaseCertificateById(row.id)), `baseCert:${row.id}`);
				this.app.store.certificateV3Store.countTreeNodes();
			} else {
				this.app.store.certificateV3Store.util.insertUnderParent(this.app.store.certificateV3Store.certToTreeNode(this.app.store.certificateV3Store.getBaseCertificateById(row.id)), `cat:${row.dirId}`);
			}
		}

		this.countTreeNodes();
		this.refreshTree();
		if (certsResponse.error) throw certsResponse;
		return certsResponse.rows;
	}
}
